import { useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  AuthService,
  ProfileService,
  ApiError,
  VerifyRegistrationParams,
  RegisterCredentials,
  ResetPasswordParams,
} from "@repo/service";
import { AppName, Env, useCurrentLanguage, getRedirectUrl } from "@repo/config";
import { RoleManager } from "../utils/roleManager";

/**
 * Query keys for authentication-related queries
 */
export const AUTH_KEYS = {
  auth: ["auth"] as const,
  profile: ["auth", "profile"] as const,
} as const;

/**
 * Interface for login mutation parameters
 */
interface LoginParams {
  /** User's username */
  username: string;
  /** User's password */
  password: string;
  /** Target application for login */
  targetApp: AppName;
  /** Service for handling profile operations */
  profileService: ProfileService;
}

/**
 * ustom hook to manage authentication operations
 *
 * This hook handles:
 * - User login with role validation
 * - Logout operations
 * - Query cache management
 * - Redirection after successful authentication
 *
 * @param {AuthService} authService - Service for handling authentication operations
 * @param {Env} env - Environment configuration
 * @returns {Object} Authentication mutations and related functions
 */
export function useAuthQuery(authService: AuthService, env: Env) {
  const queryClient = useQueryClient();
  const currentLanguage = useCurrentLanguage();

  /**
   * Mutation for handling user login
   */
  const loginMutation = useMutation<void, ApiError, LoginParams>({
    mutationFn: async ({ username, password, targetApp, profileService }) => {
      await authService.login(username, password);
      const profile = await profileService.getMyProfile(currentLanguage);

      const roleManager = RoleManager.createFromRole(profile.role_level);

      try {
        roleManager.validateWithError(targetApp);
      } catch (error) {
        authService.logout();
        throw error;
      }

      queryClient.setQueryData([...AUTH_KEYS.profile, "me"], profile);
      window.location.href = getRedirectUrl(targetApp, env);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AUTH_KEYS.profile });
    },
  });

  /**
   * Mutation for handling user logout
   */
  const logoutMutation = useMutation({
    mutationFn: async () => {
      const currentApp = window.location.origin;
      const encodedReturnUrl = encodeURIComponent(currentApp);
      await authService.logout();
      window.location.href = `${env.AUTH.AUTH_APP_URL}/login?returnUrl=${encodedReturnUrl}`;
    },
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: AUTH_KEYS.auth });
      queryClient.removeQueries({ queryKey: AUTH_KEYS.profile });
    },
  });

  /**
   * Mutation for handling user registration
   */
  const registerMutation = useMutation({
    mutationFn: async (credentials: RegisterCredentials) => {
      await authService.register(credentials, currentLanguage);
    },
  });

  /**
   * Mutation for handling user registration verification
   */
  const verifyRegistrationMutation = useMutation({
    mutationFn: async (params: VerifyRegistrationParams) => {
      await authService.verifyRegistration(params, currentLanguage);
    },
  });

  /**
   * Mutation for handling user password reset request
   */
  const requestPasswordResetMutation = useMutation({
    mutationFn: async (email: string) => {
      await authService.requestPasswordReset(email, currentLanguage);
    },
  });

  /**
   * Mutation for handling user password change
   */
  const resetPasswordMutation = useMutation({
    mutationFn: async (params: ResetPasswordParams) => {
      await authService.resetPassword(params, currentLanguage);
    },
  });

  return {
    loginMutation,
    logoutMutation,
    registerMutation,
    verifyRegistrationMutation,
    requestPasswordResetMutation,
    resetPasswordMutation,
  };
}

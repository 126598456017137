import { useTranslation, formatDate } from "@repo/config";
import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { Separator } from "@repo/ui/components/ui/Separator";
import Earth from "@/assets/images/Earth.svg?react";
import { ArrowRight, Package, AlertCircle } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";
import { OrderDetail } from "@repo/service";

interface OrderDetailContentProps {
  error: any;
  isLoading: boolean;
  selectedOrder: OrderDetail | null;
}

export const OrderDetailContent = ({
  error,
  isLoading,
  selectedOrder,
}: OrderDetailContentProps) => {
  const { t } = useTranslation("orders");

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <AlertCircle className="h-12 w-12 text-destructive mb-3" />
        <p className="text-lg text-destructive">
          {t("error.order.detail.fetch_title")}
        </p>
      </div>
    );
  }

  if (isLoading) {
    return <></>;
  }

  if (!selectedOrder) return null;

  return (
    <div className="relative">
      {/* Background SVG */}
      <div className="absolute inset-0 -z-10 overflow-hidden h-screen">
        <div className="relative size-full flex items-center justify-center">
          <Earth
            className="absolute min-h-full w-auto object-cover
                  opacity-25 dark:opacity-10 pointer-events-none select-none
                  transform -translate-x-1/2 left-1/2"
          />
        </div>
      </div>

      {/* Main Content */}
      <div className="space-y-4 sm:space-y-8 pt-4 sm:pt-8 container mx-auto md:px-12">
        {/* Created At Card */}
        <Card className="w-full mx-auto bg-background/10 dark:bg-background/50 backdrop-blur-sm dark:backdrop-blur-md">
          <CardContent className="p-4">
            <div className="flex items-center justify-between">
              {/* Created At Section */}
              <div className="flex-1 text-center">
                <h2 className="text-sm text-muted-foreground mb-1">
                  {t("order.detail.created_at")}
                </h2>
                <p className="text-xs sm:text-sm font-medium">
                  {formatDate(selectedOrder.created_at, "dd/MM/yyyy")}
                </p>
              </div>

              <Separator orientation="vertical" className="mx-4 h-12" />
              {/* Loading Date */}
              <div className="flex-1 text-center">
                <h2 className="text-sm text-muted-foreground mb-1">
                  {t("order.detail.loading_date")}
                </h2>

                <p className="text-xs sm:text-sm font-medium">
                  {formatDate(selectedOrder.loading_date)}
                </p>
              </div>
            </div>

            <Separator className="my-2" />

            {/* Types Section */}
            <div className="flex items-center justify-between">
              <div className="flex-1 text-center">
                <h2 className="text-sm text-muted-foreground mb-1">
                  {t("order.detail.expedition_type")}
                </h2>
                <p className="text-xs sm:text-sm font-medium">
                  {selectedOrder.expedition_type}
                </p>
              </div>
              <Separator orientation="vertical" className="mx-4 h-12" />
              <div className="flex-1 text-center">
                <h2 className="text-sm text-muted-foreground mb-1">
                  {t("order.detail.load_type")}
                </h2>
                <p className="text-xs sm:text-sm font-medium">
                  {selectedOrder.load_type}
                </p>
              </div>
            </div>

            <Separator className="my-2" />

            {/* Weight and Unit Section */}
            <div className="flex items-center justify-between">
              <div className="flex-1 text-center">
                <h2 className="text-sm text-muted-foreground mb-1">
                  {t("order.detail.weight")}
                </h2>
                <p className="text-xs sm:text-sm font-medium">
                  {selectedOrder.weight}
                </p>
              </div>
              <Separator orientation="vertical" className="mx-4 h-12" />
              <div className="flex-1 text-center">
                <h2 className="text-sm text-muted-foreground mb-1">
                  {t("order.detail.unit")}
                </h2>
                <p className="text-xs sm:text-sm font-medium">
                  {selectedOrder.unit}
                </p>
              </div>
            </div>

            <Separator className="my-2" />

            <div className="flex items-center justify-between">
              {/* Container Loading Type */}
              <div className="flex-1 text-center">
                <h2 className="text-sm text-muted-foreground mb-1">
                  {t("order.detail.container_loading_type")}
                </h2>
                <p className="text-xs sm:text-sm font-medium">
                  {selectedOrder.container_loading_type}
                </p>
              </div>

              <Separator orientation="vertical" className="mx-4 h-12" />

              {/* Vehicle Count */}
              <div className="flex-1 text-center">
                <h2 className="text-sm text-muted-foreground mb-1">
                  {t("order.detail.vehicle_count")}
                </h2>
                <p className="text-xs sm:text-sm font-medium">
                  {selectedOrder.vehicle_count}
                </p>
              </div>
            </div>

            {/* Description */}
            {selectedOrder.description && (
              <div className="flex-1 sm:flex-none w-full">
                <Separator className="my-2 hidden sm:block w-full" />

                <div className="text-center">
                  <h2 className="text-sm text-muted-foreground mb-2">
                    {t("order.detail.description")}
                  </h2>
                  <p className="text-xs sm:text-sm font-medium">
                    {selectedOrder.description}
                  </p>
                </div>
              </div>
            )}
          </CardContent>
        </Card>

        {/* Addresses Grid */}
        <div className="grid grid-cols-3 gap-4 relative">
          {/* Load Address */}
          <Card className="bg-background/10 text-center dark:bg-background/50 backdrop-blur-sm dark:backdrop-blur-md">
            <CardContent className="p-2 sm:p-4">
              {selectedOrder.load_address && (
                <>
                  <p className="text-sm text-muted-foreground mb-1">
                    {t("order.detail.load_address")}
                  </p>
                  <p className="text-xs sm:text-sm font-medium">
                    {selectedOrder.load_address}
                  </p>
                </>
              )}
            </CardContent>
          </Card>

          {/* Center Icons with Full Width Container */}
          <div className="w-full relative flex items-center justify-center">
            {/* Background Line */}
            <div className="absolute inset-x-0 top-1/2 -translate-y-1/2 h-0.5 bg-border" />

            {/* Arrows Container - Full Width */}
            <div className="absolute inset-x-0 top-1/2 -translate-y-1/2 flex justify-between lg:px-4">
              <ArrowRight className="size-5 text-muted-foreground" />
              <ArrowRight className="size-5 text-muted-foreground" />
            </div>

            {/* Center Package Icon */}
            <div className="relative sm:top-0 -top-2 z-10">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="w-full bg-background/10 dark:bg-background/50 backdrop-blur-sm dark:backdrop-blur-md rounded-lg max-w-[120px]">
                      <p className="text-[10px] font-medium block sm:hidden max-w-[180px] truncate">
                        {selectedOrder.load_name}
                      </p>
                      <div className="flex items-center justify-center gap-2 px-2 py-1 rounded-lg bg-background border-2 border-border">
                        <Package className="size-4 shrink-0 text-muted-foreground" />
                        <Separator
                          orientation="vertical"
                          className="h-6 hidden sm:block"
                        />
                        <p className="text-xs sm:text-sm font-medium hidden sm:block max-w-[100px] truncate">
                          {selectedOrder.load_name}
                        </p>
                      </div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{selectedOrder.load_name}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>

          {/* Unload Address */}
          <Card className="bg-background/10 text-center dark:bg-background/50 backdrop-blur-sm dark:backdrop-blur-md">
            <CardContent className="p-2 sm:p-4">
              {selectedOrder.unload_address && (
                <>
                  <p className="text-sm text-muted-foreground mb-1">
                    {t("order.detail.unload_address")}
                  </p>
                  <p className="text-xs sm:text-sm font-medium">
                    {selectedOrder.unload_address}
                  </p>
                </>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

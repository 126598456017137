import { useQuery } from "@tanstack/react-query";
import { orderService } from "@/main";
import { GetOrderParams } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

const QUERY_KEY = "order";

export const useOrder = (params: GetOrderParams) => {
  const currentLanguage = useCurrentLanguage();
  const orderNo = params.orderNo;

  return useQuery({
    queryKey: [QUERY_KEY, orderNo, currentLanguage],
    queryFn: () =>
      orderService.getOrder({
        ...params,
        language: currentLanguage,
      }),
    enabled: !!orderNo,
  });
};

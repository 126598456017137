import {
  LayoutDashboard,
  PackagePlus,
  MapPin,
  LucideIcon,
  Library as LibraryIcon,
} from "lucide-react";
import Dashboard from "@/pages/Dashboard";
import Library from "@/pages/Library";
import Orders from "@/pages/Orders";
import { Settings } from "@repo/user-settings";
import { OTS } from "@repo/ots";
import { AuthService, LoadService } from "@repo/service";

interface RouteConfigProps {
  authService: AuthService;
  loadService: LoadService;
}

export interface RouteConfig {
  path: string;
  component: React.ComponentType;
  permissions: string[];
  title: string;
  icon?: LucideIcon;
  showInSidebar?: boolean;
}

/**
 * Route keys
 * Each route's key
 */
type RouteKey = "dashboard" | "library" | "ots" | "orders" | "settings";

/**
 * Route configuration
 * Each route's required permissions and visibility settings
 *
 * Permissions:
 * - Empty array ([]): Public route, no permissions required
 * - Array with values: Protected route, requires specific permissions
 */
export const createRouteConfig = ({
  authService,
  loadService,
}: RouteConfigProps) => {
  /**
   * Route configuration
   * Each route's required permissions and visibility settings
   */
  const routes: Record<RouteKey, RouteConfig> = {
    dashboard: {
      path: "/dashboard",
      component: Dashboard,
      permissions: ["view_siberexpedition"],
      title: "sidebar.menu.dashboard",
      showInSidebar: true,
      icon: LayoutDashboard,
    },
    ots: {
      path: "/ots",
      component: OTS({ loadService }),
      permissions: ["view_load"],
      title: "sidebar.menu.ots",
      showInSidebar: true,
      icon: MapPin,
    },
    orders: {
      path: "/orders",
      component: Orders,
      permissions: ["view_freightdemand", "view_freightoffer"],
      title: "sidebar.menu.orders",
      showInSidebar: true,
      icon: PackagePlus,
    },
    library: {
      path: "/library",
      component: Library,
      permissions: [],
      title: "sidebar.menu.library",
      showInSidebar: true,
      icon: LibraryIcon,
    },
    settings: {
      path: "/settings/*",
      component: Settings({ authService }),
      permissions: [],
      title: "sidebar.menu.settings",
      showInSidebar: false,
    },
  };

  return { routes };
};

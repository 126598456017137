export const translations = {
  tr: {
    title: "Destek Talebi",
    description:
      "Destek Talebi oluşturabilir veya taleplerinizi görüntüleyebilirsiniz.",
    button: "Destek",
    createTicket: "Talep Oluştur",
    myTickets: "Taleplerim",
    tickets: {
      error: {
        fetch_title: "Talep Detayları Yüklenirken Bir Hata Oluştu",
      },
      copy: {
        success_title: "Talep Numarası Kopyalandı",
        success_description:
          "{{ticketNo}} numaralı talep numarası panoya kopyalandı.",
        error_title: "Kopyalama Başarısız",
        error_description: "Talep numarası kopyalanırken bir hata oluştu.",
      },
      empty: "Henüz bir destek talebiniz bulunmamaktadır.",
      empty_active: "İşlemde olan destek talebiniz bulunmamaktadır.",
      empty_resolved: "Sonuçlandırılmış destek talebiniz bulunmamaktadır.",
      ticket_no: "Talep No",
      subject: "Konu",
      status: "Durum",
      priority: "Öncelik",
      category: "Kategori",
      updated_at: "Son Güncelleme",
      created_at: "Oluşturulma Tarihi",
      last_replier: "Son Yanıtlayan",
      active_tickets: "İşlemdeki Talepler",
      resolved_tickets: "Sonuçlandırılan Talepler",
      ticket_detail: "Talep Detayı",
      no_replies: "Bu talebe henüz yanıt verilmemiş.",
      closed_by: "{{name}} tarafından kapatıldı",
      closed_at: "Kapatılma Tarihi",
      owner: "Talep Sahibi",
      merged: "Birleştirilmiş Talep",
      replies: {
        title: "Yanıtlar",
        no_replies: "Bu talebe henüz yanıt verilmemiş.",
        reply_count: "{{count}} yanıt",
        staff_reply: "Destek Ekibi",
        customer_reply: "Müşteri",
        loading: "Yanıtlar yükleniyor...",
        placeholder: "Yanıtınızı buraya yazın...",
        submit: "Yanıtla",
        submit_error: "Yanıt gönderilirken bir hata oluştu",
        attach_file: "Dosya Ekle",
        remove_file: "Dosyayı Kaldır",
        attachments: {
          title: "Ekler",
          no_attachments: "Ek yok",
          download: "İndir",
        },
        rating: {
          title: "Değerlendirme",
          not_rated: "Değerlendirilmemiş",
        },
      },
      meta: {
        created_at: "Oluşturulma",
        updated_at: "Son Güncelleme",
        last_reply: "Son Yanıt",
        category: "Kategori",
        department: "Departman",
        status: "Durum",
        priority: "Öncelik",
        reply_by: "Yanıtlayan",
        reply_date: "Yanıt Tarihi",
        staff_info: "Destek Ekibi Bilgisi",
        customer_info: "Müşteri Bilgisi",
      },
      status_labels: {
        new: "Yeni",
        in_progress: "İşlemde",
        waiting: "Beklemede",
        replied: "Yanıtlandı",
        resolved: "Çözüldü",
        closed: "Kapatıldı",
        on_hold: "Beklemede",
      },
      priority_labels: {
        low: "Düşük",
        medium: "Orta",
        high: "Yüksek",
        critical: "Kritik",
      },
      actions: {
        back: "Geri Dön",
        reply: "Yanıtla",
        close: "Kapat",
        reopen: "Yeniden Aç",
      },
      messages: {
        no_message: "Mesaj içeriği bulunamadı.",
        loading: "Talep detayları yükleniyor...",
        error: "Talep detayları yüklenirken bir hata oluştu.",
        show_more: "Daha Fazla Görüntüle",
        show_less: "Daha Az Görüntüle",
      },
    },
    form: {
      templates: {
        label: "Hazır Şablonlar",
      },
      validation: {
        name: {
          required: "İsim alanı zorunludur",
          min: "İsim en az {{min}} karakter olmalıdır",
          max: "İsim en fazla {{max}} karakter olabilir",
        },
        email: {
          required: "E-posta alanı zorunludur",
          invalid: "Geçerli bir e-posta adresi giriniz",
        },
        category: {
          required: "Kategori seçimi zorunludur",
        },
        priority: {
          required: "Öncelik seçimi zorunludur",
          invalid: "Geçersiz öncelik değeri",
        },
        computer_code: {
          required: "Bilgisayar kodu zorunludur",
          max: "Bilgisayar kodu en fazla {{max}} karakter olabilir",
        },
        department: {
          required: "Departman seçimi zorunludur",
        },
        subject: {
          required: "Konu alanı zorunludur",
          min: "Konu en az {{min}} karakter olmalıdır",
          max: "Konu en fazla {{max}} karakter olabilir",
        },
        message: {
          required: "Mesaj alanı zorunludur",
          min: "Mesaj en az {{min}} karakter olmalıdır",
          max: "Mesaj en fazla {{max}} karakter olabilir",
        },
        attachments: {
          label: "Ek",
          help: "Maksimum dosya boyutu 2MB olmalıdır",
          max: "Maksimum dosya boyutu",
          max_description: "Maksimum dosya boyutu {{maxSize}}MB olmalıdır",
          type: "Desteklenmeyen dosya formatı",
          type_description:
            "İzin verilen dosya formatları: gif, jpg, png, zip, rar, csv, doc, docx, xls, xlsx, txt, pdf",
        },
      },
      name: "İsim",
      email: "E-posta",
      priority: {
        label: "Öncelik",
        placeholder: "Öncelik seçin",
        options: {
          low: "Düşük",
          medium: "Orta",
          high: "Yüksek",
        },
      },
      computer_code: {
        label: "Bilgisayar Kodu",
        placeholder: "Bilgisayar kodunu giriniz",
        help: "Bilgisayar kodunuz monitör ve kasa üstünde veya laptopun altında bulunabilir",
      },
      department: {
        label: "Departman",
        placeholder: "Departman seçiniz",
        options: {
          bilgi_islem: "Bilgi İşlem",
          ar_ge: "Ar-ge",
          ithalat: "İthalat",
          sekreterlik: "Sekreterlik",
          ihracat: "İhracat",
          muhasebe: "Muhasebe",
          finans: "Finans",
          filo: "Filo",
          hesap_birimi: "Hesap Birimi",
          depo: "Depo",
          insan_kaynaklari: "İnsan Kaynakları",
          gumruk: "Gümrük",
          mersin_sube: "Mersin Şube",
          idari_isler: "İdari İşler",
          kalite: "Kalite",
          parsiyel: "Parsiyel",
          satis_pazarlama: "Satış & Pazarlama",
          istanbul_sube: "İstanbul Şube",
          diger: "Diğer",
        },
      },
      subject: {
        label: "Konu",
        placeholder: "Konu giriniz",
      },
      message: {
        label: "Mesaj",
        placeholder: "Mesajınızı giriniz",
      },
      category: {
        label: "Kategori",
        placeholder: "Kategori seçin",
        options: {
          printer: "Yazıcı",
          siber: "Siber",
          zirve: "Zirve",
          network: "Ağ",
          network_connection: "Ağ Bağlantısı",
          ms_office: "MS Office",
          administrative_affairs: "İdari İşler",
          computer: "Bilgisayar",
          hardware_software: "Donanım & Yazılım",
          phone_tablet: "Telefon & Tablet",
          email: "Email",
          remote_access: "Uzaktan Erişim",
          wish_complaint_suggestion: "İstek, Şikayet & Öneri",
          stationary: "Kırtasiye",
          mobiliz: "Mobiliz",
          human_resources: "İnsan Kaynakları",
          breach: "Kırılma",
          ar_ge: "Ar-ge",
          statement_of_employment: "İş Sözleşmesi",
          quitting_work: "İşten Ayrılma",
          change_of_duty: "Görev Değişimi",
          library: "Kütüphane",
          panel: "Panel",
          car_reservation: "Araç Rezervasyonu",
          other: "Diğer",
        },
      },
      attachments: {
        label: "Ek",
        help: "Maksimum dosya boyutu 2MB olmalıdır",
        drop: "Dosyayı buraya bırakın",
        click_or_drop: "Dosya yüklemek için tıklayın veya sürükleyin",
        error: {
          title: "Desteklenmeyen dosya formatı",
          description:
            "İzin verilen dosya formatları: gif, jpg, png, zip, rar, csv, doc, docx, xls, xlsx, txt, pdf",
        },
      },
      submit: {
        label: "Gönder",
        loading: "Gönderiliyor...",
      },
      success: {
        title: "Talep Başarıyla Oluşturuldu",
        description: "Talep detaylarınız mail adresinize gönderildi.",
      },
      error: {
        title: "Talep Oluşturulurken Bir Hata Oluştu",
        description:
          "Lütfen daha sonra tekrar deneyiniz. Eğer hata devam ederse, lütfen destek ekibimizle iletişime geçiniz.",
      },
    },
    help: {
      title: "Destek Talebi Nasıl Oluşturulur?",
      description:
        "Destek talebi oluşturma adımlarını görsel olarak inceleyin.",
    },
  },
  en: {
    title: "Support Ticket",
    description: "You can create a support ticket or view your tickets.",
    button: "Support",
    createTicket: "Create Ticket",
    myTickets: "My Tickets",
    tickets: {
      error: {
        fetch_title: "An error occurred while loading ticket details.",
      },
      copy: {
        success_title: "Ticket Number Copied",
        success_description:
          "Ticket number {{ticketNo}} has been copied to clipboard.",
        error_title: "Copy Failed",
        error_description: "An error occurred while copying the ticket number.",
      },
      empty: "You don't have any support tickets yet.",
      empty_active: "You don't have any active support tickets.",
      empty_resolved: "You don't have any resolved support tickets.",
      ticket_no: "Ticket No",
      subject: "Subject",
      status: "Status",
      priority: "Priority",
      category: "Category",
      updated_at: "Last Update",
      created_at: "Created At",
      last_replier: "Last Reply By",
      active_tickets: "Active Tickets",
      resolved_tickets: "Resolved Tickets",
      ticket_detail: "Ticket Details",
      no_replies: "No replies to this ticket yet.",
      closed_by: "Closed by {{name}}",
      closed_at: "Closed At",
      owner: "Ticket Owner",
      merged: "Merged Ticket",
      replies: {
        title: "Replies",
        no_replies: "No replies to this ticket yet.",
        reply_count: "{{count}} replies",
        staff_reply: "Support Team",
        customer_reply: "Customer",
        loading: "Replies are loading...",
        placeholder: "Write your reply here...",
        submit: "Reply",
        submit_error: "An error occurred while sending the reply",
        attach_file: "Attach File",
        remove_file: "Remove File",
        attachments: {
          title: "Attachments",
          no_attachments: "No attachments",
          download: "Download",
        },
        rating: {
          title: "Rating",
          not_rated: "Not rated",
        },
      },
      meta: {
        created_at: "Created",
        updated_at: "Last Update",
        last_reply: "Last Reply",
        category: "Category",
        department: "Department",
        status: "Status",
        priority: "Priority",
        reply_by: "Replied By",
        reply_date: "Reply Date",
        staff_info: "Support Team Info",
        customer_info: "Customer Info",
      },
      status_labels: {
        new: "New",
        in_progress: "In Progress",
        waiting: "Waiting",
        replied: "Replied",
        resolved: "Resolved",
        closed: "Closed",
        on_hold: "On Hold",
      },
      priority_labels: {
        low: "Low",
        medium: "Medium",
        high: "High",
        critical: "Critical",
      },
      actions: {
        back: "Go Back",
        reply: "Reply",
        close: "Close",
        reopen: "Reopen",
      },
      messages: {
        no_message: "No message content found.",
        loading: "Loading ticket details...",
        error: "An error occurred while loading ticket details.",
        show_more: "Show More",
        show_less: "Show Less",
      },
    },
    form: {
      templates: {
        label: "Templates",
      },
      validation: {
        name: {
          required: "Name is required",
          min: "Name must be at least {{min}} characters",
          max: "Name cannot exceed {{max}} characters",
        },
        email: {
          required: "Email is required",
          invalid: "Please enter a valid email address",
        },
        category: {
          required: "Category selection is required",
        },
        priority: {
          required: "Priority selection is required",
          invalid: "Invalid priority value",
        },
        computer_code: {
          required: "Computer code is required",
          max: "Computer code cannot exceed {{max}} characters",
        },
        department: {
          required: "Department selection is required",
        },
        subject: {
          required: "Subject is required",
          min: "Subject must be at least {{min}} characters",
          max: "Subject cannot exceed {{max}} characters",
        },
        message: {
          required: "Message is required",
          min: "Message must be at least {{min}} characters",
          max: "Message cannot exceed {{max}} characters",
        },
        attachments: {
          label: "Attachment",
          help: "Maximum file size is 2MB",
          max: "Maximum file size",
          max_description: "Maximum file size {{maxSize}}MB",
          type: "Unsupported file format",
          type_description:
            "Allowed file types: gif, jpg, png, zip, rar, csv, doc, docx, xls, xlsx, txt, pdf",
        },
      },
      name: "Name",
      email: "Email",
      priority: {
        label: "Priority",
        placeholder: "Select priority",
        options: {
          low: "Low",
          medium: "Medium",
          high: "High",
        },
      },
      computer_code: {
        label: "Computer Code",
        placeholder: "Enter computer code",
        help: "Your computer code can be found on the monitor, case, or under your laptop",
      },
      department: {
        label: "Department",
        placeholder: "Make a selection",
        options: {
          bilgi_islem: "Information Technology",
          ar_ge: "Ar-ge",
          ithalat: "Import",
          sekreterlik: "Secretarial",
          ihracat: "Export",
          muhasebe: "Accounting",
          finans: "Finance",
          filo: "Filo",
          hesap_birimi: "Accounting Unit",
          depo: "Warehouse",
          insan_kaynaklari: "Human Resources",
          gumruk: "Customs",
          mersin_sube: "Mersin Branch",
          idari_isler: "Administrative Affairs",
          kalite: "Quality",
          parsiyel: "Partial",
          satis_pazarlama: "Sales & Marketing",
          istanbul_sube: "İstanbul Branch",
          diger: "Other",
        },
      },
      subject: {
        label: "Subject",
        placeholder: "Enter subject",
      },
      message: {
        label: "Message",
        placeholder: "Enter your message",
      },
      category: {
        label: "Category",
        placeholder: "Select category",
        options: {
          printer: "Printer",
          siber: "Siber",
          zirve: "Zirve",
          network: "Network",
          network_connection: "Network Connection",
          ms_office: "MS Office",
          administrative_affairs: "Administrative Affairs",
          computer: "Computer",
          hardware_software: "Hardware & Software",
          phone_tablet: "Phone & Tablet",
          email: "Email",
          remote_access: "Remote Access",
          wish_complaint_suggestion: "Wish, Complaint & Suggestion",
          stationary: "Stationary",
          mobiliz: "Mobiliz",
          human_resources: "Human Resources",
          breach: "Breach",
          ar_ge: "Ar-ge",
          statement_of_employment: "Statement of Employment",
          quitting_work: "Quitting Work",
          change_of_duty: "Change of Duty",
          library: "Library",
          panel: "Panel",
          car_reservation: "Car Reservation",
          other: "Other",
        },
      },
      attachments: {
        label: "Attachment",
        help: "Maximum file size is 2MB",
        drop: "Drop file here",
        click_or_drop: "Click or drop file here",
        error: {
          title: "Unsupported file format",
          description:
            "Allowed file types: gif, jpg, png, zip, rar, csv, doc, docx, xls, xlsx, txt, pdf",
        },
      },
      submit: {
        label: "Submit",
        loading: "Submitting...",
      },
      success: {
        title: "Ticket Created Successfully",
        description:
          "Your ticket details have been sent to your email address.",
      },
      error: {
        title: "Error Creating Support Ticket",
        description:
          "Please try again later. If the error persists, please contact our support team.",
      },
    },
    help: {
      title: "How to Create a Support Ticket?",
      description: "Please follow the steps below to create a support ticket.",
    },
  },
};

import { z } from "zod";
import { useTranslation } from "@repo/config";

export const useChangePasswordSchema = () => {
  const { t } = useTranslation("settings");

  return z
    .object({
      old_password: z
        .string()
        .min(1, t("change_password.validation.old_password.required")),
      password: z
        .string({
          required_error: t("change_password.validation.password.required"),
        })
        .min(8, t("change_password.validation.password.min", { min: 8 }))
        .regex(
          /^(?=.*[A-ZÇĞİÖŞÜ])(?=.*[a-zçğöşü])(?=.*\d)(?=.*[@$!%*#?&_\-+.])[A-Za-zçÇğĞıİöÖşŞüÜ\d@$!%*#?&_\-+.]{8,}$/,
          t("change_password.validation.password.pattern"),
        ),
      password_confirm: z.string({
        required_error: t(
          "change_password.validation.confirm_password.required",
        ),
      }),
    })
    .superRefine((data, ctx) => {
      // Şifrelerin eşleşme kontrolü
      if (data.password !== data.password_confirm) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("change_password.validation.confirm_password.match"),
          path: ["password_confirm"],
        });
      }
    });
};

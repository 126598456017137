import { Skeleton } from "@repo/ui/components/ui/Skeleton";
import { Card, CardContent, CardHeader } from "@repo/ui/components/ui/Card";

export default function TicketCardSkeleton() {
  return (
    <Card>
      <CardHeader className="p-4 pb-0">
        <div className="flex items-start justify-between">
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <Skeleton className="h-5 w-32 md:w-40" />
              <Skeleton className="h-5 w-20 md:w-24" />
            </div>
            <div className="flex items-center gap-2">
              <Skeleton className="h-4 w-24 md:w-32" />
              <Skeleton className="h-4 w-4 rounded-full" />
            </div>
          </div>
          <div className="flex md:inline-flex hidden items-center gap-2">
            <Skeleton className="h-6 w-16" />
            <Skeleton className="h-6 w-16" />
          </div>
        </div>
      </CardHeader>
      <CardContent className="p-4 pt-4">
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-1">
            <Skeleton className="h-4 w-4 rounded-full" />
            <Skeleton className="h-4 md:w-24 w-16" />
          </div>
          <div className="flex items-center gap-1">
            <Skeleton className="h-4 w-4 rounded-full" />
            <Skeleton className="h-4 w-24 md:w-32" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

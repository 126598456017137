import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "@repo/config";
import { useUser } from "@repo/provider";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/Form";
import { Input } from "@repo/ui/components/ui/Input";
import { useProfileSchema } from "../../schemas/profileSchema";
import { Company, User } from "@repo/service";

// TODO: Telefon no, ve submit button eklenecek
// import { Button } from "@repo/ui/components/ui/button";
// import PhoneInput from "react-phone-number-input";
// import flags from "react-phone-number-input/flags";
// import "react-phone-number-input/style.css";

interface ProfileFormData {
  first_name: User["first_name"];
  last_name: User["last_name"];
  email: User["email"];
  // phone: User['phone'];
  company_name: Company["company_name"];
}

export default function UserSettingsForm() {
  const { t } = useTranslation("settings");
  const { user } = useUser();
  const profileSchema = useProfileSchema();

  const form = useForm<ProfileFormData>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      email: user?.email ?? "",
      company_name: user?.company?.company_name ?? "",
      // phone: user?.phone ?? "",
    },
  });

  const onSubmit = async (data: ProfileFormData) => {
    // TODO: onSubmit eklenecek
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* First Name */}
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("profile.form.first_name.label")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("profile.form.first_name.placeholder")}
                    {...field}
                    disabled
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Last Name */}
          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("profile.form.last_name.label")}</FormLabel>
                <FormControl>
                  <Input
                    disabled
                    placeholder={t("profile.form.last_name.placeholder")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Email */}
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("profile.form.email.label")}</FormLabel>
                <FormControl>
                  <Input
                    disabled
                    type="email"
                    placeholder={t("profile.form.email.placeholder")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="company_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("profile.form.company_name.label")}</FormLabel>
                <FormControl>
                  <Input
                    disabled
                    type="text"
                    placeholder={t("profile.form.company_name.placeholder")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* <FormField
                        control={form.control}
                        name="phone"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    {t('profile.form.phone.label')}
                                </FormLabel>
                                <FormControl>
                                    <PhoneInput
                                        international
                                        defaultCountry="TR"
                                        flags={flags}
                                        value={field.value}
                                        onChange={(value) => field.onChange(value || '')}
                                        className="flex h-10 w-full rounded-md border border-input bg-background pl-3 py-2 text-sm ring-offset-background"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    /> */}
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          {/* <Button type="submit" disabled={!form.formState.isDirty}>
                        {t('common.save')}
                    </Button> */}
        </div>
      </form>
    </Form>
  );
}

import { QueryCache, QueryClient, MutationCache } from "@tanstack/react-query";
import { ApiError } from "./apiError";
import { ApiErrorHandler } from "../utils/ErrorHandler";
import { type ToastManager } from "@repo/ui/utils/toastManager";

const QUERY_CONFIG = {
  staleTime: 30000, // 30 seconds
  maxRetries: 2,
  maxRetryDelay: 30000,
  retryBaseDelay: 1000,
  // HTTP status codes
  status: {
    noRetry: [400, 401, 403, 404] as const,
    retry: [408, 500, 502, 503, 504] as const,
  },
} as const;

export function createQueryClient(options: {
  toastManager: ToastManager;
  i18n: { t: (key: string, options?: any) => string };
}) {
  const errorHandler = ApiErrorHandler.init(options);

  return new QueryClient({
    queryCache: new QueryCache({
      onError: (error: unknown, query) => {
        if (query.meta?.suppressError) return;

        errorHandler.handleError(error as ApiError);
      },
    }),
    mutationCache: new MutationCache({
      onError: (error: unknown, _variables, _context, mutation) => {
        if (mutation.meta?.suppressError) return;
        errorHandler.handleError(error as ApiError);
      },
    }),
    defaultOptions: {
      queries: {
        staleTime: QUERY_CONFIG.staleTime,
        retry: (failureCount, error) => {
          const apiError = error as ApiError;
          const status = apiError.status ?? 0;

          if (!navigator.onLine) {
            return true;
          }

          // Do not retry on these status codes
          if (QUERY_CONFIG.status.noRetry.includes(status as any)) {
            return false;
          }

          // Retry on temporary errors
          if (QUERY_CONFIG.status.retry.includes(status as any)) {
            return failureCount < QUERY_CONFIG.maxRetries;
          }

          return false;
        },
        retryDelay: (attemptIndex) =>
          Math.min(
            QUERY_CONFIG.retryBaseDelay * Math.pow(2, attemptIndex),
            QUERY_CONFIG.maxRetryDelay,
          ),
        refetchOnWindowFocus: (query) => {
          return (
            query.state.dataUpdatedAt < Date.now() - QUERY_CONFIG.staleTime
          );
        },
        refetchOnReconnect: true,
      },
      mutations: {
        retry: false,
      },
    },
  });
}

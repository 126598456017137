import {
  ApiErrorCode,
  ErrorSeverity,
  LoginErrorResponse,
  ApiErrorResponse,
  isLoginError,
} from "../types";

export interface ApiErrorConstructor {
  message: string;
  status?: number;
  code?: ApiErrorCode;
  severity?: ErrorSeverity;
  translationKey?: string;
  response?: {
    data: ApiErrorResponse | LoginErrorResponse;
  };
}

export class ApiError extends Error {
  readonly status?: number;
  readonly code: ApiErrorCode;
  readonly severity: ErrorSeverity;
  readonly translationKey?: string;
  readonly response?: ApiErrorConstructor["response"];

  constructor({
    message,
    status,
    code = "unknown",
    severity = "error",
    translationKey,
    response,
  }: ApiErrorConstructor) {
    super(message);
    this.name = "ApiError";
    this.status = status;
    this.code = code;
    this.severity = severity;
    this.translationKey = translationKey;
    this.response = response;

    // Use Object.setPrototypeOf for proper inheritance in transpiled code
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  getErrorDetails(): string {
    if (this.response?.data) {
      if (isLoginError(this.response.data)) {
        return this.response.data.error_description;
      }
      if (
        "errors" in this.response.data &&
        this.response.data.errors.length > 0
      ) {
        return this.response.data.errors[0]?.detail ?? this.message;
      }
    }
    return this.message;
  }
}

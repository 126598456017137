import { useTranslation } from "@repo/config";
import { cn } from "@repo/ui/lib/utils";
import { Link, useLocation } from "react-router-dom";
import {
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from "@repo/ui/components/ui/Sidebar";
import { SETTINGS_ROUTES } from "../../config/routes";
import { LucideIcon, Settings } from "lucide-react";

export default function SettingsSidebar() {
  const { t } = useTranslation("settings");
  const location = useLocation();

  const isActiveUrl = (path: string) => {
    if (path === "/settings") {
      return location.pathname === "/settings";
    }
    return location.pathname.startsWith(path);
  };

  const renderNavLink = ({
    url,
    children,
    icon: Icon,
  }: {
    url: string;
    children: React.ReactNode;
    icon?: LucideIcon;
  }) => (
    <Link
      to={url}
      className={cn(
        "flex items-center gap-3 px-4 py-2 text-sm rounded-md w-full",
      )}
    >
      {Icon && <Icon className="w-4 h-4" />}
      <span className="hidden md:inline">{children}</span>
    </Link>
  );

  return (
    <div className="w-16 md:w-64 border-r border-border h-full space-y-2">
      {/* Header */}
      <div className="px-6 py-4 border-b border-border">
        <h2 className="text-lg font-semibold">
          <Settings className="size-4 md:hidden" />
          <span className="hidden md:inline">{t("title")}</span>
        </h2>
      </div>

      {/* Menu */}
      <SidebarMenu className="p-2">
        {Object.values(SETTINGS_ROUTES).map((route) => (
          <SidebarMenuItem key={route.path}>
            <SidebarMenuButton
              asChild
              isActive={isActiveUrl(route.path)}
              className="flex items-center justify-center md:justify-start"
            >
              {renderNavLink({
                url: route.path,
                children: t(route.translationKey),
                icon: route.icon,
              })}
            </SidebarMenuButton>
          </SidebarMenuItem>
        ))}
      </SidebarMenu>
    </div>
  );
}

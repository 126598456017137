import { useMutation } from "@tanstack/react-query";
import { useSettingsContext } from "../contexts/SettingsContext";
import { ChangePasswordParams } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

export function useChangePassword() {
  const { authService } = useSettingsContext();
  const currentLanguage = useCurrentLanguage();

  const changePasswordMutation = useMutation({
    mutationFn: async (data: ChangePasswordParams) => {
      await authService.changePassword(data, currentLanguage);
    },
  });

  return changePasswordMutation;
}

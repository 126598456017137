import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { reportService } from "@/main";
import { ExpeditionTimesParams } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

const QUERY_KEY = "expedition-times";

export const useExpeditionTimes = (params: ExpeditionTimesParams) => {
  const currentLanguage = useCurrentLanguage();

  const queryKey = [
    QUERY_KEY,
    currentLanguage,
    params.page,
    params.limit,
    params.filters?.search,
    params.filters?.ordering,
    params.filters?.expedition_type,
    params.filters?.is_completed,
    params.filters?.end_date__gte,
    params.filters?.end_date__lte,
  ];

  const query = useQuery({
    queryKey,
    queryFn: () =>
      reportService.getExpeditionTimes({
        ...params,
        language: currentLanguage,
        limit: params.limit ?? 10,
        offset: ((params.page ?? 1) - 1) * (params.limit ?? 10),
      }),
    placeholderData: keepPreviousData,
  });

  const isPending = query.isLoading || query.isFetching;

  return {
    ...query,
    isPending,
  };
};

export const translations = {
  tr: {
    error: {
      default: {
        title: "Hata",
      },
    },
    title: "Ayarlar",
    menu: {
      profile_settings: "Profil Ayarları",
      change_password: "Şifre Değiştir",
      my_addresses: "Adres Bilgileri",
    },
    profile: {
      form: {
        first_name: {
          label: "Ad",
          placeholder: "Adınızı girin",
        },
        last_name: {
          label: "Soyad",
          placeholder: "Soyadınızı girin",
        },
        email: {
          label: "E-posta",
          placeholder: "E-posta adresinizi girin",
        },
        phone: {
          label: "Telefon",
          placeholder: "Telefon numaranızı girin",
        },
        company_name: {
          label: "Şirket Adı",
          placeholder: "Şirket adınızı girin",
        },
      },
      validation: {
        first_name: {
          required: "Ad alanı zorunludur",
          min: "Ad en az {{min}} karakter olmalıdır",
          max: "Ad en fazla {{max}} karakter olabilir",
        },
        last_name: {
          required: "Soyad alanı zorunludur",
          min: "Soyad en az {{min}} karakter olmalıdır",
          max: "Soyad en fazla {{max}} karakter olabilir",
        },
        email: {
          required: "E-posta adresi zorunludur",
          invalid: "Geçerli bir e-posta adresi giriniz",
        },
        phone: {
          required: "Telefon numarası zorunludur",
          invalid: "Geçerli bir telefon numarası giriniz",
        },
        company_name: {
          required: "Şirket adı zorunludur",
          min: "Şirket adı en az {{min}} karakter olmalıdır",
          max: "Şirket adı en fazla {{max}} karakter olabilir",
        },
      },
    },
    change_password: {
      form: {
        old_password: {
          label: "Mevcut Şifre",
          placeholder: "Mevcut şifrenizi girin",
        },
        new_password: {
          label: "Yeni Şifre",
          placeholder: "Yeni şifrenizi girin",
        },
        confirm_password: {
          label: "Şifre Tekrar",
          placeholder: "Yeni şifrenizi tekrar girin",
        },
        submit: {
          text: "Şifreyi Değiştir",
          loading: "Şifre değiştiriliyor...",
        },
      },
      validation: {
        old_password: {
          required: "Mevcut şifrenizi girmelisiniz",
        },
        password: {
          required: "Yeni şifrenizi girmelisiniz",
          min: "Şifreniz en az {{min}} karakter olmalıdır",
          pattern:
            "Şifreniz en az bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir",
        },
        confirm_password: {
          required: "Şifrenizi tekrar girmelisiniz",
          match: "Şifreler eşleşmiyor",
        },
      },
      success: {
        title: "Şifre değiştirildi",
        message: "Şifreniz başarıyla değiştirildi",
      },
    },
    address: {
      validation: {
        title: {
          required: "Adres başlığı zorunludur",
          min: "Başlık en az {{min}} karakter olmalıdır",
          max: "Başlık en fazla {{max}} karakter olabilir",
        },
        address: {
          required: "Adres detayı zorunludur",
          min: "Adres en az {{min}} karakter olmalıdır",
          max: "Adres en fazla {{max}} karakter olabilir",
        },
        city: {
          required: "Şehir seçimi zorunludur",
        },
        district: {
          required: "İlçe seçimi zorunludur",
        },
        postal_code: {
          required: "Posta kodu zorunludur",
          pattern: "Geçerli bir posta kodu giriniz",
        },
      },
    },
  },
  en: {
    error: {
      default: {
        title: "Error",
      },
    },
    title: "Settings",
    menu: {
      profile_settings: "Profile Settings",
      change_password: "Change Password",
      my_addresses: "Address Information",
    },
    profile: {
      form: {
        first_name: {
          label: "First Name",
          placeholder: "Enter your first name",
        },
        last_name: {
          label: "Last Name",
          placeholder: "Enter your last name",
        },
        email: {
          label: "Email",
          placeholder: "Enter your email address",
        },
        phone: {
          label: "Phone",
          placeholder: "Enter your phone number",
        },
        company_name: {
          label: "Company Name",
          placeholder: "Enter your company name",
        },
      },
      validation: {
        first_name: {
          required: "First name is required",
          min: "First name must be at least {{min}} characters",
          max: "First name cannot exceed {{max}} characters",
        },
        last_name: {
          required: "Last name is required",
          min: "Last name must be at least {{min}} characters",
          max: "Last name cannot exceed {{max}} characters",
        },
        email: {
          required: "Email address is required",
          invalid: "Please enter a valid email address",
        },
        phone: {
          required: "Phone number is required",
          invalid: "Please enter a valid phone number",
        },
        company_name: {
          required: "Company name is required",
          min: "Company name must be at least {{min}} characters",
          max: "Company name cannot exceed {{max}} characters",
        },
      },
    },
    change_password: {
      form: {
        old_password: {
          label: "Current Password",
          placeholder: "Enter your current password",
        },
        new_password: {
          label: "New Password",
          placeholder: "Enter your new password",
        },
        confirm_password: {
          label: "Confirm Password",
          placeholder: "Confirm your new password",
        },
        submit: {
          text: "Change Password",
          loading: "Changing password...",
        },
      },
      validation: {
        old_password: {
          required: "Current password is required",
        },
        password: {
          required: "New password is required",
          min: "Password must be at least {{min}} characters",
          pattern:
            "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
        },
        confirm_password: {
          required: "Please confirm your password",
          match: "Passwords do not match",
        },
      },
      success: {
        title: "Password changed",
        message: "Your password has been successfully changed",
      },
    },
    address: {
      validation: {
        title: {
          required: "Address title is required",
          min: "Title must be at least {{min}} characters",
          max: "Title cannot exceed {{max}} characters",
        },
        address: {
          required: "Address detail is required",
          min: "Address must be at least {{min}} characters",
          max: "Address cannot exceed {{max}} characters",
        },
        city: {
          required: "City selection is required",
        },
        district: {
          required: "District selection is required",
        },
        postal_code: {
          required: "Postal code is required",
          pattern: "Please enter a valid postal code",
        },
      },
    },
  },
};

import { ApiClient } from "../../infrastructure/apiClient";
import {
  GetOrdersParams,
  GetOrderParams,
  Order,
  OrdersResponse,
  OrderDetail,
} from "./types";

export class OrderService {
  constructor(private readonly apiClient: ApiClient) {}

  /**
   * Get the freight demands
   * @param params - The parameters for getting freight demands
   * @returns Promise<OrdersResponse>
   */
  async getOrders(
    params: GetOrdersParams & { language: string },
  ): Promise<OrdersResponse> {
    const { page, limit = 15, filters, language } = params;
    const offset = (page - 1) * limit;

    return this.apiClient.get<OrdersResponse>(
      `${language}/orders/freight-demands`,
      {
        params: {
          offset,
          limit,
          ...filters,
        },
      },
    );
  }

  async getOrder(
    params: GetOrderParams & { language: string },
  ): Promise<OrderDetail> {
    const { orderNo, language } = params;
    return this.apiClient.get<OrderDetail>(
      `${language}/orders/freight-demands/${orderNo}`,
    );
  }
}

export const createOrderService = (apiClient: ApiClient) =>
  new OrderService(apiClient);

import type { ApiClient } from "../../infrastructure/apiClient";
import type { User } from "../../types/user";
import type { PermissionResponse } from "../../types";

export class ProfileService {
  constructor(private readonly apiClient: ApiClient) {}

  /**
   * Get user profile
   * @returns Profile
   */
  async getMyProfile(language: string): Promise<User> {
    const ENDPOINT = `${language}/accounts/my-profile`;
    return this.apiClient.get<User>(ENDPOINT);
  }

  async getMyPermissions(language: string): Promise<PermissionResponse> {
    const ENDPOINT = `${language}/permissions/my`;
    return this.apiClient.get<PermissionResponse>(ENDPOINT);
  }
}

export const createProfileService = (apiClient: ApiClient) =>
  new ProfileService(apiClient);

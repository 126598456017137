import {
  SubmitTicketResponse,
  TicketDetail,
  TicketReply,
  TicketRepsonse,
  CategoriesResponse,
  PrioritiesResponse,
  TemplatesResponse,
} from "./types";
import { Env } from "@repo/config";
import { ApiClient, createApiClient } from "../../infrastructure/apiClient";
import { AuthService } from "../auth";

export interface SubmitTicketReplyResponse {
  title: string;
  description: string;
}

/**
 * HeskService is a service that provides methods to interact with the Hesk API.
 * @class
 * @param {ApiClient} apiClient - The API client to use for requests.
 * @param {string} baseUrl - The base URL of the Hesk API.
 */
export class HeskService {
  constructor(
    private readonly apiClient: ApiClient,
    private readonly baseUrl: string,
  ) {
    if (!baseUrl) {
      throw new Error("HESK_API_URL is not defined");
    }
  }

  /**
   * Submits a ticket to the Hesk API.
   * @param {FormData} formData - The form data to submit.
   * @param {string} language - The language of the ticket.
   * @returns {Promise<SubmitTicketResponse>} The response from the Hesk API.
   */
  async submitTicket(
    formData: FormData,
    language: string,
  ): Promise<SubmitTicketResponse> {
    const response = await this.apiClient.post<SubmitTicketResponse>(
      `/${language}/hesk/tickets`,
      formData,
      {
        baseURL: this.baseUrl,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return response;
  }

  /**
   * Retrieves tickets from the Hesk API.
   * @param {string} language - The language of the tickets.
   * @returns {Promise<TicketRepsonse>} The response from the Hesk API.
   */
  async getTickets(language: string): Promise<TicketRepsonse> {
    const response = await this.apiClient.get<TicketRepsonse>(
      `/${language}/hesk/tickets`,
      {
        baseURL: this.baseUrl,
      },
    );

    return response;
  }

  /**
   * Retrieves the details of a ticket from the Hesk API.
   * @param {string} language - The language of the ticket.
   * @param {string} ticketNo - The number of the ticket.
   * @returns {Promise<TicketDetail>} The details of the ticket.
   */
  async getTicketDetail(
    language: string,
    ticketNo: string,
  ): Promise<TicketDetail> {
    const response = await this.apiClient.get<TicketDetail>(
      `/${language}/hesk/tickets/${ticketNo}`,
      {
        baseURL: this.baseUrl,
      },
    );

    return response;
  }

  /**
   * Get the ticket replies
   * @param {string} language - The language of the ticket.
   * @param {string} ticketNo - The number of the ticket.
   * @returns {Promise<TicketReply[]>} The replies of the ticket.
   */
  async getTicketReplies(
    language: string,
    ticketNo: string,
  ): Promise<TicketReply[]> {
    const response = await this.apiClient.get<TicketReply[]>(
      `/${language}/hesk/tickets/${ticketNo}/replies`,
      {
        baseURL: this.baseUrl,
      },
    );

    return response;
  }

  /**
   * Submit a reply to a ticket
   * @param {string} language - The language of the ticket
   * @param {string} ticketNo - The ticket number
   * @param {FormData} formData - The form data containing message and optional attachment
   * @returns {Promise<SubmitTicketReplyResponse>} The response from the API
   */
  async submitTicketReply(
    language: string,
    ticketNo: string,
    formData: FormData,
  ): Promise<SubmitTicketReplyResponse> {
    const response = await this.apiClient.post<SubmitTicketReplyResponse>(
      `/${language}/hesk/tickets/${ticketNo}/replies`,
      formData,
      {
        baseURL: this.baseUrl,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return response;
  }

  /**
   * Get Categories
   * @returns {Promise<Category[]>} The categories
   */
  async getCategories(language: string): Promise<CategoriesResponse> {
    const response = await this.apiClient.get<CategoriesResponse>(
      `/${language}/hesk/categories`,
      {
        baseURL: this.baseUrl,
      },
    );

    return response;
  }

  /**
   * Get Priorities
   */
  async getPriorities(language: string): Promise<PrioritiesResponse> {
    const response = await this.apiClient.get<PrioritiesResponse>(
      `/${language}/hesk/priorities`,
      {
        baseURL: this.baseUrl,
      },
    );

    return response;
  }

  /**
   * Get Templates
   */
  async getTemplates(language: string): Promise<TemplatesResponse> {
    const response = await this.apiClient.get<TemplatesResponse>(
      `/${language}/hesk/tickets/templates`,
      {
        baseURL: this.baseUrl,
      },
    );

    return response;
  }
}

/**
 * Creates a new HeskService instance.
 * @param {Env} env - The environment variables.
 * @param {AuthService} authService - The authentication service.
 * @returns {HeskService} The HeskService instance.
 */
export const createHeskService = (env: Env, authService: AuthService) => {
  if (!env?.API.HESK_API_URL) {
    throw new Error("HESK_API_URL is not defined");
  }

  return new HeskService(
    createApiClient(env, authService),
    env.API.HESK_API_URL,
  );
};

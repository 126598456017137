import { useState, useEffect, useCallback } from "react";
import { formatTimeAgo, useTranslation } from "@repo/config";

/**
 * Use time ago hook
 * @description This hook is used to format the time ago
 * @param timestamp - Timestamp
 * @returns Time ago
 */
export function useTimeAgo(timestamp: string) {
  const [timeAgo, setTimeAgo] = useState("");
  const { i18n } = useTranslation();

  // Get time ago
  const getTimeAgo = useCallback(() => {
    try {
      return formatTimeAgo(timestamp);
    } catch (error) {
      console.error("Time ago format error:", error);
      return "";
    }
  }, [timestamp]);

  // Get update interval
  const getUpdateInterval = useCallback(() => {
    const seconds = Math.floor(
      (new Date().getTime() - new Date(timestamp).getTime()) / 1000,
    );

    if (seconds < 60) return 1000; // every second
    if (seconds < 3600) return 60000; // every minute
    if (seconds < 86400) return 3600000; // every hour
    return 86400000; // every day
  }, [timestamp]);

  // Update time ago
  useEffect(() => {
    setTimeAgo(getTimeAgo());

    const interval = getUpdateInterval();
    const timer = setInterval(() => {
      setTimeAgo(getTimeAgo());
    }, interval);

    return () => clearInterval(timer);
  }, [getTimeAgo, getUpdateInterval]);

  // Language change listener
  useEffect(() => {
    setTimeAgo(getTimeAgo());
  }, [i18n.language, getTimeAgo]);

  return timeAgo;
}

export interface AuthEnv {
  LOGIN_URL: string;
  CLIENT_ID: string;
  CLIENT_SECRET: string;
  GRANT_TYPE: string;
  COOKIE_DOMAIN: string;
  REGISTER_CLIENT_ID: string;
  REGISTER_CLIENT_SECRET: string;
  AUTH_APP_URL: string;
  NODE_ENV: string;
  REVOKE_URL: string;
}

export interface Env {
  AUTH: AuthEnv;
  API: {
    BASE_URL: string;
    CHAT_WEBSOCKET_URL?: string;
    HESK_API_URL?: string;
  };
  ENCRYPTION_KEY: string;
  NODE_ENV: string;
}

export const createEnv = (envVars: Record<string, string>): Env => {
  const getEnvVar = (key: string, isOptional = false): string => {
    const value = envVars[key];

    // check if required env vars are defined
    if (!isOptional && value === undefined) {
      throw new Error(`Environment variable ${key} is required`);
    }

    return value ?? "";
  };

  return {
    API: {
      BASE_URL: getEnvVar("VITE_API_BASE_URL"),
      CHAT_WEBSOCKET_URL: getEnvVar("VITE_CHAT_WEBSOCKET_URL", true),
      HESK_API_URL: getEnvVar("VITE_HESK_API_URL", true),
    },
    AUTH: {
      CLIENT_ID: getEnvVar("VITE_AUTH_CLIENT_ID"),
      CLIENT_SECRET: getEnvVar("VITE_AUTH_CLIENT_SECRET"),
      LOGIN_URL: getEnvVar("VITE_AUTH_LOGIN_URL"),
      GRANT_TYPE: getEnvVar("VITE_AUTH_GRANT_TYPE"),
      COOKIE_DOMAIN: getEnvVar("VITE_AUTH_COOKIE_DOMAIN"),
      REGISTER_CLIENT_ID: getEnvVar("VITE_AUTH_REGISTER_CLIENT_ID"),
      REGISTER_CLIENT_SECRET: getEnvVar("VITE_AUTH_REGISTER_CLIENT_SECRET"),
      AUTH_APP_URL: getEnvVar("VITE_AUTH_APP_URL"),
      REVOKE_URL: getEnvVar("VITE_AUTH_REVOKE_URL"),
      NODE_ENV: getEnvVar("VITE_NODE_ENV"),
    },
    ENCRYPTION_KEY: getEnvVar("VITE_ENCRYPTION_KEY"),
    NODE_ENV: getEnvVar("VITE_NODE_ENV"),
  };
};

export type Environment = ReturnType<typeof createEnv>;

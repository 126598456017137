import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { ProfileService } from "@repo/service";
import { AUTH_KEYS } from "./useAuthQuery";

/**
 * Interface for useProfileQuery hook parameters
 */
interface UseProfileQueryProps {
  /** Service for handling profile operations */
  profileService: ProfileService;
  /** Current language setting */
  language: string;
  /** Flag indicating if user is authenticated */
  isAuthenticated: boolean;
}

/**
 * Custom hook to manage user profile data
 *
 * This hook handles:
 * - Fetching user profile data
 * - Validating user access to specific apps
 * - Refreshing profile data when needed
 *
 * @param {UseProfileQueryProps} props - Hook parameters
 * @returns {Object} Profile query, validation, and refresh functions
 */
export function useProfileQuery({
  profileService,
  language,
  isAuthenticated,
}: UseProfileQueryProps) {
  const queryClient = useQueryClient();

  /**
   * Query for fetching user profile data
   */
  const profileQuery = useQuery({
    queryKey: [...AUTH_KEYS.profile, "me"],
    queryFn: () => profileService.getMyProfile(language),
    retry: false,
    enabled: isAuthenticated,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  /**
   * Permissions query
   */
  const permissionsQuery = useQuery({
    queryKey: [...AUTH_KEYS.profile, "permissions"],
    queryFn: () => profileService.getMyPermissions(language),
    enabled: isAuthenticated,
    staleTime: 2 * 60 * 1000, // 2 minutes
  });

  /**
   * Forces a refresh of the user profile data
   */
  const refreshProfile = () => {
    if (isAuthenticated) {
      queryClient.invalidateQueries({ queryKey: AUTH_KEYS.profile });
    }
  };

  return {
    profileQuery,
    permissionsQuery,
    refreshProfile,
  };
}

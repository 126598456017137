import { useState, useRef } from "react";
import { Button } from "@repo/ui/components/ui/Button";
import { Textarea } from "@repo/ui/components/ui/Textarea";
import { useTranslation } from "@repo/config";
import { HeskService } from "@repo/service";
import { useSubmitTicketReply } from "../hooks/useSubmitTicketReply";
import { toastManager } from "@repo/ui/utils/toastManager";
import { Loader2, Send, Paperclip, X } from "lucide-react";
import { cn } from "@repo/ui/lib/utils";
import { ALLOWED_FILE_TYPES } from "../constants/allowedFileTypes";
import { handleFileChange } from "../utils/attachmentCheck";

interface TicketReplyFormProps {
  ticketNo: string;
  heskService: HeskService;
  onSuccess?: () => void;
}

export default function TicketReplyForm({
  ticketNo,
  heskService,
  onSuccess,
}: Readonly<TicketReplyFormProps>) {
  const { t } = useTranslation("hesk");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { submitReply, isLoading } = useSubmitTicketReply({
    ticketNo,
    heskService,
    onSuccess: (response) => {
      toastManager.success(response.title, response.description);
      setMessage("");
      setAttachment(null);
      onSuccess?.();
    },
    onError: (error) => {
      toastManager.error(t("tickets.replies.submit_error"), error.message);
    },
  });

  const handleSubmit = async () => {
    if (!message.trim()) return;
    await submitReply(message, attachment || undefined);
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = handleFileChange(e);
    if (file) {
      setAttachment(file);
    }
  };

  return (
    <div
      className={cn(
        "flex-none p-3 rounded-b-lg",
        "bg-card",
        "border border-border",
      )}
    >
      <div className="space-y-3">
        <Textarea
          placeholder={t("tickets.replies.placeholder")}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="min-h-[80px] resize-none"
        />
        <div className="flex items-center justify-between gap-3">
          <div className="flex items-center gap-2">
            <input
              type="file"
              ref={fileInputRef}
              onChange={onFileChange}
              accept={ALLOWED_FILE_TYPES.join(",")}
              className="hidden"
            />
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => fileInputRef.current?.click()}
              className="h-8 gap-2"
            >
              <Paperclip className="size-3" />
              <p className="max-w-48 truncate">
                {attachment
                  ? attachment.name
                  : t("tickets.replies.attach_file")}
              </p>
            </Button>
            {attachment && (
              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={() => setAttachment(null)}
                className="h-8 text-destructive hover:text-destructive/90"
              >
                <X className="size-3" />
              </Button>
            )}
          </div>
          <Button
            onClick={handleSubmit}
            disabled={!message.trim() || isLoading}
            className="h-8 gap-2"
          >
            {isLoading ? (
              <Loader2 className="size-3 animate-spin" />
            ) : (
              <Send className="size-3" />
            )}
            {t("tickets.replies.submit")}
          </Button>
        </div>
      </div>
    </div>
  );
}

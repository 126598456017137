import { Skeleton } from "@repo/ui/components/ui/Skeleton";
import TicketCardSkeleton from "./TicketCardSkeleton";
import { createSkeletonKey } from "../../utils/generateSkeletonKey";

export default function MyTicketsSkeleton() {
  return (
    <div className="py-4 px-2 space-y-4 w-full">
      {/* Filter Buttons */}
      <div className="flex items-center gap-2">
        <Skeleton className="h-8 bg-card w-32 md:w-40 border border-border" />
        <Skeleton className="h-8 bg-card w-32 md:w-40 border border-border" />
      </div>

      {/* Active Tickets */}
      <div className="space-y-3 h-[calc(100vh-20rem)] overflow-hidden">
        {Array.from({ length: 5 }).map((_, i) => (
          <TicketCardSkeleton key={createSkeletonKey(i)} />
        ))}
      </div>
    </div>
  );
}

import { useQuery } from "@tanstack/react-query";
import { HeskService } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

export const useGetTicketReplies = (
  heskService: HeskService,
  ticketNo: string | null,
) => {
  const language = useCurrentLanguage();

  return useQuery({
    queryKey: ["ticketReplies", ticketNo],
    queryFn: () => {
      if (!ticketNo) throw new Error("Ticket number is required");
      return heskService.getTicketReplies(language, ticketNo);
    },
    enabled: !!ticketNo,
  });
};

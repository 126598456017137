import { Card } from "@repo/ui/components/ui/Card";
import { Skeleton } from "@repo/ui/components/ui/Skeleton";

export default function LoadOtsMapSkeleton() {
  return (
    <Card className="w-full h-full relative">
      {/* Harita arka planı için gri alan */}
      <div className="absolute inset-0 bg-muted animate-pulse" />

      {/* Sağ alt köşede zoom kontrolleri için iskelet */}
      <div className="absolute bottom-4 right-4 z-10">
        <div className="flex flex-col gap-1">
          <Skeleton className="w-7 h-7" />
          <Skeleton className="w-7 h-7" />
        </div>
      </div>
    </Card>
  );
}

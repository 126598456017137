import React from "react";
import { AppName } from "@repo/config";
import { useUser } from "../providers";

interface WithPermissionProps {
  requiredPermissions?: string[];
  targetApp?: AppName;
}

/**
 * Helper function to check permissions and role access
 */
const checkAccess = (
  permissions: string[],
  requiredPermissions: string[],
  roleManager: any,
  targetApp?: AppName,
) => {
  // First, check role access
  if (targetApp) {
    const roleValidation = roleManager.checkAccess(targetApp);
    if (!roleValidation.hasAccess) return false;
  }

  // Then, check permission access
  return (
    !requiredPermissions?.length ||
    requiredPermissions.every((permission) => permissions.includes(permission))
  );
};

/**
 * Higher Order Component for permission-based access control
 * If no permissions are required (empty or undefined array), access is granted
 *
 * @example
 * ```tsx
 * // Component requires permissions
 * const ProtectedComponent = withPermission(Component, {
 *   requiredPermissions: ['admin_access'],
 *   targetApp: AppName.ADMIN
 * });
 *
 * // Public component (no permissions required)
 * const PublicComponent = withPermission(Component, {
 *   requiredPermissions: [],
 *   targetApp: AppName.CUSTOMER
 * });
 * ```
 */
export const withPermission = (
  WrappedComponent: React.ComponentType<any>,
  { requiredPermissions = [], targetApp }: WithPermissionProps,
) => {
  return function PermissionWrapper(props: any) {
    const { roleManager, isLoading, permissions } = useUser();

    if (isLoading) {
      return null; // or a loading component
    }

    return checkAccess(
      permissions,
      requiredPermissions,
      roleManager,
      targetApp,
    ) ? (
      <WrappedComponent {...props} />
    ) : null;
  };
};

/**
 * Hook for checking permissions and access control
 *
 * @param requiredPermissions - Array of required permission codes
 * @param targetApp - Target application for role check
 * @returns Object with access control methods and states
 *
 * @example
 * ```tsx
 * const { canAccess, checkPermission } = usePermission(['view_reports'], AppName.ADMIN);
 *
 * // Check specific permissions
 * if (checkPermission(['edit_user'])) {
 *   // User can edit
 * }
 *
 * // Check access with role and permissions
 * if (canAccess) {
 *   // User has access
 * }
 * ```
 */
export const usePermission = (
  requiredPermissions: string[] = [],
  targetApp?: AppName,
) => {
  const { roleManager, permissions } = useUser();

  // Check specific permissions
  const checkPermission = (perms: string[]) => {
    return (
      !perms?.length ||
      perms.every((permission) => permissions.includes(permission))
    );
  };

  return {
    canAccess: checkAccess(
      permissions,
      requiredPermissions,
      roleManager,
      targetApp,
    ),
    checkPermission,
    hasPermission: (permission: string) => permissions.includes(permission),
  };
};

import { useToast } from "../../hooks/useToast";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@repo/ui/components/ui/Toast";
import { CircleAlert, CheckCircle2, AlertCircle, Info } from "lucide-react";
import { useEffect } from "react";
import { toastManager } from "@repo/ui/utils/toastManager";
import { cn } from "@repo/ui/lib/utils";

const toastIcons = {
  error: (
    <CircleAlert
      fill="#ef4444"
      strokeWidth={2}
      className="min-w-12 min-h-12 p-2.5 text-destructive-background bg-destructive/10 dark:bg-destructive-background rounded-md"
    />
  ),
  success: (
    <CheckCircle2
      fill="#22c55e"
      strokeWidth={2}
      className="min-w-12 min-h-12 p-2.5 text-success-background bg-success/10 dark:bg-success-background rounded-md"
    />
  ),
  warning: (
    <AlertCircle
      fill="#f59e0b"
      strokeWidth={2}
      className="min-w-12 min-h-12 p-2.5 text-warning-background bg-warning/10 dark:bg-warning-background rounded-md"
    />
  ),
  info: (
    <Info
      fill="#3b82f6"
      strokeWidth={2}
      className="min-w-12 min-h-12 p-2.5 text-info-background bg-info/10 dark:bg-info-background rounded-md"
    />
  ),
};

const toastStyles = {
  error:
    "border-2 bg-white dark:bg-[#FF4C52]/5 backdrop-blur border-destructive/50 dark:border-destructive-background",
  success:
    "border-2 bg-white dark:bg-[#22C55E]/5 backdrop-blur border-success/50 dark:border-success-background",
  warning:
    "border-2 bg-white dark:bg-[#FF9E42]/5 backdrop-blur border-warning/50 dark:border-warning-background",
  info: "border-2 bg-white dark:bg-[#3B82F6]/5 backdrop-blur border-info/50",
};

export function Toaster() {
  const { toast, toasts } = useToast();

  useEffect(() => {
    toastManager.setToastFunction((props) => {
      toast(props);
    });
  }, [toast]);

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        type,
        ...props
      }) {
        const icon = toastIcons[type];
        const style = toastStyles[type];

        return (
          <Toast
            key={id}
            {...props}
            className={cn(style, "shadow-lg dark:shadow-none")}
          >
            <div className="flex items-center gap-2">
              <ToastClose className="absolute right-2 top-2 opacity-70 transition-opacity hover:opacity-100" />
              {icon}
              <div className="grid gap-1">
                {title && (
                  <ToastTitle
                    className={cn(
                      "text-sm font-semibold",
                      type === "error" &&
                        "text-destructive dark:text-destructive-foreground",
                      type === "success" &&
                        "text-success dark:text-success-foreground",
                      type === "warning" &&
                        "text-warning dark:text-warning-foreground",
                      type === "info" && "text-info dark:text-info-foreground",
                    )}
                  >
                    {title}
                  </ToastTitle>
                )}
                {description && (
                  <ToastDescription className="text-muted-foreground">
                    {description}
                  </ToastDescription>
                )}
              </div>
            </div>
            {action}
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}

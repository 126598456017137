import { Role, AppName, RoleValidationResult, APP_ROLES } from "@repo/config";
import { ApiError } from "@repo/service";

export class RoleManager {
  constructor(private readonly userRole: Role | null | undefined) {}

  private createValidationResult(
    hasAccess: boolean,
    reason?: RoleValidationResult["reason"],
  ): RoleValidationResult {
    return { hasAccess, ...(reason && { reason }) };
  }

  private isRoleAllowed(role: Role, app: AppName): boolean {
    return APP_ROLES[app].allowed.includes(role as any);
  }

  private validateAccess(targetApp: AppName): RoleValidationResult {
    if (!this.userRole) {
      return this.createValidationResult(false, "INVALID_ROLE");
    }

    if (this.userRole === "NEW_USER") {
      return this.createValidationResult(false, "NEW_USER");
    }

    if (!this.isRoleAllowed(this.userRole, targetApp)) {
      return this.createValidationResult(false, "FORBIDDEN_ROLE");
    }

    return this.createValidationResult(true);
  }

  checkAccess(targetApp: AppName): RoleValidationResult {
    return this.validateAccess(targetApp);
  }

  validateWithError(targetApp: AppName): void {
    const { hasAccess, reason } = this.validateAccess(targetApp);

    if (!hasAccess && reason) {
      throw new ApiError({
        message: reason,
        code: "forbidden",
        severity: "error",
        translationKey: `error.auth.${reason.toLowerCase()}`,
      });
    }
  }

  static createFromRole(role: Role | null | undefined): RoleManager {
    return new RoleManager(role);
  }
}

export type RoleManagerType = InstanceType<typeof RoleManager>;

import type { ApiClient } from "../../infrastructure/apiClient";
import type {
  ExpeditionTimeResponse,
  ExpeditionTimesParams,
  ExpeditionTypesResponse,
} from "./types";

export class ReportService {
  constructor(private readonly apiClient: ApiClient) {}

  /**
   * Get the expedition times
   * @param params - Expedition times params
   * @returns Expedition times
   */
  async getExpeditionTimes(
    params: ExpeditionTimesParams & { language: string },
  ): Promise<ExpeditionTimeResponse> {
    const ENDPOINT = `${params.language}/reports/expedition-times`;

    // Modify the params to match the API requirements
    const apiParams = {
      limit: params.limit,
      offset: params.offset,
      search: params.filters?.search,
      ordering: params.filters?.ordering,
      expedition_type: params.filters?.expedition_type,
      is_completed: params.filters?.is_completed,
      end_date__gte: params.filters?.end_date__gte,
      end_date__lte: params.filters?.end_date__lte,
    };

    // Remove undefined values
    const cleanParams = Object.fromEntries(
      Object.entries(apiParams).filter(([_key, value]) => value !== undefined),
    );

    return this.apiClient.get<ExpeditionTimeResponse>(ENDPOINT, {
      params: cleanParams,
    });
  }

  async getExpeditionTypes(language: string): Promise<ExpeditionTypesResponse> {
    const ENDPOINT = `${language}/orders/expedition-types`;
    return this.apiClient.get<ExpeditionTypesResponse>(ENDPOINT);
  }
}

// Factory function
export const createReportService = (apiClient: ApiClient) =>
  new ReportService(apiClient);

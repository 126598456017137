import { Book as BookIcon, BookOpen } from "lucide-react";
import { useTranslation } from "@repo/config";
import { Book } from "../books";
import { cn } from "@repo/ui/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@repo/ui/components/ui/Dialog.tsx";
import { Skeleton } from "@repo/ui/components/ui/Skeleton";
import { useState } from "react";
import { LoadingProgress } from "@repo/ui/components/ui/LoadingProgress";

interface BookCardProps {
  readonly book: Book;
  readonly onBookClick: (book: Book) => void;
  readonly bookImage: string;
  readonly isOpen?: boolean;
  readonly onClose: () => void;
}

export function BookCard({
  book,
  onBookClick,
  bookImage,
  isOpen = false,
  onClose,
}: BookCardProps) {
  const { t } = useTranslation("library");
  const [imageLoading, setImageLoading] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(true);

  return (
    <>
      <button
        onClick={() => onBookClick(book)}
        className="w-full h-full cursor-pointer"
      >
        <div className="relative aspect-[3/4] w-full rounded-md overflow-hidden group  h-full shadow-sm hover:shadow-lg transition-shadow duration-300  ">
          {/* Book Image with Loading State */}
          <div className="w-full h-full">
            {bookImage ? (
              <>
                {imageLoading && (
                  <Skeleton className="w-full h-full absolute inset-0" />
                )}
                <img
                  src={bookImage}
                  alt={book.title}
                  className={cn(
                    "w-full h-full object-cover transition-all duration-300",
                    "group-hover:scale-105",
                    imageLoading ? "opacity-0" : "opacity-100",
                  )}
                  onLoad={() => setImageLoading(false)}
                />
              </>
            ) : (
              <div className="w-full h-full bg-muted flex items-center justify-center">
                <BookIcon className="h-12 w-12 text-muted-foreground" />
              </div>
            )}
          </div>

          {/* Book Information Overlay */}
          <div
            className={cn(
              "absolute inset-0 bg-gradient-to-t from-background/95 via-background/60 to-transparent",
              "flex flex-col justify-end p-4",
              "hidden sm:flex",
              "transform translate-y-[110%] transition-transform duration-300 ease-out",
              "group-hover:translate-y-0",
            )}
          >
            <div className="space-y-2">
              <h3 className="font-semibold text-base line-clamp-2 transition-opacity duration-200">
                {book.title}
              </h3>
              {book.author && (
                <p className="text-xs text-muted-foreground">
                  {t("author")}:{" "}
                  <span className="font-medium text-foreground/80">
                    {book.author}
                  </span>
                </p>
              )}
              {book.description && (
                <p className="text-xs text-muted-foreground line-clamp-3 transition-opacity duration-200">
                  {book.description}
                </p>
              )}
            </div>
          </div>
        </div>
      </button>

      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[90vw] h-[90vh] p-4 sm:p-6 overflow-hidden">
          <DialogHeader className="pb-2 flex items-center justify-between flex-row">
            <DialogTitle className="text-xl">{book.title}</DialogTitle>
          </DialogHeader>
          <div className="flex-1 w-full h-[calc(90vh-80px)] relative">
            {iframeLoading && (
              <LoadingProgress
                icon={<BookOpen />}
                title={t("loading.title")}
                description={t("loading.description")}
              />
            )}
            <iframe
              src={book.path}
              className="w-full h-full border-none"
              title={book.title}
              style={{ height: "100%" }}
              allowFullScreen
              onLoad={() => setIframeLoading(false)}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

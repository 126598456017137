import { useQuery } from "@tanstack/react-query";
import { HeskService } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

export const useGetCategories = (heskService: HeskService) => {
  const language = useCurrentLanguage();

  return useQuery({
    queryKey: ["categories"],
    queryFn: () => {
      return heskService.getCategories(language);
    },
    gcTime: 1000 * 60 * 30, // keep in cache for 30 minutes
  });
};

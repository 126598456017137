import ExpeditionTimesTableSkeleton from "@/components/skeletons/ExpeditionTimesTableSkeleton";
import { Suspense } from "react";
import { withPermission } from "@repo/provider";
import { AppName } from "@repo/config";
import ExpeditionTimesTable from "@/features/reports/components/ExpeditionTimesTable";

const ProtectedExpeditionTimesTable = withPermission(ExpeditionTimesTable, {
  requiredPermissions: ["view_siberexpedition"],
  targetApp: AppName.EMPLOYEE,
});

/**
 * @function Dashboard
 * @description Dashboard page component
 * @returns {JSX.Element} Dashboard page component
 */
export default function Dashboard() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-4">
      <Suspense fallback={<ExpeditionTimesTableSkeleton />}>
        <ProtectedExpeditionTimesTable />
      </Suspense>
    </div>
  );
}

import { Input } from "@repo/ui/components/ui/Input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select.tsx";
import { DateRangePicker } from "@repo/ui/components/table/DateRangePicker";
import {
  useTranslation,
  DateRange,
  enUS,
  i18n,
  tr,
  useDebounce,
} from "@repo/config";
import { ExpeditionTimeFilters } from "@repo/service";
import { useExpeditionTypes } from "@/features/reports/hooks/useExpeditionTypes";
import { Search } from "lucide-react";
import { useEffect, useState } from "react";
import { ScrollArea, ScrollBar } from "@repo/ui/components/ui/ScrollArea";

interface TableFiltersProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
  expeditionType?: ExpeditionTimeFilters["expedition_type"];
  onExpeditionTypeChange: (
    type: ExpeditionTimeFilters["expedition_type"] | undefined,
  ) => void;
  isCompleted?: ExpeditionTimeFilters["is_completed"];
  onIsCompletedChange: (
    value: ExpeditionTimeFilters["is_completed"] | undefined,
  ) => void;
  dateRange?: DateRange;
  onDateRangeChange: (date: DateRange | undefined) => void;
  ordering?: ExpeditionTimeFilters["ordering"];
  onOrderingChange: (
    value: ExpeditionTimeFilters["ordering"] | undefined,
  ) => void;
}

export const ExpeditionTimesTableFilters = ({
  searchValue,
  onSearchChange,
  expeditionType,
  onExpeditionTypeChange,
  isCompleted,
  onIsCompletedChange,
  dateRange,
  onDateRangeChange,
  ordering,
  onOrderingChange,
}: TableFiltersProps) => {
  const { t } = useTranslation("reports");
  const { data: expeditionTypes, isLoading: isLoadingTypes } =
    useExpeditionTypes();
  const [localSearch, setLocalSearch] = useState(searchValue);
  const debouncedSearch = useDebounce(localSearch, 300);

  useEffect(() => {
    if (debouncedSearch !== searchValue) {
      onSearchChange(debouncedSearch);
    }
  }, [debouncedSearch, onSearchChange, searchValue]);

  useEffect(() => {
    setLocalSearch(searchValue);
  }, [searchValue]);

  const ORDERING_OPTIONS = [
    { value: "-end_date", label: t("expedition.filter.ordering.endDateDesc") },
    { value: "end_date", label: t("expedition.filter.ordering.endDateAsc") },
    {
      value: "-start_date",
      label: t("expedition.filter.ordering.startDateDesc"),
    },
    {
      value: "start_date",
      label: t("expedition.filter.ordering.startDateAsc"),
    },
  ];

  return (
    <ScrollArea className="w-full whitespace-nowrap">
      <div className="flex overflow-x-auto items-center gap-4 w-full p-1">
        <div className="relative">
          <Input
            placeholder={t("search.placeholder")}
            value={localSearch}
            onChange={(event) => setLocalSearch(event.target.value)}
            className="max-w-sm pl-9 min-w-[200px]"
          />
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 size-4 text-muted-foreground" />
        </div>
        {/* ORDERING */}
        <Select value={ordering} onValueChange={onOrderingChange}>
          <SelectTrigger className="w-auto">
            <SelectValue
              placeholder={t("expedition.filter.ordering.placeholder")}
            />
          </SelectTrigger>
          <SelectContent>
            {ORDERING_OPTIONS.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {/* EXPEDITION TYPE */}
        <Select
          value={expeditionType?.toString() ?? ""}
          onValueChange={(value) =>
            onExpeditionTypeChange(value === "all" ? "" : value)
          }
          disabled={isLoadingTypes}
        >
          <SelectTrigger className="w-auto max-w-[150px]">
            <SelectValue
              placeholder={t("expedition.filter.type.placeholder")}
            />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">
              {t("expedition.filter.type.all")}
            </SelectItem>
            {expeditionTypes?.results.map((type) => (
              <SelectItem
                key={type.row_id}
                value={(type.row_id - 1).toString()}
              >
                {type.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {/* IS COMPLETED */}
        <Select
          value={isCompleted === undefined ? "all" : isCompleted.toString()}
          onValueChange={(value) =>
            onIsCompletedChange(value === "all" ? "" : value)
          }
        >
          <SelectTrigger className="w-auto max-w-[150px]">
            <SelectValue
              placeholder={t("expedition.filter.status.placeholder")}
            />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">
              {t("expedition.filter.status.all")}
            </SelectItem>
            <SelectItem value="YES">
              {t("expedition.filter.status.completed")}
            </SelectItem>
            <SelectItem value="NO">
              {t("expedition.filter.status.in_progress")}
            </SelectItem>
          </SelectContent>
        </Select>
        {/* DATE RANGE */}
        <div className="xl:ml-auto">
          <DateRangePicker
            date={dateRange}
            onDateChange={onDateRangeChange}
            placeholder={t("expedition.filter.date.placeholder")}
            locale={i18n.language === "tr" ? tr : enUS}
          />
        </div>
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
};

import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useLoad } from "../hooks/useLoad";
import { parseAsString, useQueryState } from "nuqs";
import { ApiError, LoadDetail, LoadService } from "@repo/service";

interface LoadProviderProps {
  readonly children: ReactNode;
  readonly loadService: LoadService;
}

interface LoadContextType {
  selectedLoad: LoadDetail | null;
  setSelectedLoadNo: (loadNo: string) => void;
  isLoading: boolean;
  error: Error | ApiError | null;
  loadService: LoadService;
}

export const LoadContext = createContext<LoadContextType | undefined>(
  undefined,
);

export function useLoadContext() {
  const context = useContext(LoadContext);
  if (!context) {
    throw new Error("useLoadContext must be used within a LoadProvider");
  }
  return context;
}

export default function LoadProvider({
  children,
  loadService,
}: LoadProviderProps) {
  const [selectedLoadNo, setSelectedLoadNo] = useQueryState(
    "load_no",
    parseAsString,
  );

  const { load, isLoading, error } = useLoad(selectedLoadNo ?? "", loadService);

  const handleSetSelectedLoadNo = useCallback(
    async (loadNo: string) => {
      setSelectedLoadNo(loadNo);
    },
    [setSelectedLoadNo],
  );

  const contextValue = useMemo(
    () => ({
      selectedLoad: load || null,
      setSelectedLoadNo: handleSetSelectedLoadNo,
      isLoading,
      error,
      loadService,
    }),
    [load, handleSetSelectedLoadNo, isLoading, error, loadService],
  );

  return (
    <LoadContext.Provider value={contextValue}>{children}</LoadContext.Provider>
  );
}

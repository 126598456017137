import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { Skeleton } from "@repo/ui/components/ui/Skeleton";
import { createSkeletonKey } from "../../utils/createSkeletonKey";

export default function LoadOtsListSkeleton() {
  return (
    <div className="w-full">
      <Card className="rounded-lg py-2 backdrop-blur-md bg-opacity-50 bg-card/30 dark:bg-card/80">
        <CardContent className="px-2">
          <div className="flex flex-col gap-2">
            {/* Search Input ve Filter Area */}
            <div className="flex items-center gap-2">
              <Skeleton className="h-10 shadow flex-1" /> {/* Search input */}
              <Skeleton className="h-10 shadow w-10" /> {/* Filter button */}
            </div>

            {/* Count Badge */}
            <div className="flex items-center  gap-2">
              <Skeleton className="h-5 w-12 shadow rounded-full" />
            </div>

            {/* Load Cards List */}
            <div className="overflow-hidden h-[calc(100svh-15rem)]">
              <div className="pr-2 pt-1 pl-1 space-y-2">
                {Array.from({ length: 15 }).map((_, index) => (
                  <div
                    key={createSkeletonKey(index)}
                    className="p-1.5 border shadow rounded-lg"
                  >
                    <div className="flex gap-2">
                      {/* Üst Satır - Load No ve Status */}
                      <div className="flex justify-between items-center">
                        <Skeleton className="size-8 rounded-full" />{" "}
                        {/* Car Badge */}
                      </div>

                      {/* Orta Satır - Tarih ve Detaylar */}
                      <div className="flex flex-col gap-2 items-start">
                        <Skeleton className="h-4 w-24" /> {/* Tarih */}
                        <Skeleton className="h-4 w-8" /> {/* Detay */}
                      </div>

                      {/* Alt Satır - Lokasyon Bilgileri */}
                      <div className="ml-auto">
                        <Skeleton className="h-4 w-16" /> {/* Icon */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

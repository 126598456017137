import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useUser } from "../../providers";
import { toastManager } from "@repo/ui/utils/toastManager";
import { useTranslation } from "@repo/config";
import { LoadingProgress } from "@repo/ui/components/ui/LoadingProgress";
import { AppGuardProps } from "./types";
import { RoleManager } from "../../utils/roleManager";

/**
 * AppGuard component
 * Protects the entire app based on authentication and role access
 *
 * @param {AppGuardProps} props - Component props
 * @returns {JSX.Element} Protected content or loading state
 */
export function AppGuard({ appName, authAppUrl }: Readonly<AppGuardProps>) {
  const { isAuthenticated, user, isLoading } = useUser();
  const { t } = useTranslation(["common", "auth"]);
  const [shouldRedirect, setShouldRedirect] = useState<string | null>(null);

  // Create login URL with return URL
  const getLoginUrl = () => {
    const returnUrl = encodeURIComponent(window.location.href);
    return `${authAppUrl}/login?returnUrl=${returnUrl}`;
  };

  useEffect(() => {
    // Eğer yükleme tamamlandıysa ve kullanıcı authenticate olmamışsa
    if (!isLoading && !isAuthenticated) {
      setShouldRedirect(getLoginUrl());
      return;
    }

    // Kullanıcı authenticate olmuşsa, rol kontrolü yap
    if (!isLoading && user?.role_level) {
      const roleManager = RoleManager.createFromRole(user.role_level);
      const { hasAccess, reason } = roleManager.checkAccess(appName);

      if (!hasAccess) {
        toastManager.error(t(`auth:errors.${reason?.toLowerCase()}`));
        setShouldRedirect(getLoginUrl());
      }
    }
  }, [isAuthenticated, isLoading, appName, authAppUrl, user, t]);

  // Show loading state
  if (isLoading) {
    return <LoadingProgress showTruck={true} />;
  }

  // Handle redirects
  if (shouldRedirect) {
    window.location.href = shouldRedirect;
    return <LoadingProgress showTruck={true} />;
  }

  // If everything is OK, render the protected content
  return <Outlet />;
}

import { useLoadContext } from "../contexts/LoadContext";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/Card";
import { Truck, Calendar, MapPin, User, Phone, Mail } from "lucide-react";
import {
  formatAddress,
  formatDateMonthName,
  useTranslation,
  formatContactInfo,
} from "@repo/config";

import { cn } from "@repo/ui/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";
import LoadOtsDetailSkeleton from "./skeletons/LoadOtsDetailSkeleton";

/**
 * @module LoadOtsDetail
 * @description Component for displaying detailed information of selected load.
 * Shows load status, customer information, location details, etc.
 *
 * @component
 * @example
 * return (
 *   <LoadOtsDetail />
 * )
 */
export default function LoadOtsDetail() {
  const { t } = useTranslation("loads");
  const { selectedLoad, isLoading, error } = useLoadContext();

  if (isLoading) {
    return <LoadOtsDetailSkeleton />;
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center py-8 text-center">
        <p className="text-lg font-medium text-destructive">
          {t("ots.detail.error.fetch_title", {
            loadNo: selectedLoad?.load_no,
          })}
        </p>
      </div>
    );
  }

  if (selectedLoad) {
    const customerContact = formatContactInfo(
      selectedLoad.customer_representative,
    );
    const salesContact = formatContactInfo(selectedLoad.sales_representative);

    return (
      <div className="space-y-4">
        <Card className="rounded-lg py-2 backdrop-blur-md bg-opacity-50 bg-card/30 dark:bg-card/80">
          <CardHeader className="px-4 py-2">
            <div className="flex items-center gap-4">
              <div
                className={cn(
                  "rounded-full p-2",
                  selectedLoad.is_load_completed
                    ? "bg-success-background text-success"
                    : "bg-warning-background text-warning",
                )}
              >
                <Truck className="h-6 w-6" />
              </div>
              <div>
                <CardTitle className="text-lg">
                  {t("ots.detail.loadNo")}:{" "}
                  {selectedLoad.load_no || t("common.notSpecified")}
                </CardTitle>
                <p className="text-sm text-muted-foreground">
                  {selectedLoad.status?.status_name || t("ots.detail.noStatus")}
                </p>
              </div>
            </div>
          </CardHeader>

          <CardContent className="px-4 grid gap-6 max-h-[calc(100svh-215px)] overflow-y-auto">
            {/* Vehicle Information */}
            <div className="space-y-2">
              <h3 className="font-semibold flex items-center gap-2">
                <Truck className="h-4 w-4" />
                {t("ots.detail.sections.vehicle.title")}
              </h3>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                  <p className="text-muted-foreground">
                    {t("ots.detail.sections.vehicle.truck")}
                  </p>
                  <p className="font-medium">
                    {selectedLoad.truck_plate || t("common.notSpecified")}
                  </p>
                </div>
                <div>
                  <p className="text-muted-foreground">
                    {t("ots.detail.sections.vehicle.trailer")}
                  </p>
                  <p className="font-medium">
                    {selectedLoad.trailer_plate || t("common.notSpecified")}
                  </p>
                </div>
              </div>
            </div>

            {/* Date Information */}
            <div className="space-y-2">
              <h3 className="font-semibold flex items-center gap-2">
                <Calendar className="h-4 w-4" />
                {t("ots.detail.sections.dates.title")}
              </h3>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                  <p className="text-muted-foreground">
                    {t("ots.detail.sections.dates.loading")}
                  </p>
                  <p className="font-medium">
                    {formatDateMonthName(selectedLoad.loading_date)}
                  </p>
                </div>
                <div>
                  <p className="text-muted-foreground">
                    {selectedLoad.status?.status_id === 15
                      ? t("ots.detail.sections.dates.unloading")
                      : t("ots.detail.sections.dates.estimatedUnloading")}
                  </p>
                  <p className="font-medium">
                    {selectedLoad.status?.status_id === 15
                      ? formatDateMonthName(selectedLoad.unloading_date)
                      : formatDateMonthName(
                          selectedLoad.estimated_unloading_date,
                        )}
                  </p>
                </div>
              </div>
            </div>

            {/* Address Information */}
            <div className="space-y-2">
              <h3 className="font-semibold flex items-center gap-2">
                <MapPin className="h-4 w-4" />
                {t("ots.detail.sections.address.title")}
              </h3>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                  <p className="text-muted-foreground">
                    {t("ots.detail.sections.address.loading")}
                  </p>
                  <p className="font-medium">
                    {formatAddress(selectedLoad.load_address)}
                  </p>
                </div>
                <div>
                  <p className="text-muted-foreground">
                    {t("ots.detail.sections.address.unloading")}
                  </p>
                  <p className="font-medium">
                    {formatAddress(selectedLoad.unload_address)}
                  </p>
                </div>
              </div>
            </div>

            {/* Contact Information */}
            <div className="space-y-2">
              <h3 className="font-semibold flex items-center gap-2">
                <User className="h-4 w-4" />
                {t("ots.detail.sections.contact.title")}
              </h3>
              <div className="grid grid-cols-2 gap-4 text-sm w-full">
                <ContactInfo
                  title={t("ots.detail.sections.contact.customer.title")}
                  contact={customerContact}
                />
                <ContactInfo
                  title={t("ots.detail.sections.contact.sales.title")}
                  contact={salesContact}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

// Helper component
const ContactInfo = ({
  title,
  contact,
}: {
  title: string;
  contact: ReturnType<typeof formatContactInfo>;
}) => {
  return (
    <div className="space-y-4">
      <div>
        <p className="text-muted-foreground truncate">{title}</p>
        <p className="font-medium truncate">{contact.fullName}</p>
        <div className="flex items-center gap-2 mt-1 truncate">
          <Phone className="min-w-3 size-3" />
          <p>
            <a href={`tel:${contact.phone}`}>{contact.phone}</a>
          </p>
        </div>
        <div className="flex items-center gap-2 mt-1 truncate">
          <Mail className="min-w-4 size-4" />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild className="truncate">
                <p>
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </p>
              </TooltipTrigger>
              <TooltipContent side="left">
                <p className="font-medium">{contact.email}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
};

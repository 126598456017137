import OrdersTableSkeleton from "@/components/skeletons/OrdersTableSkeleton";
import { Suspense } from "react";
import { withPermission } from "@repo/provider";
import { AppName } from "@repo/config";
import OrderProvider from "@/contexts/OrderContext";
import { OrderDetailSheet } from "@/features/orders/components/OrderDetailSheet";
import OrdersTable from "@/features/orders/components/OrdersTable";

// Lazy imports
// const OrdersTable = lazy(
//   () => import("@/features/orders/components/OrdersTable"),
// );

export default function Orders() {
  const ProtectedOrdersTable = withPermission(OrdersTable, {
    requiredPermissions: ["view_freightdemand", "view_freightoffer"],
    targetApp: AppName.EMPLOYEE,
  });

  const ProtectedOrderDetailSheet = withPermission(OrderDetailSheet, {
    requiredPermissions: ["view_freightdemand", "view_freightoffer"],
    targetApp: AppName.EMPLOYEE,
  });

  return (
    <Suspense fallback={<OrdersTableSkeleton />}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-4">
        <OrderProvider>
          <ProtectedOrdersTable />
          <ProtectedOrderDetailSheet />
        </OrderProvider>
      </div>
    </Suspense>
  );
}

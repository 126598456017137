import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { Env } from "../env";
interface I18nConfig {
  fallbackLng?: string;
  supportedLngs?: string[];
  defaultNS?: string;
  loadPath?: string;
  resources?: Record<string, Record<string, any>>;
}

// Cookie for language
const LANGUAGE_COOKIE_NAME = "i18nextLng";
const COOKIE_EXPIRES_DAYS = 365; // 1 year

const getCookieExpirationDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + COOKIE_EXPIRES_DAYS);
  return date;
};

const getCookieOptions = (env: Env) => ({
  path: "/",
  expires: getCookieExpirationDate(),
  secure: env?.NODE_ENV === "production",
  sameSite: "lax" as const,
  domain: env?.AUTH?.COOKIE_DOMAIN || ".abclojistik.com",
});

const version = localStorage.getItem("app_version") ?? "__BUILD_VERSION__";

export interface I18nResources {
  namespace: string;
  resources: Record<string, any>;
}

export const initI18n = async (
  env: Env,
  ns: string[] = ["common"],
  config?: I18nConfig,
  resources: I18nResources[] = [],
) => {
  await i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: config?.fallbackLng ?? "tr",
      supportedLngs: config?.supportedLngs ?? ["tr", "en"],
      ns,
      defaultNS: config?.defaultNS ?? "common",
      backend: {
        loadPath:
          config?.loadPath ?? "/locales/{{lng}}/{{ns}}.json?v=" + version,
      },
      resources: config?.resources,
      detection: {
        order: ["cookie", "navigator"],
        caches: ["cookie"],
        lookupCookie: LANGUAGE_COOKIE_NAME,
        cookieOptions: getCookieOptions(env),
      },
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
    });

  // Add all resources after initialization
  resources.forEach(({ namespace, resources }) => {
    Object.keys(resources).forEach((lang) => {
      i18n.addResourceBundle(lang, namespace, resources[lang], true, true);
    });
  });

  return i18n;
};

export { i18n };
export type { I18nConfig };

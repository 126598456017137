import { createContext, useContext } from "react";
import type { AuthService } from "@repo/service";

interface SettingsContextType {
  authService: AuthService;
}

export const SettingsContext = createContext<SettingsContextType | null>(null);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettingsContext must be used within SettingsProvider");
  }
  return context;
};

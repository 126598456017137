import { useQuery } from "@tanstack/react-query";
import { GetLoadParams, LoadService } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

const QUERY_KEY = "load";

/**
 * Get the load
 * @param load_no - Load number
 * @param loadService - Load service instance
 * @returns Load
 */
export const useLoad = (
  load_no: GetLoadParams["load_no"],
  loadService: LoadService,
) => {
  const currentLanguage = useCurrentLanguage();

  const loadQuery = useQuery({
    queryKey: [QUERY_KEY, load_no, currentLanguage],
    queryFn: () => loadService.getLoad(load_no, currentLanguage),
    enabled: !!load_no,
  });

  return {
    load: loadQuery.data,
    isLoading: loadQuery.isLoading,
    error: loadQuery.error,
    refetch: loadQuery.refetch,
  };
};

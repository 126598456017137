import { AxiosRequestConfig } from "axios";

export type ApiErrorCode =
  | "validation"
  | "auth"
  | "network"
  | "server"
  | "not_found"
  | "forbidden"
  | "timeout"
  | "unknown";

export type ErrorSeverity = "error" | "warning" | "info";

export type ApiErrorDetail = {
  code: string;
  detail: string;
  attr: string;
};

export interface BaseErrorResponse {
  severity?: ErrorSeverity;
  translationKey?: string;
}

export interface ApiErrorResponse extends BaseErrorResponse {
  type: ApiErrorCode;
  errors: ApiErrorDetail[];
}

export interface LoginErrorResponse extends BaseErrorResponse {
  error: string;
  error_description: string;
}

export type ApiRequestConfig = Omit<AxiosRequestConfig, "baseURL"> & {
  retry?: number;
  retryDelay?: number;
  skipAuthRefresh?: boolean;
  _retryCount?: number;
  baseURL?: string;
};

export type ApiResponse<T = unknown> = {
  data: T;
  status: number;
  headers: Record<string, string>;
};

/**
 * Type guard to check if the error is a login error returned from the OAuth server
 */
export const isLoginError = (error: unknown): error is LoginErrorResponse => {
  return (
    typeof error === "object" &&
    error !== null &&
    "error" in error &&
    "error_description" in error
  );
};

/**
 * Type guard to check if the error is an API error returned from the API server
 */
export const isApiErrorResponse = (
  error: unknown,
): error is ApiErrorResponse => {
  return (
    typeof error === "object" &&
    error !== null &&
    "type" in error &&
    "errors" in error &&
    Array.isArray((error as ApiErrorResponse).errors)
  );
};

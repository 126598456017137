import {
  SheetContent,
  Sheet,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetFooter,
} from "@repo/ui/components/ui/Sheet";
import { Button } from "@repo/ui/components/ui/Button";
import { Filter } from "lucide-react";
import { Badge } from "@repo/ui/components/ui/Badge";
import { useLoadStatuses } from "../hooks/useLoadStatuses";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select";
import { Label } from "@repo/ui/components/ui/Label";
import { Separator } from "@repo/ui/components/ui/Separator";
import { LoadFilterOptions, LoadStatus } from "@repo/service";
import { getActiveFilterCount } from "../utils/getActiveFilterCount";
import { useTranslation } from "@repo/config";

/**
 * @module LoadOtsFilterSheet
 * @description Sheet component for advanced load filtering options.
 * Provides filtering by status, completion state, and sorting options.
 *
 * @component
 * @param {Object} props
 * @param {LoadFilterOptions} props.filters - Current filter state
 * @param {Function} props.onFiltersChange - Callback for filter changes
 * @param {Function} props.onReset - Callback to reset all filters
 *
 * @example
 * return (
 *   <LoadOtsFilterSheet
 *     filters={filters}
 *     onFiltersChange={handleFilterChange}
 *     onReset={handleReset}
 *   />
 * )
 */

interface LoadOtsFilterSheetProps {
  filters: LoadFilterOptions;
  onFiltersChange: (filters: LoadFilterOptions) => void;
  onReset: () => void;
}

export default function LoadOtsFilterSheet({
  filters,
  onFiltersChange,
  onReset,
}: Readonly<LoadOtsFilterSheetProps>) {
  const { t } = useTranslation("loads");
  const {
    data: statusesData,
    isLoading: isLoadingStatuses,
    isError,
  } = useLoadStatuses();

  const ORDERING_OPTIONS = [
    {
      value: "-created_at",
      label: t("ots.filter.ordering.options.createdAtDesc"),
    },
    {
      value: "created_at",
      label: t("ots.filter.ordering.options.createdAtAsc"),
    },
    {
      value: "-loading_date",
      label: t("ots.filter.ordering.options.loadingDateDesc"),
    },
    {
      value: "loading_date",
      label: t("ots.filter.ordering.options.loadingDateAsc"),
    },
  ];

  const handleStatusChange = (value: string) => {
    const newFilters = { ...filters };
    if (value === "all") {
      delete newFilters.status_number;
    } else {
      newFilters.status_number = parseInt(value);
    }
    onFiltersChange(newFilters);
  };

  const currentStatusValue = filters.status_number
    ? filters.status_number.toString()
    : "all";

  // Get active filter count but does not show ordering filter if ordering is -created_at
  const activeFilterCount = getActiveFilterCount(filters);

  const renderStatusSelect = () => {
    if (isLoadingStatuses) {
      return (
        <SelectTrigger disabled>
          <SelectValue placeholder={t("ots.filter.status.loading")} />
        </SelectTrigger>
      );
    }

    if (isError) {
      return (
        <SelectTrigger disabled>
          <SelectValue placeholder={t("ots.filter.status.error")} />
        </SelectTrigger>
      );
    }

    return (
      <>
        <SelectTrigger>
          <SelectValue placeholder={t("ots.filter.status.placeholder")} />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="all">{t("ots.filter.status.all")}</SelectItem>
          {statusesData?.results.map((status: LoadStatus) => (
            <SelectItem
              key={status.status_number}
              value={status.status_number.toString()}
            >
              {status.status_name} ({status.item_count})
            </SelectItem>
          ))}
        </SelectContent>
      </>
    );
  };

  return (
    <Sheet>
      <SheetTrigger className="relative">
        <Button
          asChild
          variant="secondary"
          size="icon"
          className="size-8 p-1.5 bg-background"
        >
          <Filter />
        </Button>
        {/* Show badge if there are active filters */}
        {activeFilterCount > 0 && (
          <Badge
            variant="destructive"
            className="absolute hover:bg-destructive pointer-events-none -top-2 size-4 p-1.5 flex items-center justify-center -right-2 text-xs"
          >
            {activeFilterCount}
          </Badge>
        )}
      </SheetTrigger>
      <SheetContent side="right" className="w-[400px] sm:w-[540px]">
        <SheetHeader>
          <SheetTitle>{t("ots.filter.title")}</SheetTitle>
          <SheetDescription>{t("ots.filter.description")}</SheetDescription>
        </SheetHeader>

        <div className="grid gap-6 py-6">
          <div className="space-y-2">
            <Label>{t("ots.filter.ordering.label")}</Label>
            <Select
              value={filters.ordering}
              onValueChange={(value) =>
                onFiltersChange({ ...filters, ordering: value })
              }
            >
              <SelectTrigger>
                <SelectValue
                  placeholder={t("ots.filter.ordering.placeholder")}
                />
              </SelectTrigger>
              <SelectContent>
                {ORDERING_OPTIONS.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label>{t("ots.filter.status.label")}</Label>
            <Select
              value={currentStatusValue}
              onValueChange={handleStatusChange}
              disabled={isLoadingStatuses || isError}
            >
              {renderStatusSelect()}
            </Select>
          </div>

          <div className="space-y-2">
            <Label>{t("ots.filter.completion.label")}</Label>
            <Select
              value={
                filters.is_load_completed === undefined
                  ? "all"
                  : filters.is_load_completed.toString()
              }
              onValueChange={(value) => {
                const newFilters = { ...filters };
                if (value === "all") {
                  delete newFilters.is_load_completed;
                } else {
                  newFilters.is_load_completed = value === "true";
                }
                onFiltersChange(newFilters);
              }}
            >
              <SelectTrigger>
                <SelectValue
                  placeholder={t("ots.filter.completion.placeholder")}
                />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">
                  {t("ots.filter.completion.all")}
                </SelectItem>
                <SelectItem value="true">
                  {t("ots.filter.completion.completed")}
                </SelectItem>
                <SelectItem value="false">
                  {t("ots.filter.completion.incomplete")}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          <Separator />
        </div>

        <SheetFooter>
          <Button variant="outline" onClick={onReset} className="w-full">
            {t("ots.filter.reset")}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}

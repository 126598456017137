import { Outlet } from "react-router-dom";
import { SidebarProvider } from "@repo/ui/components/ui/Sidebar";
import Navbar from "@/components/dashboard/navbar/Navbar";
import Sidebar from "@/components/dashboard/sidebar/Sidebar";

/**
 * @function DashboardLayout
 * @description Layout component for the dashboard.
 * @returns {JSX.Element} The dashboard layout component.
 */
const DashboardLayout = (): JSX.Element => {
  return (
    <SidebarProvider>
      <div className="flex w-full">
        <Sidebar />
        <div className="flex-1 flex flex-col gap-4 min-h-screen pl-4">
          <Navbar />
          <div className="flex flex-1 gap-4 pr-4">
            <div className="flex-1">
              <Outlet />
            </div>
            {/* <Rightbar /> */}
          </div>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default DashboardLayout;

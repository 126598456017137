import { toastManager } from "@repo/ui/utils/toastManager";
import {
  MAX_FILE_SIZE,
  ALLOWED_FILE_TYPES,
} from "../constants/allowedFileTypes";
import { i18next } from "@repo/config";

export const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const file = e.target.files?.[0];
  if (!file) return null;

  // MAX FILE SIZE CHECK
  if (file.size > MAX_FILE_SIZE) {
    toastManager.error(
      i18next.t("form.validation.attachments.max"),
      i18next.t("form.validation.attachments.max_description", {
        maxSize: MAX_FILE_SIZE / (1024 * 1024),
      }),
    );
    return null;
  }

  // FILE TYPE CHECK
  if (!ALLOWED_FILE_TYPES.includes(file.type)) {
    toastManager.error(
      i18next.t("form.validation.attachments.type"),
      i18next.t("form.validation.attachments.type_description"),
    );
    return null;
  }

  return file;
};

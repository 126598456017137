import { Column } from "@tanstack/react-table";
import { cn } from "@repo/ui/lib/utils";
import { BadgeInfo } from "@repo/ui/components/ui/BadgeInfo";

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
  hasTooltip?: boolean;
}

export function DataTableColumnHeader<TData, TValue>({
  title,
  className,
  hasTooltip,
}: Readonly<DataTableColumnHeaderProps<TData, TValue>>) {
  return (
    <div
      className={cn(
        "font-medium flex items-center gap-1.5 text-sm whitespace-nowrap",
        className,
      )}
    >
      <span>{title}</span>
      {hasTooltip && <BadgeInfo />}
    </div>
  );
}

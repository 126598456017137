import { LoadFilterOptions, LoadStatus } from "@repo/service";
import { TFunction } from "@repo/config";

type BadgeConfig = {
  key: keyof LoadFilterOptions;
  label: string;
  getValue: (value: any, statuses?: LoadStatus[], t?: TFunction) => string;
  isVisible: (filters: LoadFilterOptions) => boolean;
};

export const createFilterBadgeConfig = (t: TFunction): BadgeConfig[] => [
  {
    key: "search",
    label: t("ots.filter.search.label"),
    getValue: (value) => value,
    isVisible: (filters) => !!filters.search,
  },
  {
    key: "status_number",
    label: t("ots.filter.status.label"),
    getValue: (value, statuses) =>
      statuses?.find((status) => status.status_number === value)?.status_name ??
      `${t("ots.filter.status.unknown")} ${value}`,
    isVisible: (filters) => filters.status_number !== undefined,
  },
  {
    key: "is_load_completed",
    label: t("ots.filter.completion.label"),
    getValue: (value, _statuses, t) => {
      if (!t) return "";
      return t(`ots.filter.completion.${value ? "completed" : "incomplete"}`);
    },
    isVisible: (filters) => filters.is_load_completed !== undefined,
  },
  {
    key: "ordering",
    label: t("ots.filter.ordering.label"),
    getValue: (value) => {
      const orderingMap: Record<string, string> = {
        created_at: t("ots.filter.ordering.options.createdAtDesc"),
        "-created_at": t("ots.filter.ordering.options.createdAtAsc"),
        loading_date: t("ots.filter.ordering.options.loadingDateDesc"),
        "-loading_date": t("ots.filter.ordering.options.loadingDateAsc"),
      };
      return orderingMap[value] || value;
    },
    isVisible: (filters) =>
      filters.ordering !== undefined && filters.ordering !== "-created_at",
  },
];

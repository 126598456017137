import axios, { AxiosError } from "axios";
import { isApiErrorResponse, isLoginError, LoginErrorResponse } from "../types";
import { ApiError } from "../infrastructure/apiError";

export class ErrorNormalizer {
  private static instance: ErrorNormalizer;

  private constructor() {}

  public static getInstance(): ErrorNormalizer {
    if (!ErrorNormalizer.instance) {
      ErrorNormalizer.instance = new ErrorNormalizer();
    }
    return ErrorNormalizer.instance;
  }

  public normalize(error: unknown): ApiError {
    if (!navigator.onLine) {
      return this.createNetworkError("No internet connection");
    }

    if (axios.isAxiosError(error)) {
      return this.handleAxiosError(error);
    }

    if (error instanceof Error) {
      return this.createUnknownError(error.message);
    }

    return this.createUnknownError("An unexpected error occurred");
  }

  private handleAxiosError(error: AxiosError): ApiError {
    const status = error.response?.status;

    if (error.code === "ECONNABORTED") {
      return this.createTimeoutError(status);
    }

    if (error.code === "ERR_NETWORK") {
      return this.createNetworkError("Server is not reachable", status);
    }

    if (
      error.response?.data &&
      (isApiErrorResponse(error.response.data) ||
        isLoginError(error.response.data))
    ) {
      if (isLoginError(error.response.data)) {
        return this.createLoginError(
          error.response.data.error_description,
          error.response.data,
          status,
        );
      }

      return new ApiError({
        message: error.message,
        status,
        response: { data: error.response.data },
      });
    }

    return this.createUnknownError(error.message, status);
  }

  private createNetworkError(message: string, status?: number): ApiError {
    return new ApiError({
      message,
      status,
      code: "network",
      severity: "error",
      translationKey: "error.network",
    });
  }

  private createTimeoutError(status?: number): ApiError {
    return new ApiError({
      message: "İstek zaman aşımına uğradı",
      status,
      code: "timeout",
      severity: "error",
      translationKey: "error.timeout",
    });
  }

  private createLoginError(
    message: string,
    data: LoginErrorResponse,
    status?: number,
  ): ApiError {
    return new ApiError({
      message,
      status,
      code: "auth",
      severity: "error",
      translationKey: "error.auth",
      response: { data },
    });
  }

  private createUnknownError(message: string, status?: number): ApiError {
    return new ApiError({
      message,
      status,
      code: "unknown",
      severity: "error",
      translationKey: "error.unknown",
    });
  }
}

export const errorNormalizer = ErrorNormalizer.getInstance();

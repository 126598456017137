export const DEPARTMENTS = [
  { id: "Bilgi İşlem", name: "form.department.options.bilgi_islem" },
  { id: "Ar-ge", name: "form.department.options.ar_ge" },
  { id: "İthalat", name: "form.department.options.ithalat" },
  { id: "Sekreterlik", name: "form.department.options.sekreterlik" },
  { id: "İhracat", name: "form.department.options.ihracat" },
  { id: "Muhasebe", name: "form.department.options.muhasebe" },
  { id: "Finans", name: "form.department.options.finans" },
  { id: "Filo", name: "form.department.options.filo" },
  { id: "Hesap Birimi", name: "form.department.options.hesap_birimi" },
  { id: "Depo", name: "form.department.options.depo" },
  { id: "İnsan Kaynakları", name: "form.department.options.insan_kaynaklari" },
  { id: "Gümrük", name: "form.department.options.gumruk" },
  { id: "Mersin Şube", name: "form.department.options.mersin_sube" },
  { id: "İdari İşler", name: "form.department.options.idari_isler" },
  { id: "Kalite", name: "form.department.options.kalite" },
  { id: "Parsiyel", name: "form.department.options.parsiyel" },
  { id: "Satış & Pazarlama", name: "form.department.options.satis_pazarlama" },
  { id: "İstanbul Şube", name: "form.department.options.istanbul_sube" },
  { id: "Diğer", name: "form.department.options.diger" },
];

import { DashboardNavbar } from "@repo/ui/components/layout/DashboardNavbar";
import { ThemeToggle } from "../../theme/ThemeToggle";
import LanguageSwitcher from "@repo/ui/components/i18n/LanguageSwitcher";
import { useTranslation, useCurrentLanguage } from "@repo/config";
import { HeskDialog } from "@repo/hesk";
import { heskService } from "@/main";
/**
 * Dashboard navbar
 * @returns
 */
export default function Navbar() {
  const { t, i18n } = useTranslation();
  const currentLanguage = useCurrentLanguage();
  const onLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  // Actions
  const actions = (
    <div className="flex items-center space-x-2">
      {/* Hesk */}
      <HeskDialog heskService={heskService} />

      {/* Theme toggle */}
      <ThemeToggle />

      {/* Language switcher */}
      <LanguageSwitcher
        currentLanguage={currentLanguage}
        onLanguageChange={onLanguageChange}
        t={t}
      />
    </div>
  );

  return <DashboardNavbar actions={actions} />;
}

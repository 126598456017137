import { ChevronRight } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/Avatar";
import { useIsMobile } from "../../hooks/UseMobile";
import { cn } from "../../lib/utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../ui/DropDownMenu";

interface UserSidebarProfileProps {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly avatar: string;
  readonly actions?: React.ReactNode;
  readonly className?: string;
  readonly onProfileClick?: () => void;
}

export default function UserSidebarProfile({
  firstName,
  lastName,
  email,
  avatar,
  actions,
  className,
  onProfileClick,
}: UserSidebarProfileProps) {
  const isMobile = useIsMobile();

  // Calculate initial letters of the name
  const initials =
    `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`.toUpperCase();

  // Combine full name
  const fullName = `${firstName} ${lastName}`.trim();

  return (
    <div className={cn("pb-2.5", className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button
            className="w-full p-2 h-auto hover:bg-sidebar-accent rounded-lg transition-colors outline-none focus-visible:ring-2 focus-visible:ring-primary"
            onClick={onProfileClick}
            aria-label={`Profil menüsünü aç: ${fullName}`}
          >
            <div className="flex items-center gap-3 w-full">
              <Avatar className="h-10 w-10 border-2 border-border">
                <AvatarImage src={avatar} alt={fullName} loading="lazy" />
                <AvatarFallback
                  className="text-sm font-medium bg-primary/10 text-primary"
                  aria-label={`${fullName} profil resmi`}
                >
                  {initials}
                </AvatarFallback>
              </Avatar>
              <div className="flex-1 min-w-0 text-left overflow-hidden">
                <p className="text-sm font-semibold text-foreground truncate">
                  {fullName}
                </p>
                <p className="text-xs truncate text-muted-foreground">
                  {email}
                </p>
              </div>
              <ChevronRight
                className="size-4 text-muted-foreground/50"
                aria-hidden="true"
              />
            </div>
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={cn("w-56 rounded-lg", isMobile && "w-full")}
          align="end"
          side={isMobile ? "bottom" : "right"}
          sideOffset={isMobile ? 0 : 14}
        >
          {actions}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

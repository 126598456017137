import SettingsSidebar from "../components/common/SettingsSidebar";
import SettingsNavbar from "../components/common/SettingsNavbar";
import { Outlet } from "react-router-dom";
import { Card, CardContent } from "@repo/ui/components/ui/Card";

export default function SettingsLayout() {
  return (
    <Card className="h-[calc(100svh-80px)] md:h-[calc(100svh-100px)]">
      <CardContent className="flex flex-row p-0 gap-4 md:gap-6 h-full">
        <SettingsSidebar />
        <div className="flex flex-col gap-2 flex-1">
          <SettingsNavbar />
          <Outlet />
        </div>
      </CardContent>
    </Card>
  );
}

import { useTranslation } from "@repo/config";
import { useLocation } from "react-router-dom";
import { getRouteConfig } from "../../config/routes";

export default function SettingsNavbar() {
  const { t } = useTranslation("settings");
  const location = useLocation();
  const currentRoute = getRouteConfig(location.pathname);

  return (
    <div className="py-4 ">
      <h2 className="text-lg font-semibold">
        {t(currentRoute?.translationKey || "")}
      </h2>
    </div>
  );
}

import { Navigate, useLocation } from "react-router-dom";
import { usePermission } from "../../hoc/";
import { AppName } from "@repo/config";

export interface RouteGuardConfig {
  path: string;
  permissions: string[];
  fallbackPath?: string;
}

interface RouteGuardProps {
  config: RouteGuardConfig;
  appName: AppName;
  children: React.ReactNode;
}

interface RouteWithPermissions {
  path: string;
  permissions: string[];
  priority: number;
}

type RoutesMap = Record<string, RouteWithPermissions>;

export const RouteGuard: React.FC<RouteGuardProps> = ({
  config,
  appName,
  children,
}) => {
  const location = useLocation();
  const { canAccess } = usePermission(config.permissions, appName);

  if (location.pathname === "/") {
    return <Navigate to={config.fallbackPath || "/dashboard"} replace />;
  }

  if (!canAccess) {
    const redirectPath = config.fallbackPath || "/";
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

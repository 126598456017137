import { ColumnDef } from "@tanstack/react-table";
import { Order } from "@repo/service";
import { DataTableColumnHeader } from "@repo/ui/components/table/DataTableColumnHeader";
import { formatDateMonthName, useTranslation } from "@repo/config";
import { Badge } from "@repo/ui/components/ui/Badge";
import { TableCellContent } from "@repo/ui/components/table/TableCellContent";
import { Button } from "@repo/ui/components/ui/Button";
import useOrderContext from "@/contexts/useOrderContext";
import { getBadgeVariant } from "../utils/getBadgeVariant";
export const useOrdersTableColumns = () => {
  const { t } = useTranslation("orders");
  const { setSelectedOrderNo } = useOrderContext();

  const columns: ColumnDef<Order>[] = [
    {
      accessorKey: "freight_demand_no",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("orders.demand_no")} />
      ),
      cell: ({ row }) => (
        <Button
          variant="link"
          className="p-0 h-auto font-normal group flex items-center gap-1.5 underline"
          onClick={() => setSelectedOrderNo(row.getValue("freight_demand_no"))}
        >
          <TableCellContent content={row.getValue("freight_demand_no")} />
        </Button>
      ),
      enableSorting: true,
    },
    {
      accessorKey: "load_address",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("orders.load_address")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("load_address")} />
      ),
    },
    {
      accessorKey: "unload_address",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("orders.unload_address")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("unload_address")} />
      ),
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("orders.created_at")} />
      ),
      cell: ({ row }) => (
        <TableCellContent
          maxWidth="max-w-[150px]"
          content={formatDateMonthName(row.getValue("created_at"))}
        />
      ),
    },
    {
      accessorKey: "freight_demand_status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("orders.status")} />
      ),
      cell: ({ row }) => {
        const status = row.getValue<Order["freight_demand_status"]>(
          "freight_demand_status",
        );
        return (
          <Badge variant={getBadgeVariant(status.slug)}>
            <TableCellContent content={status.name} />
          </Badge>
        );
      },
    },
  ];

  return columns;
};

import { Input } from "@repo/ui/components/ui/Input";
import { useTranslation, useDebounce } from "@repo/config";
import { Search } from "lucide-react";
import { useState, useEffect } from "react";

interface OrdersTableFiltersProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
}

export const OrdersTableFilters = ({
  searchValue,
  onSearchChange,
}: OrdersTableFiltersProps) => {
  const { t } = useTranslation("orders");
  const [localSearch, setLocalSearch] = useState(searchValue);
  const debouncedSearch = useDebounce(localSearch, 500);

  useEffect(() => {
    if (debouncedSearch !== searchValue) {
      onSearchChange(debouncedSearch);
    }
  }, [debouncedSearch, onSearchChange, searchValue]);

  useEffect(() => {
    setLocalSearch(searchValue);
  }, [searchValue]);

  return (
    <div className="flex overflow-x-auto items-center gap-4 w-full p-1">
      <div className="flex flex-wrap items-center gap-4 w-full">
        <div className="relative">
          {/* Search input */}
          <Input
            placeholder={t("orders.search.placeholder")}
            value={localSearch}
            onChange={(event) => setLocalSearch(event.target.value)}
            className="max-w-sm pl-9"
          />
          {/* Search icon */}
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 size-4 text-muted-foreground" />
        </div>
        {/* TODO: Add filters */}
      </div>
    </div>
  );
};

class UserInteractionTracker {
  private static instance: UserInteractionTracker;
  private hasInteracted: boolean = false;
  private readonly listeners: Set<() => void> = new Set();

  private constructor() {
    this.setupListeners();
  }

  /**
   * Setup event listeners
   */
  private setupListeners() {
    const interactionEvents = ["click", "touchstart", "keydown", "mousedown"];

    const handleInteraction = () => {
      if (!this.hasInteracted) {
        this.hasInteracted = true;
        this.notifyListeners();
        // Remove event listeners after user has interaction with app.
        interactionEvents.forEach((event) => {
          document.removeEventListener(event, handleInteraction);
        });
      }
    };

    // Add event listeners to track user interaction
    interactionEvents.forEach((event) => {
      document.addEventListener(event, handleInteraction);
    });
  }

  /**
   * Get instance of UserInteractionTracker
   * @returns {UserInteractionTracker} UserInteractionTracker instance
   */
  public static getInstance(): UserInteractionTracker {
    if (!UserInteractionTracker.instance) {
      UserInteractionTracker.instance = new UserInteractionTracker();
    }
    return UserInteractionTracker.instance;
  }

  /**
   * Check if user has interacted with the app
   * @returns {boolean} User interacted
   */
  public hasUserInteracted(): boolean {
    return this.hasInteracted;
  }

  public onFirstInteraction(callback: () => void) {
    if (this.hasInteracted) {
      callback();
    } else {
      this.listeners.add(callback);
    }
  }

  /**
   * Notify listeners
   */
  private notifyListeners() {
    this.listeners.forEach((listener) => listener());
    this.listeners.clear();
  }
}

export const userInteractionTracker = UserInteractionTracker.getInstance();

import { useQuery } from "@tanstack/react-query";
import { HeskService } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

export const useGetTickets = (heskService: HeskService) => {
  const language = useCurrentLanguage();

  return useQuery({
    queryKey: ["tickets"],
    queryFn: () => {
      return heskService.getTickets(language);
    },
  });
};

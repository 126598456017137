export const ALLOWED_FILE_TYPES = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "application/zip",
  "application/x-rar-compressed",
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/plain",
  "application/pdf",
];

export const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

import { z } from "zod";
import { useTranslation } from "@repo/config";
import { ALLOWED_FILE_TYPES } from "../constants/allowedFileTypes";

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

export const useHeskSchema = () => {
  const { t } = useTranslation("hesk");

  return z.object({
    // Category and Priority
    category: z.string().min(1, t("form.validation.category.required")),

    priority: z.string().min(1, t("form.validation.priority.required")),

    // Department and Computer Code
    computer_code: z
      .string()
      .min(1, t("form.validation.computer_code.required"))
      .max(50, t("form.validation.computer_code.max", { max: 50 })),

    department: z.string().min(1, t("form.validation.department.required")),

    // Subject and Message
    subject: z
      .string()
      .min(1, t("form.validation.subject.required"))
      .min(4, t("form.validation.subject.min", { min: 4 }))
      .max(100, t("form.validation.subject.max", { max: 100 })),

    message: z
      .string()
      .min(1, t("form.validation.message.required"))
      .min(10, t("form.validation.message.min", { min: 10 }))
      .max(5000, t("form.validation.message.max", { max: 5000 })),

    attachments: z
      .instanceof(File)
      .refine(
        (file) => file.size <= MAX_FILE_SIZE,
        t("form.validation.attachments.max", {
          max: MAX_FILE_SIZE / (1024 * 1024),
        }),
      )
      .refine(
        (file) => ALLOWED_FILE_TYPES.includes(file.type),
        t("form.validation.attachments.type"),
      )
      .optional(),
  });
};

// Form values type
export type HeskFormValues = z.infer<ReturnType<typeof useHeskSchema>>;

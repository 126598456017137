import { Card, CardContent, CardHeader } from "@repo/ui/components/ui/Card";
import { Skeleton } from "@repo/ui/components/ui/Skeleton";
import { createSkeletonKey } from "../../utils/createSkeletonKey";

export default function LoadOtsDetailSkeleton() {
  return (
    <Card className="rounded-lg py-2 backdrop-blur-md bg-opacity-50 bg-card/30 dark:bg-card/80">
      <CardHeader className="px-4 py-2">
        <div className="flex items-center gap-4">
          <Skeleton className="h-10 w-10 rounded-full" />
          <div className="space-y-2">
            <Skeleton className="h-6 w-32" />
            <Skeleton className="h-4 w-24" />
          </div>
        </div>
      </CardHeader>
      <CardContent className="px-4 space-y-6">
        {Array.from({ length: 4 }).map((_, i) => (
          <div key={createSkeletonKey(i)} className="space-y-2">
            <Skeleton className="h-5 w-32" />
            <div className="grid grid-cols-2 gap-4">
              <Skeleton className="h-16" />
              <Skeleton className="h-16" />
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

import useOrderContext from "@/contexts/useOrderContext";
import { useTranslation } from "@repo/config";
import { Copy, X } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@repo/ui/components/ui/Sheet";
import { Button } from "@repo/ui/components/ui/Button";
import { toastManager } from "@repo/ui/utils";
import { getBadgeColor } from "../utils/getBadgeVariant";
import { cn } from "@repo/ui/lib/utils";
import { OrderDetailContent } from "./OrderDetailContent";

export const OrderDetailSheet = () => {
  const { t } = useTranslation("orders");
  const { selectedOrder, isLoading, error, isSheetOpen, closeSheet } =
    useOrderContext();

  const handleCopyFreightNo = async () => {
    if (!selectedOrder?.freight_demand_no) return;

    try {
      await navigator.clipboard.writeText(selectedOrder.freight_demand_no);
      toastManager.success(t("order.detail.copy.success"));
    } catch (error: any) {
      toastManager.error(t("order.detail.copy.error"), error.message);
    }
  };

  return (
    <Sheet
      open={isSheetOpen}
      modal={true}
      onOpenChange={(open) => {
        if (!open) {
          closeSheet();
        }
      }}
    >
      <SheetContent
        showClose={false}
        side="right"
        className="min-w-[96vw] md:min-w-[80vw] lg:min-w-[65vw] xl:min-w-[55vw] 2xl:min-w-[50vw] h-[calc(100vh-60px)] sm:h-[calc(100vh-80px)] top-[60px] sm:top-[80px] rounded-tl-lg"
      >
        <SheetHeader>
          <div className="flex items-center justify-between">
            {/* Sol: Title , Status Copy Freight No Button*/}
            <div className="flex items-center gap-2">
              {selectedOrder?.freight_demand_status && (
                <div
                  className={cn(
                    `rounded-full size-3.5 animate-pulse ${getBadgeColor(selectedOrder.freight_demand_status.slug)}`,
                  )}
                />
              )}
              <SheetTitle className="text-sm sm:text-lg">
                {t("order.detail.title")}
              </SheetTitle>
              {selectedOrder && (
                <div className="flex items-center gap-2">
                  <p className="text-xs">{selectedOrder.freight_demand_no}</p>
                  <Button
                    variant="ghost"
                    size="iconSm"
                    onClick={handleCopyFreightNo}
                    title={t("order.detail.copy.tooltip")}
                  >
                    <Copy className="size-4" />
                  </Button>
                </div>
              )}
            </div>

            {/* Sağ: Close */}
            <div className="flex items-center gap-4">
              <Button variant="outline" size="iconSm" onClick={closeSheet}>
                <X className="size-4" />
              </Button>
            </div>
          </div>
        </SheetHeader>
        <OrderDetailContent
          error={error}
          isLoading={isLoading}
          selectedOrder={selectedOrder}
        />
      </SheetContent>
    </Sheet>
  );
};

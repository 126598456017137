import React from "react";
import { cn } from "@repo/ui/lib/utils";
import { MessageBubble } from "./MessageBubble";
import { MessageAvatar } from "./MessageAvatar";
import { MessageTime } from "./MessageTime";
import type { ChatMessage as ChatMessageType } from "@repo/service";

interface ChatMessageProps {
  message: ChatMessageType;
}

//  use memo for message bubble
const MessageBubbleMemo = React.memo(MessageBubble);
const MessageTimeMemo = React.memo(MessageTime);

/**
 * Chat message
 * @param message Message
 * @returns Chat message
 */
export const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => {
  return (
    <div
      className={cn(
        "group relative flex items-start gap-2 hover:bg-muted/5 rounded-lg px-2 transition-colors",
      )}
    >
      {/* Only show avatar for received messages */}
      {!message.sent_by_me && <MessageAvatar author={message.author} />}

      <div
        className={cn(
          "flex flex-1 flex-col",
          message.sent_by_me ? "items-end" : "items-start",
        )}
      >
        {/* Message bubble */}
        <MessageBubbleMemo message={message} />
        {/* Message time */}
        <MessageTimeMemo timestamp={message.created_at} />
      </div>
    </div>
  );
};

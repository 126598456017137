import React, { createContext, useState, useMemo, useEffect } from "react";
import { UserContextType, UserProviderProps } from "./types";
import { useAuthQuery, useProfileQuery } from "../../hooks";
import { useTokenRefresh } from "../../hooks/useTokenRefresh";
import { RoleManager } from "../../utils/roleManager";
import type { Permission } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

/**
 * Context for user-related data and operations
 */
export const UserContext = createContext<UserContextType | undefined>(
  undefined,
);

/**
 * UserProvider component
 *
 * Manages user authentication state, profile data, and token refresh
 *
 * @param {UserProviderProps} props - Component props
 * @returns {React.ReactElement} Rendered component
 */
export const UserProvider: React.FC<UserProviderProps> = ({
  children,
  authService,
  profileService,
  env,
}: UserProviderProps): React.ReactElement => {
  // Ensure AUTH_APP_URL is provided in the environment configuration
  if (!env?.AUTH.AUTH_APP_URL) {
    throw new Error("AUTH_APP_URL is required in env configuration");
  }

  // Fetch and manage user profile data
  const { profileQuery, permissionsQuery, refreshProfile } = useProfileQuery({
    profileService,
    language: useCurrentLanguage(),
    isAuthenticated: !authService.isTokenExpired(),
  });

  // Initialize authentication state
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return !authService.isTokenExpired();
  });

  const { logoutMutation } = useAuthQuery(authService, env);

  // Handle token refresh
  useTokenRefresh({
    isAuthenticated,
    authService,
    refreshProfile,
    onLogout: () => {
      logoutMutation.mutate(undefined, {
        onSuccess: () => {
          setIsAuthenticated(false);
        },
      });
    },
  });

  // Update authentication state when profile data or auth service changes
  useEffect(() => {
    const hasValidToken = !authService.isTokenExpired();
    setIsAuthenticated(hasValidToken);
  }, [profileQuery.data, authService]);

  const roleManager = useMemo(
    () => RoleManager.createFromRole(profileQuery.data?.role_level),
    [profileQuery.data?.role_level],
  );

  const permissions = useMemo(() => {
    const permissionData = permissionsQuery.data as Permission[] | undefined;
    if (!permissionData?.length) return [];
    return permissionData.map((permission) => permission.codename);
  }, [permissionsQuery.data]);

  // Memoize context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      user: profileQuery.data || null,
      permissions,
      isAuthenticated,
      isLoading: profileQuery.isLoading || permissionsQuery.isLoading,
      refreshProfile,
      roleManager,
    }),
    [
      isAuthenticated,
      profileQuery.data,
      profileQuery.isLoading,
      permissions,
      permissionsQuery.isLoading,
      refreshProfile,
      roleManager,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

import React from "react";
import { cn } from "@repo/ui/lib/utils";
import type { ChatMessage } from "@repo/service";

interface MessageBubbleProps {
  message: ChatMessage;
}

/**
 * Message bubble
 * @param message Message
 * @returns Message bubble
 */
export const MessageBubble: React.FC<MessageBubbleProps> = ({ message }) => {
  return (
    <div
      className={cn(
        "relative max-w-[85%] px-4 py-2 shadow-sm break-words whitespace-pre-wrap",
        message.sent_by_me
          ? "rounded-l-xl rounded-tr-xl bg-primary/70 text-primary-foreground"
          : "rounded-r-xl rounded-bl-xl bg-muted",
      )}
    >
      {/* Message */}
      <p className="text-sm whitespace-pre-wrap break-words">
        {message.message}
      </p>
    </div>
  );
};

import {
  GetLoadsParams,
  LoadDetail,
  LoadsResponse,
  LoadStatusResponse,
} from "./types";
import type { ApiClient } from "../../infrastructure/apiClient";

export class LoadService {
  constructor(private readonly apiClient: ApiClient) {}

  /**
   * Get the loads
   * @param params - The parameters for getting loads
   * @returns Promise<LoadsResponse>
   */
  async getLoads(
    params: GetLoadsParams & { language: string },
  ): Promise<LoadsResponse> {
    const { page, limit = 15, filters, language } = params;
    const offset = (page - 1) * limit;

    return this.apiClient.get<LoadsResponse>(`${language}/loads`, {
      params: {
        offset,
        limit,
        ...filters,
        ordering: filters?.ordering ? filters.ordering : "-created_at",
      },
    });
  }

  /**
   * Get a specific load by load number
   * @param load_no - The load number to retrieve
   * @param language - The language code
   * @returns Promise<LoadDetail>
   */
  async getLoad(load_no: string, language: string): Promise<LoadDetail> {
    const ENDPOINT = `${language}/loads/${load_no}`;
    return this.apiClient.get<LoadDetail>(ENDPOINT);
  }

  /**
   * Get all available load statuses
   * @param language - The language code
   * @returns Promise<LoadStatusResponse>
   */
  async getLoadStatuses(language: string): Promise<LoadStatusResponse> {
    const ENDPOINT = `${language}/loads/load-statuses`;
    return this.apiClient.get<LoadStatusResponse>(ENDPOINT);
  }
}

export const createLoadService = (apiClient: ApiClient) =>
  new LoadService(apiClient);

export const getStatusColor = (status: number) => {
  switch (status) {
    case 0:
      return "border border-border bg-info-background text-info hover:bg-info/20"; // NEW
    case 1:
      return "border border-border bg-warning-background text-warning hover:bg-warning/20"; // WAITING
    case 2:
      return "border border-border bg-info-background text-info hover:bg-info/20"; // REPLIED
    case 3:
      return "border border-border bg-success-background text-success hover:bg-success/20"; // RESOLVED
    case 4:
      return "border border-border text-muted-foreground"; // IN_PROGRESS
    case 5:
      return "border border-border text-muted-foreground"; // ON_HOLD
    default:
      return "border border-border text-muted-foreground";
  }
};

export const getPriorityColor = (priority: number) => {
  switch (priority) {
    case 1:
      return "border border-border bg-destructive-background text-destructive hover:bg-destructive/20"; // HIGH
    case 2:
      return "border border-border bg-warning-background text-warning hover:bg-warning/20"; // MEDIUM
    case 3:
      return "border border-border text-muted-foreground"; // LOW
    default:
      return "border border-border text-muted-foreground";
  }
};

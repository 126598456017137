import { QueryClientProvider } from "@tanstack/react-query";
import { createQueryClient } from "@repo/service";
import { useTranslation } from "@repo/config";
import { toastManager } from "@repo/ui/utils/toastManager";
import { QueryProviderProps } from "./types";

export function QueryProvider({ children }: Readonly<QueryProviderProps>) {
  const { t } = useTranslation(["common", "auth"]);

  const queryClient = createQueryClient({
    toastManager,
    i18n: { t },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}

import { useState } from "react";
import { useTranslation } from "@repo/config";
import { Button } from "@repo/ui/components/ui/Button";
import { Badge } from "@repo/ui/components/ui/Badge";
import { cn } from "@repo/ui/lib/utils";
import { HeskService, Ticket } from "@repo/service";
import { useGetTickets } from "../hooks/useGetTickets";
import { useGetTicketDetail } from "../hooks/useGetTicketDetail";
import { useGetTicketReplies } from "../hooks/useGetTicketReplies";
import TicketCard from "./TicketCard";
import TicketDetail from "./TicketDetail";
import MyTicketsSkeleton from "./skeletons/MyTicketsSkeleton";
import TicketDetailSkeleton from "./skeletons/TicketDetailSkeleton";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";

interface MyTicketsProps {
  heskService: HeskService;
}

type FilterValue = "active" | "resolved";

interface FilterButtonProps {
  value: FilterValue;
  activeFilter: FilterValue;
  count: number;
  onClick: () => void;
  label: string;
}

const FilterButton = ({
  value,
  activeFilter,
  count,
  onClick,
  label,
}: FilterButtonProps) => {
  const isActive = activeFilter === value;
  return (
    <Button
      variant="outline"
      size="sm"
      onClick={onClick}
      className={cn(
        "relative bg-card border border-border text-foreground",
        isActive
          ? "border border-primary/20 drop-shadow-md"
          : "ring-transparent",
      )}
    >
      <span>{label}</span>
      {count > 0 && (
        <Badge
          variant="secondary"
          className={cn("ml-2", isActive && "bg-success-background")}
        >
          {count}
        </Badge>
      )}
    </Button>
  );
};

const NoTicketsMessage = ({
  activeFilter,
  t,
}: {
  activeFilter: FilterValue;
  t: (key: string) => string;
}) => (
  <div className="text-center text-muted-foreground py-8">
    {activeFilter === "active"
      ? t("tickets.empty_active")
      : t("tickets.empty_resolved")}
  </div>
);

const TicketList = ({
  tickets,
  onTicketClick,
}: {
  tickets: Ticket[];
  onTicketClick: (ticketNo: string) => void;
}) => (
  <div className="space-y-3">
    {tickets.map((ticket) => (
      <TicketCard
        key={ticket.id}
        ticket={ticket}
        onClick={() => onTicketClick(ticket.ticket_no)}
      />
    ))}
  </div>
);

export default function MyTickets({ heskService }: Readonly<MyTicketsProps>) {
  const { t } = useTranslation("hesk");
  const [selectedTicketNo, setSelectedTicketNo] = useState<string | null>(null);
  const [activeFilter, setActiveFilter] = useState<FilterValue>("active");

  const {
    data: ticketsResponse,
    error,
    isLoading,
  } = useGetTickets(heskService);

  const { data: selectedTicket, isLoading: isLoadingDetail } =
    useGetTicketDetail(heskService, selectedTicketNo);

  const {
    data: replies = [],
    isLoading: isLoadingReplies,
    refetch: refetchReplies,
  } = useGetTicketReplies(heskService, selectedTicketNo);

  const activeTickets = ticketsResponse?.results.filter(
    (ticket) => !ticket.is_resolved,
  );
  const resolvedTickets = ticketsResponse?.results.filter(
    (ticket) => ticket.is_resolved,
  );
  const currentTickets =
    activeFilter === "active" ? activeTickets : resolvedTickets;

  if (isLoading) return <MyTicketsSkeleton />;
  if (error) {
    return (
      <div className="text-center text-destructive py-8">
        {t("tickets.error.fetch_title")}
      </div>
    );
  }
  if (ticketsResponse?.count === 0) {
    return (
      <div className="text-center text-muted-foreground py-8">
        {t("tickets.empty")}
      </div>
    );
  }

  if (selectedTicketNo) {
    if (isLoadingDetail || isLoadingReplies) return <TicketDetailSkeleton />;
    if (selectedTicket) {
      return (
        <TicketDetail
          ticket={selectedTicket}
          replies={replies}
          isLoadingReplies={isLoadingReplies}
          onBack={() => setSelectedTicketNo(null)}
          heskService={heskService}
          refetchReplies={refetchReplies}
        />
      );
    }
  }

  return (
    <div className="py-4 px-2 space-y-4">
      <div className="flex items-center gap-2">
        <FilterButton
          value="active"
          activeFilter={activeFilter}
          count={activeTickets?.length ?? 0}
          onClick={() => setActiveFilter("active")}
          label={t("tickets.active_tickets")}
        />
        <FilterButton
          value="resolved"
          activeFilter={activeFilter}
          count={resolvedTickets?.length ?? 0}
          onClick={() => setActiveFilter("resolved")}
          label={t("tickets.resolved_tickets")}
        />
      </div>

      {currentTickets && currentTickets.length > 0 ? (
        <ScrollArea className="h-[calc(100vh-21rem)]">
          <TicketList
            tickets={currentTickets}
            onTicketClick={setSelectedTicketNo}
          />
        </ScrollArea>
      ) : (
        <NoTicketsMessage activeFilter={activeFilter} t={t} />
      )}
    </div>
  );
}

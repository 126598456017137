import { toastManager } from "@repo/ui/utils/toastManager";
import { i18next } from "@repo/config";

export const handleTicketNoCopy = async (ticketNo: string) => {
  try {
    await navigator.clipboard.writeText(ticketNo);
    toastManager.success(
      i18next.t("copy.success_title"),
      i18next.t("copy.success_description", { value: ticketNo }),
    );
  } catch (error) {
    toastManager.error(
      i18next.t("copy.error_title"),
      i18next.t("copy.error_description", { value: ticketNo }),
    );
  }
};

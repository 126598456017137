import { i18next } from "@repo/config/";
import { LoadDetail } from "@repo/service";

/**
 * Format contact information
 * @param contact - Contact object
 * @returns Formatted contact information
 */
export const formatContactInfo = (
  contact: LoadDetail["sales_representative"],
) => {
  if (!contact)
    return {
      fullName: i18next.t("loads:ots.map.popup.noName"),
      phone: i18next.t("loads:ots.map.popup.noPhone"),
      email: i18next.t("loads:ots.map.popup.noEmail"),
    };

  return {
    fullName: formatName(contact.full_name),
    phone: formatPhoneNumber(contact.gsm_number),
    email: contact.email || i18next.t("loads:ots.map.popup.noEmail"),
  };
};

/**
 * Format a phone number
 * @param phone - Phone object
 * @returns Formatted phone number string
 */
export const formatPhoneNumber = (
  phone: { full_number?: string } | null | undefined,
) => {
  if (!phone?.full_number) return i18next.t("loads:ots.map.popup.noPhone");

  // clean the phone number from +90 and spaces
  const cleanNumber = phone.full_number.replace("+90", "").replace(/\s/g, "");

  // format the phone number to 5XX XXX XX XX
  return cleanNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
};

/**
 * Format a name
 * @param name - Name string
 * @returns Formatted name with first letter capital
 */
export const formatName = (name: string | null | undefined) => {
  if (!name) return i18next.t("loads:ots.map.popup.noName");

  return name
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/**
 * Creates a unique key for skeleton items
 * @param index - The index of the skeleton item
 * @returns A unique string key
 */
export const createSkeletonKey = (index: number) => `skeleton-${index}`;

import { LoadDetail } from "@repo/service";
import { TFunction } from "@repo/config";

interface StatusMessage {
  text: string;
  className?: string;
}

/**
 * @function getLoadStatusMessage
 * @description Generates a status message for the selected load.
 * @param {LoadDetail | null} selectedLoad - The selected load or null.
 * @param {TFunction} t - Translation function
 * @returns {StatusMessage} The status message object.
 */
export const getLoadStatusMessage = (
  selectedLoad: LoadDetail | null,
  t: TFunction,
): StatusMessage => {
  if (!selectedLoad) {
    return {
      text: t("ots.status.selectLoad"),
      className:
        "text-muted-foreground shadow-card-shadow p-2 bg-muted/80 rounded-md",
    };
  }

  if (selectedLoad.status.status_id === 15) {
    return {
      text: t("ots.status.completed"),
      className:
        "text-success shadow-card-shadow p-2 bg-success-background/80 rounded-md",
    };
  }

  if (!selectedLoad.last_location) {
    return {
      text: t("ots.status.noLocation"),
      className:
        "text-destructive-text shadow-card-shadow p-2 bg-destructive-background/80 rounded-md",
    };
  }

  return { text: "" };
};

import { Routes, Route } from "react-router-dom";
import SettingsLayout from "./layout/SettingsLayout";
import UserSettings from "./components/settings/UserSettings";
import { translations } from "./i18n/translations";
import { AuthService } from "@repo/service";
import { SettingsContext } from "./contexts/SettingsContext";
import ChangePassword from "./components/password/ChangePassword";
import AddressSettings from "./components/address/AddressSettings";
import { memo, useMemo } from "react";

interface SettingsProps {
  authService: AuthService;
}

export function Settings({ authService }: SettingsProps) {
  return memo(function SettingsComponent() {
    const contextValue = useMemo(() => ({ authService }), [authService]);

    return (
      <SettingsContext.Provider value={contextValue}>
        <Routes>
          <Route element={<SettingsLayout />}>
            <Route index element={<UserSettings />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="addresses" element={<AddressSettings />} />
          </Route>
        </Routes>
      </SettingsContext.Provider>
    );
  });
}

/**
 * i18n resources for settings
 */
export const i18n = {
  namespace: "settings",
  resources: translations,
};

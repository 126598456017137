import { useQuery } from "@tanstack/react-query";
import { useLoadContext } from "../contexts/LoadContext";
import { useCurrentLanguage } from "@repo/config";

export const useLoadStatuses = () => {
  const currentLanguage = useCurrentLanguage();
  const { loadService } = useLoadContext();

  return useQuery({
    queryKey: ["loadStatuses", currentLanguage],
    queryFn: () => loadService.getLoadStatuses(currentLanguage),
    enabled: !!loadService,
  });
};

import { Button } from "@repo/ui/components/ui/Button";
import { Card } from "@repo/ui/components/ui/Card";
import { Skeleton } from "@repo/ui/components/ui/Skeleton";
import { ArrowLeft } from "lucide-react";
import { cn } from "@repo/ui/lib/utils";
import { createSkeletonKey } from "../../utils/generateSkeletonKey";

export default function TicketDetailSkeleton() {
  return (
    <div className="flex flex-col max-h-[80vh] overflow-hidden">
      {/* Main Header - Fixed */}
      <div
        className={cn(
          "flex-none p-3 rounded-t-lg",
          "bg-card",
          "border border-border",
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Button variant="ghost" size="icon" disabled className="size-8">
              <ArrowLeft className="size-4" />
            </Button>
            <div>
              <Skeleton className="h-5 w-32" />
              <Skeleton className="h-4 w-24 mt-1" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Skeleton className="h-6 w-20" />
            <Skeleton className="h-6 w-20" />
          </div>
        </div>
      </div>

      {/* Ticket Content - Fixed */}
      <div className="flex-none p-3 bg-card border-x border-border">
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <Skeleton className="h-6 w-64" />
            <div className="flex items-center gap-3">
              <Skeleton className="h-4 w-32" />
              <Skeleton className="h-4 w-40" />
            </div>
          </div>
          <div className="space-y-2">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-3/4" />
          </div>
        </div>
      </div>

      {/* Replies Header - Fixed */}
      <div
        className={cn("flex-none p-3 bg-card border-x border-t border-border")}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Skeleton className="h-5 w-4" />
            <Skeleton className="h-5 w-32" />
            <Skeleton className="h-5 w-8" />
          </div>
        </div>
      </div>

      {/* Replies Content - Scrollable */}
      <div className="flex-1 border-x border-border">
        <div className="space-y-4 p-4 h-[300px] overflow-hidden">
          {Array.from({ length: 2 }).map((_, i) => (
            <Card key={createSkeletonKey(i)} className="bg-muted/50">
              <div className="p-4 space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Skeleton className="h-8 w-8 rounded-full" />
                    <div>
                      <Skeleton className="h-4 w-32" />
                      <Skeleton className="h-3 w-24 mt-1" />
                    </div>
                  </div>
                  <Skeleton className="h-4 w-24" />
                </div>
                <div className="space-y-2">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-2/3" />
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>

      {/* Reply Form */}
      <div
        className={cn(
          "flex-none p-3 rounded-b-lg",
          "bg-card",
          "border border-border",
        )}
      >
        <div className="space-y-3">
          <Skeleton className="h-[80px] w-full" />
          <div className="flex items-center justify-between gap-3">
            <Skeleton className="h-8 w-32" />
            <Skeleton className="h-8 w-24" />
          </div>
        </div>
      </div>
    </div>
  );
}

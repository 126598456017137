import { Button } from "@repo/ui/components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@repo/ui/components/ui/Dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@repo/ui/components/ui/Tabs";
import { useTranslation } from "@repo/config";
import { memo, useState, useCallback } from "react";
import { LifeBuoy } from "lucide-react";
import { FeatureGuide } from "@repo/ui/components/feature-guide/FeatureGuide";
import HeskForm from "./HeskForm";
import MyTickets from "./MyTickets";
import type { HeskService } from "@repo/service";
import { HELP_IMAGES } from "../constants/images";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";

interface HeskDialogProps {
  readonly heskService: HeskService;
}

export const HeskDialog = memo(function HeskDialog({
  heskService,
}: HeskDialogProps) {
  const { t } = useTranslation("hesk");
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("create-ticket");

  const onSuccess = useCallback(() => {
    setActiveTab("my-tickets");
  }, []);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          size="sm"
          variant="outline"
          className="flex items-center gap-2 divide-x text-sm"
        >
          <LifeBuoy className="size-4" />
          {t("button")}
        </Button>
      </DialogTrigger>
      <DialogContent className="h-[90dvh] max-h-[60rem] overflow-hidden">
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <DialogHeader className="pb-2">
            <div className="flex items-center gap-2">
              <DialogTitle>{t("title")}</DialogTitle>
              <FeatureGuide
                images={HELP_IMAGES}
                title={t("help.title")}
                description={t("help.description")}
              />
            </div>
            <DialogDescription>{t("description")}</DialogDescription>
          </DialogHeader>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="create-ticket">{t("createTicket")}</TabsTrigger>
            <TabsTrigger value="my-tickets">{t("myTickets")}</TabsTrigger>
          </TabsList>

          <TabsContent value="create-ticket">
            <ScrollArea className="h-[calc(100dvh-16rem)]">
              <HeskForm onSuccess={onSuccess} heskService={heskService} />
            </ScrollArea>
          </TabsContent>
          <TabsContent value="my-tickets">
            <ScrollArea className="h-[calc(100dvh-16rem)]">
              <MyTickets heskService={heskService} />
            </ScrollArea>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
});

import { useQuery } from "@tanstack/react-query";
import { HeskService } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

export const useGetTicketDetail = (
  heskService: HeskService,
  ticketNo: string | null,
) => {
  const language = useCurrentLanguage();

  return useQuery({
    queryKey: ["ticketDetail", ticketNo],
    queryFn: () => {
      if (!ticketNo) throw new Error("Ticket number is required");
      return heskService.getTicketDetail(language, ticketNo);
    },
    enabled: !!ticketNo,
    gcTime: 1000 * 60 * 30, // keep in cache for 30 minutes
  });
};

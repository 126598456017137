import { useContext } from "react";
import { ChatContext } from "./ChatContext";

/**
 * Use chat context
 * @returns Chat context
 */
export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatContext must be used within a ChatContextProvider");
  }
  return context;
};

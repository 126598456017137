import type { ApiErrorCode } from "../types";
import { type ToastManager } from "@repo/ui/utils/toastManager";
import { ApiError } from "../infrastructure/apiError";

/**
 * Options for initializing the ErrorHandler
 */
export interface ErrorHandlerOptions {
  toastManager: ToastManager;
  i18n: {
    t: (key: string, options?: any) => string;
  };
}

/**
 * Handles API errors and displays them using a toast notification system
 * Implements the Singleton pattern to ensure only one instance exists
 */
export class ApiErrorHandler {
  private static instance: ApiErrorHandler;
  private readonly toastManager: ErrorHandlerOptions["toastManager"];
  private readonly t: ErrorHandlerOptions["i18n"]["t"];

  private constructor(options: ErrorHandlerOptions) {
    this.toastManager = options.toastManager;
    this.t = options.i18n.t;
  }

  /**
   * Initializes or returns the existing singleton instance
   * @param options Configuration options for the error handler
   */
  public static init(options: ErrorHandlerOptions): ApiErrorHandler {
    if (!ApiErrorHandler.instance) {
      ApiErrorHandler.instance = new ApiErrorHandler(options);
    }
    return ApiErrorHandler.instance;
  }

  /**
   * Handles an API error by displaying it as a toast notification
   * @param error The API error to handle
   */
  public handleError(error: ApiError): void {
    const config = {
      title: this.getErrorTitle(error),
      description: this.getErrorDescription(error),
      duration: this.getDurationByType(error.code),
      severity: error.severity,
    };

    this.toastManager.showWithSeverity(
      config.severity,
      config.title,
      config.description,
      { duration: config.duration },
    );
  }

  /**
   * Gets the translated error title
   * Uses custom translation key if provided, otherwise uses default error code based key
   */
  private getErrorTitle(error: ApiError): string {
    const key = error.translationKey
      ? `${error.translationKey}.title`
      : `error.${error.code}.title`;
    return this.t(key);
  }

  /**
   * Gets the translated error description with error details
   * Uses custom translation key if provided, otherwise uses default error code based key
   */
  private getErrorDescription(error: ApiError): string {
    const key = error.translationKey
      ? `${error.translationKey}.message`
      : `error.${error.code}.message`;
    return this.t(key, { message: error.getErrorDetails() });
  }

  /**
   * Gets the toast duration based on error type
   * Returns default duration (5000ms) if type is not mapped
   */
  private getDurationByType(type: ApiErrorCode): number {
    const durationMap: Record<ApiErrorCode, number> = {
      validation: 5000,
      auth: 4000,
      network: 5000,
      server: 5000,
      not_found: 4000,
      forbidden: 4000,
      unknown: 5000,
      timeout: 5000,
    };

    return durationMap[type] || 5000;
  }
}

/**
 * Helper function to create or get the singleton instance of ApiErrorHandler
 */
export const createErrorHandler = (
  options: ErrorHandlerOptions,
): ApiErrorHandler => ApiErrorHandler.init(options);

import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { LoadSummary } from "@repo/service";
import { cn } from "@repo/ui/lib/utils";
import { LoadOtsListCardHeader } from "./LoadOtsListCardHeader";
import { LoadOtsListCardStatusIcon } from "./LoadOtsListCardStatusIcon";

interface LoadCardProps {
  load: LoadSummary;
  onClick: (loadNo: string) => void;
  isActive?: boolean;
}

/**
 * Card component for displaying individual load information in the list view.
 * Shows load number, status, completion state, and trailer plate.
 */
export default function LoadOtsListCard({
  load,
  onClick,
  isActive = false,
}: Readonly<LoadCardProps>) {
  const cardContainerClasses = cn(
    "w-full rounded-md backdrop-blur transition-colors",
    "dark:hover:bg-primary/30 hover:bg-primary/10",
    isActive
      ? "bg-primary/10 hover:bg-primary/10 dark:hover:bg-primary/20 dark:bg-primary/20 ring-2 ring-primary/30"
      : "bg-card/30",
  );

  const cardClasses = cn(
    "bg-primary/5 dark:bg-primary/10 border-0",
    isActive && "shadow-sm",
  );

  return (
    <button
      onClick={() => onClick(load.load_no)}
      className={cardContainerClasses}
    >
      <Card className={cardClasses}>
        <CardContent className="gap-2 px-2 py-1 flex items-center justify-center">
          <LoadOtsListCardStatusIcon isCompleted={load.is_load_completed} />
          <div className="flex flex-col items-start w-full">
            <LoadOtsListCardHeader
              loadNo={load.load_no}
              trailerPlate={load.trailer_plate}
              isActive={isActive}
            />
            <p className="text-[10px] font-semibold text-muted-foreground">
              {load.status.status_name}
            </p>
          </div>
        </CardContent>
      </Card>
    </button>
  );
}

import LoadProvider from "./contexts/LoadContext";
import { Suspense, memo, useMemo } from "react";
import LoadOtsListSkeleton from "./components/skeletons/LoadOtsListSkeleton";
import LoadOtsMapSkeleton from "./components/skeletons/LoadOtsMapSkeleton";
import LoadOtsDetailSkeleton from "./components/skeletons/LoadOtsDetailSkeleton";
import LoadOtsList from "./components/LoadOtsList";
import LoadOtsMap from "./components/LoadOtsMap";
import LoadOtsDetail from "./components/LoadOtsDetail";
import { LoadService } from "@repo/service";
import { translations } from "./i18n/translations";

interface OTSProps {
  loadService: LoadService;
}

/**
 * OTS component
 * @param loadService - Load service
 * @returns
 */
export function OTS({ loadService }: OTSProps) {
  return memo(function OTSComponent() {
    const contextValue = useMemo(() => ({ loadService }), [loadService]);

    return (
      <LoadProvider loadService={contextValue.loadService}>
        <section className="h-[calc(100svh-80px)] md:h-[calc(100svh-100px)] shadow-card-shadow flex rounded-lg items-center relative overflow-hidden">
          <section className="absolute top-2 left-2 h-full w-full max-w-xs z-10">
            <Suspense fallback={<LoadOtsListSkeleton />}>
              <LoadOtsList />
            </Suspense>
          </section>
          <section className="w-full h-full z-0">
            <Suspense fallback={<LoadOtsMapSkeleton />}>
              <LoadOtsMap height="100%" />
            </Suspense>
          </section>
          <section className="absolute top-2 right-2 w-full max-w-xs z-10 max-h-[calc(100svh-100px)]">
            <Suspense fallback={<LoadOtsDetailSkeleton />}>
              <LoadOtsDetail />
            </Suspense>
          </section>
        </section>
      </LoadProvider>
    );
  });
}

/**
 * i18n resources for loads
 */
export const i18n = {
  namespace: "loads",
  resources: translations,
};

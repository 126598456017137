import { z } from "zod";
import { useTranslation } from "@repo/config";
import { isValidPhoneNumber } from "react-phone-number-input";

export const useProfileSchema = () => {
  const { t } = useTranslation("settings");

  return z.object({
    first_name: z
      .string()
      .min(1, t("profile.validation.first_name.required"))
      .min(2, t("profile.validation.first_name.min", { min: 2 }))
      .max(50, t("profile.validation.first_name.max", { max: 50 })),
    last_name: z
      .string()
      .min(1, t("profile.validation.last_name.required"))
      .min(2, t("profile.validation.last_name.min", { min: 2 }))
      .max(50, t("profile.validation.last_name.max", { max: 50 })),
    email: z
      .string()
      .min(1, t("profile.validation.email.required"))
      .email(t("profile.validation.email.invalid")),
    phone: z
      .string({
        required_error: t("profile.validation.phone.required"),
      })
      .refine((phone) => isValidPhoneNumber(phone), {
        message: t("profile.validation.phone.invalid"),
      }),
    company_name: z
      .string()
      .min(1, t("profile.validation.company_name.required"))
      .min(2, t("profile.validation.company_name.min", { min: 2 }))
      .max(100, t("profile.validation.company_name.max", { max: 100 })),
  });
};

import { useLoadContext } from "../contexts/LoadContext";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
  useMap,
} from "react-leaflet";
import { Card } from "@repo/ui/components/ui/Card";
import { Truck, MapPin, Clock } from "lucide-react";
import L from "leaflet";
import { useRef, useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import { cn } from "@repo/ui/lib/utils";
import { LoadDetail } from "@repo/service";
import { getLoadStatusMessage } from "../utils/getLoadStatusMessage";
import { LoadingProgress } from "@repo/ui/components/ui/LoadingProgress";
import TruckIcon from "../assets/icons/truck.svg";
import { formatDateTime, useTranslation } from "@repo/config";

/**
 * @module LoadOtsMap
 * @description Component for displaying loads on a map.
 * Uses OpenStreetMap to show load locations with markers.
 *
 * @component
 * @param {Object} props
 * @param {string} props.height - Map height (e.g., '500px')
 *
 * @example
 * return (
 *   <LoadOtsMap height="500px" />
 * )
 */

interface LoadMapProps {
  readonly height?: string | number;
}

export default function LoadOtsMap({ height = "500px" }: LoadMapProps) {
  const { selectedLoad, isLoading } = useLoadContext();
  const { t } = useTranslation("loads");

  // Initial zoom level
  const defaultZoom = 6; // Enough zoom the see Turkiye
  const selectedZoom = selectedLoad?.last_location ? 9 : defaultZoom; // Selected Zoom level

  const tileLayerOptions = {
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: "© OpenStreetMap contributors",
    maxZoom: 18,
  };

  /**
   * Default center of the map (Turkiye)
   */
  const defaultCenter: [number, number] = [39.6478625, 27.8118136];
  const center: [number, number] = selectedLoad?.last_location
    ? [
        selectedLoad.last_location.latitude,
        selectedLoad.last_location.longitude,
      ]
    : defaultCenter;

  const showOverlay =
    !selectedLoad ||
    selectedLoad.status.status_id === 15 ||
    !selectedLoad.last_location ||
    isLoading;
  const statusMessage = getLoadStatusMessage(selectedLoad, t);

  return (
    <div className="relative w-full" style={{ height }}>
      <MapContainer
        center={center}
        zoom={selectedLoad?.last_location ? selectedZoom : defaultZoom}
        scrollWheelZoom={true}
        zoomControl={false}
        className="w-full h-full"
      >
        <TileLayer {...tileLayerOptions} />

        {selectedLoad?.last_location && (
          <LoadMarker load={selectedLoad} isSelected={true} />
        )}

        <MapController />
        <ZoomControl position="bottomright" />
      </MapContainer>

      {showOverlay && (
        <Card
          className={cn(
            "absolute inset-0 flex items-center justify-center z-[1000]",
            "bg-card/30 backdrop-blur-sm",
            "animate-in fade-in duration-300",
          )}
        >
          {isLoading ? (
            <LoadingProgress
              title={t("ots.map.overlay.loading")}
              className="bg-transparent"
              showSpinner={false}
            />
          ) : (
            <p className={cn(statusMessage.className)}>{statusMessage.text}</p>
          )}
        </Card>
      )}
    </div>
  );
}

// Map kontrolü için ayrı bir komponent
function MapController() {
  const map = useMap();
  const { selectedLoad } = useLoadContext();

  useEffect(() => {
    if (selectedLoad?.last_location) {
      const { latitude, longitude } = selectedLoad.last_location;
      map.flyTo([latitude, longitude], 9, {
        duration: 1.5,
        easeLinearity: 0.25,
      });
    }
  }, [map, selectedLoad]);

  return null;
}

/**
 * @component LoadMarker
 * @description Marker component for displaying load location on map
 *
 * @param {Object} props
 * @param {LoadDetail} props.load - Load detail information
 *
 * @example
 * return (
 *   <LoadMarker load={loadDetail} />
 * )
 */

interface LoadMarkerProps {
  load: LoadDetail;
  isSelected: boolean;
}

// Marker komponenti ayrı bir bileşen olarak
const LoadMarker = ({ load, isSelected }: LoadMarkerProps) => {
  const map = useMap();
  const { t } = useTranslation("loads");
  const [popupReady, setPopupReady] = useState(false);
  const popupRef = useRef<L.Popup | null>(null);

  useEffect(() => {
    if (popupReady && isSelected && popupRef.current) {
      popupRef.current.openOn(map);
    }
  }, [popupReady, isSelected, map]);

  const truckIcon = L.icon({
    iconUrl: TruckIcon,
    iconSize: [48, 48],
  });

  return (
    <Marker
      position={[load.last_location.latitude, load.last_location.longitude]}
      icon={truckIcon}
    >
      <Popup
        ref={(r) => {
          if (r) {
            popupRef.current = r;
            setPopupReady(true);
          }
        }}
        className="custom-popup"
      >
        <div className="min-w-[300px] -mx-[12px] -my-[5px]">
          {/* Header Section */}
          <div className="p-3 border-separate border-b">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <div
                  className={cn(
                    "rounded-full p-2",
                    load.is_load_completed
                      ? "bg-success/20 text-success"
                      : "bg-warning/20 text-warning",
                  )}
                >
                  <Truck className="h-5 w-5" />
                </div>
                <div>
                  <h3 className="font-semibold text-foreground">
                    #{load.load_no}
                  </h3>
                  <span
                    className={cn(
                      "text-xs px-2 py-1 rounded-full",
                      load.is_load_completed
                        ? "bg-success/10 text-success"
                        : "bg-warning/10 text-warning",
                    )}
                  >
                    {load.status.status_name}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Detail Section */}
          <div className="p-3 space-y-2">
            <InfoRow
              icon={<Truck className="h-4 w-4" />}
              label={t("ots.map.popup.plate")}
              value={load.truck_plate || t("ots.map.popup.noPlate")}
            />
            <InfoRow
              icon={<MapPin className="h-4 w-4" />}
              label={t("ots.map.popup.location")}
              value={load.last_location.full_address}
            />
            <InfoRow
              icon={<Clock className="h-4 w-4" />}
              label={t("ots.map.popup.lastUpdate")}
              value={formatDateTime(load.last_location.gps_time)}
            />
          </div>
        </div>
      </Popup>
    </Marker>
  );
};

// Helper component
const InfoRow = ({
  icon,
  label,
  value,
}: {
  icon: React.ReactNode;
  label: string;
  value: string;
}) => (
  <div className="flex items-start gap-2">
    <div className="text-muted-foreground mt-1">{icon}</div>
    <div>
      <div className="text-xs text-muted-foreground">{label}</div>
      <div className="text-sm text-foreground">{value}</div>
    </div>
  </div>
);

import { Badge } from "@repo/ui/components/ui/Badge";
import { useLoads } from "../hooks/useLoads";
import { useTranslation } from "@repo/config";
import { LoadFilterOptions } from "@repo/service";

interface LoadOtsCountBadgeProps {
  filters: Readonly<LoadFilterOptions>;
}

/**
 * @component LoadOtsCountBadge
 * @description Displays the total count of loads based on current filters
 */
export default function LoadOtsCountBadge({
  filters,
}: Readonly<LoadOtsCountBadgeProps>) {
  const { t } = useTranslation("loads");
  const { data: loadsData, isLoading } = useLoads(filters);

  if (isLoading || !loadsData?.pages[0]) return null;

  const totalCount = loadsData.pages[0].count;

  return (
    <Badge variant="outline" className="w-fit shadow">
      {t("ots.count", {
        count: totalCount,
        defaultValue: "{{count}} yük",
      })}
    </Badge>
  );
}

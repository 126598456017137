import { useInfiniteQuery } from "@tanstack/react-query";
import { LoadsFilters, LoadsResponse } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";
import { useLoadContext } from "../contexts/LoadContext";

const QUERY_KEY = "loads";
const PAGE_SIZE = 15;

/**
 * Get the loads with infinite scroll
 * @param filters - Filters
 * @returns Loads with pagination
 */
export const useLoads = (filters: LoadsFilters = {}) => {
  const { loadService } = useLoadContext();
  const currentLanguage = useCurrentLanguage();

  return useInfiniteQuery<LoadsResponse, Error>({
    queryKey: [QUERY_KEY, filters, currentLanguage],
    queryFn: async ({ pageParam }) => {
      const page = typeof pageParam === "number" ? pageParam : 1;
      return loadService.getLoads({
        page,
        limit: PAGE_SIZE,
        filters,
        language: currentLanguage,
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.next) return undefined;
      const totalPages = Math.ceil(lastPage.count / PAGE_SIZE);
      const nextPage = allPages.length + 1;
      return nextPage <= totalPages ? nextPage : undefined;
    },

    enabled: !!loadService,
  });
};

import { useMemo, useState, useEffect, Suspense } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/Card";
import { BOOKS, Book } from "@/features/library/books";
import { useTranslation, useDebounce } from "@repo/config";
import { BookList } from "@/features/library/components/BookList";
import { BookFilter } from "@/features/library/components/BookFilter";
import { parseAsString, useQueryState } from "nuqs";

const DEBOUNCE_DELAY = 500;

// Query parameter isimlendirmeleri
const QUERY_PARAMS = {
  SEARCH: "library_search",
  SELECTED_BOOK: "library_book",
} as const;

export default function LibraryPage() {
  return (
    <Suspense fallback={<></>}>
      <LibraryContent />
    </Suspense>
  );
}

function LibraryContent() {
  const { t } = useTranslation("library");
  const [searchQuery, setSearchQuery] = useQueryState(
    QUERY_PARAMS.SEARCH,
    parseAsString.withDefault(""),
  );
  const [selectedBookId, setSelectedBookId] = useQueryState(
    QUERY_PARAMS.SELECTED_BOOK,
    parseAsString.withDefault(""),
  );
  const [localSearch, setLocalSearch] = useState(searchQuery ?? "");
  const debouncedSearch = useDebounce(localSearch, DEBOUNCE_DELAY);

  useEffect(() => {
    setSearchQuery(debouncedSearch || null);
  }, [debouncedSearch]);

  useEffect(() => {
    if (!searchQuery) {
      setLocalSearch("");
    }
  }, [searchQuery]);

  const filteredBooks = useMemo(() => {
    const query = debouncedSearch.toLowerCase();
    return BOOKS.filter(
      (book) =>
        book.title.toLowerCase().includes(query) ||
        book.author?.toLowerCase().includes(query),
    );
  }, [debouncedSearch]);

  const selectedBook = useMemo(() => {
    return BOOKS.find((book) => book.id === selectedBookId);
  }, [selectedBookId]);

  const handleBookClick = (book: Book) => {
    setSelectedBookId(book.id);
  };

  const handleCloseBook = () => {
    setSelectedBookId(null);
  };

  return (
    <Card className="h-[calc(100svh-80px)] md:h-[calc(100svh-100px)] overflow-hidden">
      <CardHeader>
        <CardTitle>{t("title")}</CardTitle>
      </CardHeader>
      <CardContent className="pb-0">
        <BookFilter searchQuery={localSearch} onSearchChange={setLocalSearch} />
        <BookList
          books={filteredBooks}
          onBookClick={handleBookClick}
          selectedBook={selectedBook}
          onCloseBook={handleCloseBook}
        />
      </CardContent>
    </Card>
  );
}

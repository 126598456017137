import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useChatContext } from "../../contexts/useChatContext";
import { Button } from "@repo/ui/components/ui/Button";
import { Input } from "@repo/ui/components/ui/Input";
import { Send } from "lucide-react";
import { useTranslation } from "@repo/config";

const TYPING_THROTTLE = 500; // 0.5 seconds
const TYPING_TIMEOUT = 5000; // 5 seconds

export interface ChatInputRef {
  focus: () => void;
}

/**
 * Chat input
 * @returns Chat input
 */
export const ChatInput = forwardRef<ChatInputRef>((_, ref) => {
  const { sendMessage, sendTyping, activeRoomStatus } = useChatContext();
  const [newMessage, setNewMessage] = useState("");
  const { t } = useTranslation("chat");
  const inputRef = useRef<HTMLInputElement>(null);

  const lastTypingTime = useRef<number>(0);
  const typingTimeoutRef = useRef<NodeJS.Timeout>();

  // Export focus method to parent component
  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
  }));

  /**
   * Handle typing with throttle
   */
  const handleTyping = useCallback(() => {
    const now = Date.now();

    // Throttle control
    if (now - lastTypingTime.current < TYPING_THROTTLE) {
      return;
    }

    // Send typing action
    sendTyping();
    lastTypingTime.current = now;

    // Clear current timeout
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Set new timeout
    typingTimeoutRef.current = setTimeout(() => {
      typingTimeoutRef.current = undefined;
    }, TYPING_TIMEOUT);
  }, [sendTyping]);

  /**
   * Handle input change
   */
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setNewMessage(value);

      if (activeRoomStatus && value.trim()) {
        handleTyping();
      }
    },
    [activeRoomStatus, handleTyping],
  );

  /**
   * Handle send message
   */
  const handleSend = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (newMessage.trim()) {
        sendMessage(newMessage);
        setNewMessage("");
      }
    },
    [newMessage, sendMessage],
  );

  /**
   * Cleanup typing timeout
   */
  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="p-4 border-t bg-muted/5">
      {/* Chat input form */}
      <form onSubmit={handleSend} className="flex gap-2">
        {/* Chat input */}
        <Input
          ref={inputRef}
          autoFocus
          tabIndex={0}
          disabled={!activeRoomStatus}
          value={newMessage}
          onChange={handleInputChange}
          placeholder={
            activeRoomStatus
              ? t("chat.input.placeholder")
              : t("chat.input.placeholderInactive")
          }
          className="flex-1"
        />
        {/* Send button */}
        <Button
          variant="outline"
          type="submit"
          size="icon"
          disabled={!activeRoomStatus}
        >
          <Send className="size-2.5" />
        </Button>
      </form>
    </div>
  );
});

import { BadgeVariant } from "@repo/ui/components/ui/Badge";

export const getBadgeVariant = (slug: string): BadgeVariant => {
  switch (slug) {
    case "price-not-accepted":
    case "out-of-services":
      return "destructive";
    case "request-completed":
      return "success";
    case "offer-pending":
      return "outline";
    default:
      return "warning";
  }
};

export const getBadgeColor = (slug: string): string => {
  switch (slug) {
    case "price-not-accepted":
    case "out-of-services":
      return "bg-destructive";
    case "request-completed":
      return "bg-success";
    case "offer-pending":
      return "bg-primary/40";
    default:
      return "bg-warning";
  }
};

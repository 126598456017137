import { ReactNode } from "react";
import { cn } from "../../lib/utils";
import { SidebarTrigger } from "../../components/ui/Sidebar";

interface NavbarProps {
  readonly className?: string;
  readonly showSidebarTrigger?: boolean;
  readonly actions?: ReactNode;
}

export function DashboardNavbar({
  className,
  showSidebarTrigger = true,
  actions,
}: NavbarProps) {
  return (
    <header
      className={cn(
        "flex sticky rounded-bl-lg shadow-navbar-shadow dark:drop-shadow top-0 z-50 bg-navbar flex-col",
        className,
      )}
    >
      <div className="flex items-center p-2 md:p-4">
        {showSidebarTrigger && <SidebarTrigger />}
        {actions && <div className="ml-auto">{actions}</div>}
      </div>
    </header>
  );
}

import { useMutation } from "@tanstack/react-query";
import {
  HeskService,
  HeskFormValues,
  SubmitTicketResponse,
} from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

export const useSubmitTicket = (heskService: HeskService) => {
  const language = useCurrentLanguage();

  return useMutation({
    mutationFn: async (
      formData: HeskFormValues,
    ): Promise<SubmitTicketResponse> => {
      const form = new FormData();

      // Add form fields
      Object.entries(formData).forEach(([key, value]) => {
        if (key === "attachments" && value instanceof File) {
          form.append("attachments", value);
        } else if (key !== "attachments" && typeof value === "string") {
          form.append(key, value);
        }
      });

      return heskService.submitTicket(form, language);
    },
  });
};

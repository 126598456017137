const BASE_URL = "https://cdn.abclojistik.com/feature-guides/hesk";

export const HELP_IMAGES = [
  {
    id: 0,
    src: `${BASE_URL}/1.png`,
    alt: "Destek talebi oluşturma adım 1",
  },
  {
    id: 1,
    src: `${BASE_URL}/2.png`,
    alt: "Destek talebi oluşturma adım 2",
  },
  {
    id: 2,
    src: `${BASE_URL}/3.png`,
    alt: "Destek talebi oluşturma adım 3",
  },
  {
    id: 3,
    src: `${BASE_URL}/3.1.png`,
    alt: "Destek talebi oluşturma adım 3.1",
  },
  {
    id: 4,
    src: `${BASE_URL}/3.2.png`,
    alt: "Destek talebi oluşturma adım 4",
  },
  {
    id: 5,
    src: `${BASE_URL}/4.png`,
    alt: "Destek talebi oluşturma adım 5",
  },
  {
    id: 6,
    src: `${BASE_URL}/5.png`,
    alt: "Destek talebi oluşturma adım 6",
  },
  {
    id: 7,
    src: `${BASE_URL}/6.png`,
    alt: "Destek talebi oluşturma adım 7",
  },
  {
    id: 8,
    src: `${BASE_URL}/7.png`,
    alt: "Destek talebi oluşturma adım 8",
  },
  {
    id: 9,
    src: `${BASE_URL}/8.png`,
    alt: "Destek talebi oluşturma adım 9",
  },
];

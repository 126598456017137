import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { Badge } from "@repo/ui/components/ui/Badge";
import { User2, Calendar, Paperclip, Download } from "lucide-react";
import { TicketReply } from "@repo/service";
import { useTranslation, useCurrentLanguage } from "@repo/config";
import { format } from "date-fns";
import { tr, enUS } from "date-fns/locale";
import { cn } from "@repo/ui/lib/utils";
import { Button } from "@repo/ui/components/ui/Button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";

interface TicketReplyCardProps {
  readonly reply: TicketReply;
}

export default function TicketReplyCard({ reply }: TicketReplyCardProps) {
  const { t } = useTranslation("hesk");
  const language = useCurrentLanguage();
  const dateLocale = language === "tr" ? tr : enUS;

  const formattedDate = format(new Date(reply.dt), "PPp", {
    locale: dateLocale,
  });

  return (
    <Card
      className={cn(
        "group transition-all duration-200",
        reply.author?.is_staff
          ? "bg-info-background/50 dark:bg-info-background/10"
          : "bg-card dark:bg-card/50",
      )}
    >
      <CardContent className="p-3">
        <div className="flex items-start gap-3">
          {/* Avatar and User Information */}
          <div
            className={cn(
              "shrink-0 p-2 rounded-full",
              reply.author?.is_staff
                ? "bg-info/10 dark:bg-info/20"
                : "bg-muted dark:bg-muted/50",
            )}
          >
            <User2
              className={cn(
                "size-4",
                reply.author?.id
                  ? "text-info dark:text-info"
                  : "text-muted-foreground",
              )}
            />
          </div>

          {/* Content */}
          <div className="flex-1 min-w-0 space-y-2">
            {/* Top Information */}
            <div className="flex items-center justify-between gap-2">
              <div>
                <div className="flex items-center gap-2">
                  <span
                    className={cn(
                      "font-medium",
                      reply.author?.is_staff
                        ? "text-info dark:text-info"
                        : "text-foreground",
                    )}
                  >
                    {reply.author?.name
                      ? reply.author.name
                      : t("tickets.replies.customer_reply")}
                  </span>
                  {reply.author?.is_staff && (
                    <Badge
                      variant="secondary"
                      className="text-[10px] h-4 bg-info/10 text-info dark:bg-info/20"
                    >
                      {t("tickets.replies.staff_reply")}
                    </Badge>
                  )}
                </div>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center gap-1 text-xs text-muted-foreground mt-0.5 cursor-help">
                        <Calendar className="size-3" />
                        <span>{formattedDate}</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{t("tickets.meta.reply_date")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>

            {/* Message */}
            <div
              className="prose prose-sm max-w-none dark:prose-invert"
              dangerouslySetInnerHTML={{ __html: reply.message_html }}
            />

            {/* Attachments */}
            {reply.attachments && (
              <div className="flex items-center gap-2 text-sm">
                <Paperclip className="size-3 text-muted-foreground" />
                <span className="text-muted-foreground truncate flex-1 max-w-48">
                  {reply.attachments.file_name}
                </span>
                <Button
                  variant="ghost"
                  size="icon"
                  className="size-6 shrink-0"
                  asChild
                >
                  <a
                    href={reply.attachments.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <Download className="size-3" />
                  </a>
                </Button>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

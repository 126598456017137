import { useContext } from "react";
import { OrderContext } from "./OrderContext";

/**
 * Use order context
 * @returns Order context
 */
export const useOrderContext = () => {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error("useOrderContext must be used within a OrderProvider");
  }
  return context;
};

export default useOrderContext;

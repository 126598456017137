import React from "react";
import { useTimeAgo } from "../../hooks/useTimeAgo";
import { formatTime } from "@repo/config";

interface MessageTimeProps {
  timestamp: string;
}

/**
 * Message time
 * @param timestamp Timestamp
 * @returns Message time
 */
export const MessageTime: React.FC<MessageTimeProps> = ({ timestamp }) => {
  const timeAgo = useTimeAgo(timestamp);
  const time = formatTime(timestamp);

  return (
    <span className="text-xs text-muted-foreground/60 opacity-0 group-hover:opacity-100 transition-opacity duration-200 mt-1 px-2">
      {time} • {timeAgo}
    </span>
  );
};

import { useTheme } from "@repo/provider";
import { ModeToggle } from "@repo/ui/components/theme/ModeToggle";
import { useTranslation } from "@repo/config";

export function ThemeToggle() {
  const { theme, setTheme } = useTheme();
  const { t } = useTranslation("common");

  return (
    <ModeToggle
      theme={theme}
      onThemeChange={setTheme}
      translations={{
        light: t("theme.light"),
        dark: t("theme.dark"),
        system: t("theme.system"),
      }}
    />
  );
}

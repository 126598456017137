import { BrowserRouter as Router } from "react-router-dom";
import {
  ThemeProvider,
  LanguageProvider,
  QueryProvider,
  UserProvider,
} from "@repo/provider";
import { ReactNode } from "react";
import { NuqsAdapter } from "nuqs/adapters/react-router";
import { Toaster } from "@repo/ui/components/ui/Toaster";
import { authService, profileService, env } from "@/main";

interface ProvidersProps {
  readonly children: ReactNode;
}

/**
 * @component Providers
 * @description Wraps the application with necessary providers
 */
export function Providers({ children }: ProvidersProps) {
  return (
    <Router
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
    >
      <NuqsAdapter>
        <QueryProvider>
          <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
            <UserProvider
              authService={authService}
              profileService={profileService}
              env={env}
            >
              <LanguageProvider>
                {children}
                <Toaster />
              </LanguageProvider>
            </UserProvider>
          </ThemeProvider>
        </QueryProvider>
      </NuqsAdapter>
    </Router>
  );
}

import { cn } from "@repo/ui/lib/utils";

interface LoadCardHeaderProps {
  loadNo: string;
  trailerPlate: string;
  isActive?: boolean;
}

export function LoadOtsListCardHeader({
  loadNo,
  trailerPlate,
  isActive,
}: Readonly<LoadCardHeaderProps>) {
  return (
    <div className="flex items-center w-full justify-between">
      <h3
        className={cn(
          "text-sm md:text-base font-semibold",
          isActive && "text-primary",
        )}
      >
        {loadNo}
      </h3>
      <p className="text-xs font-semibold text-muted-foreground">
        {trailerPlate}
      </p>
    </div>
  );
}

import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  startTransition,
} from "react";
import { OrderDetail, ApiError } from "@repo/service";
import { useOrder } from "@/features/orders/hooks/useOrder";
import { parseAsString, useQueryState } from "nuqs";
import { useTranslation } from "@repo/config";
import { toastManager } from "@repo/ui/utils";

type OrderProviderProps = {
  readonly children: ReactNode;
};

type OrderContextType = {
  selectedOrder: OrderDetail | null;
  setSelectedOrderNo: (orderNo: string) => void;
  isLoading: boolean;
  error: Error | ApiError | null;
  isSheetOpen: boolean;
  closeSheet: () => void;
};

export const OrderContext = createContext<OrderContextType | undefined>(
  undefined,
);

export default function OrderProvider({ children }: OrderProviderProps) {
  const { t } = useTranslation("common");
  const [selectedOrderNo, setSelectedOrderNo] = useQueryState(
    "order_no",
    parseAsString,
  );

  const {
    data: order,
    isLoading,
    error,
  } = useOrder({
    orderNo: selectedOrderNo ?? "",
  });

  const isSheetOpen = !!selectedOrderNo;

  const handleSetSelectedOrderNo = useCallback(
    async (orderNo: string) => {
      try {
        startTransition(() => {
          setSelectedOrderNo(orderNo).catch((error: any) => {
            toastManager.error(t("error.boundary.home"), error.message);
          });
        });
      } catch (error: any) {
        toastManager.error(t("error.boundary.home"), error.message);
      }
    },
    [setSelectedOrderNo, t],
  );

  const handleCloseSheet = useCallback(() => {
    startTransition(() => {
      setSelectedOrderNo(null).catch((error: any) => {
        toastManager.error(t("error.boundary.home"), error.message);
      });
    });
  }, [setSelectedOrderNo, t]);

  const contextValue = useMemo(
    () => ({
      selectedOrder: order || null,
      setSelectedOrderNo: handleSetSelectedOrderNo,
      isLoading,
      error: error,
      isSheetOpen,
      closeSheet: handleCloseSheet,
    }),
    [
      order,
      handleSetSelectedOrderNo,
      isLoading,
      error,
      isSheetOpen,
      handleCloseSheet,
    ],
  );

  return (
    <OrderContext.Provider value={contextValue}>
      {children}
    </OrderContext.Provider>
  );
}

export const translations = {
  tr: {
    common: {
      error: "Hata: {{message}}",
      notSpecified: "Belirtilmemiş",
      loading: "Yükleniyor...",
      retry: "Tekrar deneyin",
    },
    dashboard: {
      title: "Yük Dağılımı",
      description: "Tamamlanmış ve Tamamlanmamış Yükler",
      chart: {
        value: "Sayı",
        completed: "Tamamlanmış",
        incomplete: "Tamamlanmamış",
        load: "Yük",
      },
    },
    ots: {
      detail: {
        loadNo: "Yük No",
        noStatus: "Durum bilgisi mevcut değil",
        error: {
          forbidden_title: "Erişim Reddedildi",
          fetch_title: "Yük yüklenirken bir hata oluştu {{loadNo}}",
          list_title: "Yükler yüklenirken bir hata oluştu",
        },
        sections: {
          vehicle: {
            title: "Araç Bilgileri",
            truck: "Çekici Plaka",
            trailer: "Dorse Plaka",
          },
          dates: {
            title: "Tarih Bilgileri",
            loading: "Yükleme Tarihi",
            unloading: "Boşaltma Tarihi",
            estimatedUnloading: "Tahmini Boşaltma Tarihi",
          },
          address: {
            title: "Adres Bilgileri",
            loading: "Yükleme Adresi",
            unloading: "Boşaltma Adresi",
          },
          contact: {
            title: "İletişim Bilgileri",
            customer: {
              title: "Müşteri Temsilcisi",
              phone: "Telefon",
              email: "E-posta",
            },
            sales: {
              title: "Satış Temsilcisi",
              phone: "Telefon",
              email: "E-posta",
            },
          },
          location: {
            title: "Son Konum Bilgisi",
            address: "Adres",
            lastUpdate: "Son Güncelleme",
          },
        },
      },
      list: {
        empty: {
          title: "Yük bulunamadı",
          search: "Arama kriterlerinize uygun yük bulunamadı",
          filter: "Seçili filtrelere uygun yük bulunamadı",
          noData: "Henüz yük bulunmuyor",
        },
        loadMore: "Tüm yükler yüklendi",
        error: {
          title: "Yükler yüklenirken bir hata oluştu",
          description: "Lütfen tekrar deneyin veya sayfayı yenileyin",
          fetch: "Yükler yüklenirken bir hata oluştu. Lütfen tekrar deneyin.",
        },
      },
      filter: {
        title: "Filtreleme Seçenekleri",
        description: "Yükleri filtrelemek için aşağıdaki seçenekleri kullanın",
        ordering: {
          label: "Sıralama",
          placeholder: "Varsayılan sıralama",
          options: {
            createdAtDesc: "Oluşturma Tarihi (Yeni-Eski)",
            createdAtAsc: "Oluşturma Tarihi (Eski-Yeni)",
            loadingDateDesc: "Yükleme Tarihi (Yeni-Eski)",
            loadingDateAsc: "Yükleme Tarihi (Eski-Yeni)",
          },
        },
        status: {
          label: "Yük Durumu",
          placeholder: "Tüm durumlar",
          all: "Tümü",
          loading: "Durumlar yükleniyor...",
          error: "Durumlar yüklenemedi",
        },
        completion: {
          label: "Tamamlanma Durumu",
          placeholder: "Tamamlanma Durumu",
          all: "Tümü",
          completed: "Tamamlanmış",
          incomplete: "Tamamlanmamış",
        },
        reset: "Filtreleri Sıfırla",
        search: {
          label: "Arama",
          placeholder: "Yüklerde ara...",
        },
      },
      badges: {
        search: "Arama: {{term}}",
        status: "Durum: {{status}}",
        completed: "Tamamlanan",
        incomplete: "Tamamlanmayan",
      },
      status: {
        selectLoad: "Lütfen bir yük seçin",
        completed: "Bu yük tamamlanmıştır",
        noLocation: "Bu yüke ait bir konum bilgisi yoktur",
      },
      map: {
        overlay: {
          loading: "Yükleniyor...",
          noLoad: "Yük seçilmedi",
          completed: "Yük tamamlandı",
          noLocation: "Konum bilgisi yok",
        },
        popup: {
          loadNo: "Yük No",
          plate: "Plaka",
          location: "Konum",
          lastUpdate: "Son Güncelleme",
          noDate: "Tarih bilgisi yok",
          noAddress: "Adres bilgisi yok",
          noPhone: "Telefon bilgisi yok",
          noEmail: "E-posta bilgisi yok",
          noName: "İsim bilgisi yok",
          noPlate: "Plaka bilgisi yok",
        },
      },
      count: "{{count}} yük",
    },
    error: {
      load: {
        fetch: "{{loadNo}} numaralı yük yüklenirken bir hata oluştu",
        param: "{{loadNo}} numaralı yük seçilirken bir hata oluştu",
        list: "Yükler yüklenirken bir hata oluştu",
        list_title: "Yükler yüklenemedi",
        forbidden: "Bu içeriği görüntüleme yetkiniz bulunmamaktadır",
        forbidden_title: "Erişim Reddedildi",
        count: {
          fetch: "Yük sayısı yüklenirken bir hata oluştu",
        },
        statuses: {
          fetch: "Durumlar yüklenirken bir hata oluştu",
          forbidden: "Yük durumlarını görüntüleme yetkiniz bulunmamaktadır",
        },
        detail: {
          fetch: "{{loadNo}} numaralı yük yüklenirken bir hata oluştu",
        },
      },
    },
  },
  en: {
    common: {
      error: "Error: {{message}}",
      notSpecified: "Not Specified",
      loading: "Loading...",
      noData: "No data found",
      retry: "Retry",
    },
    dashboard: {
      title: "Load Distribution",
      description: "Completed and Incomplete Loads",
      chart: {
        value: "Count",
        completed: "Completed",
        incomplete: "Incomplete",
        load: "Loads",
      },
    },
    ots: {
      detail: {
        loadNo: "Load No",
        noStatus: "No status information",
        error: {
          forbidden_title: "Access Denied",
          fetch_title: "Error loading load {{loadNo}}",
          list_title: "Error loading loads",
        },
        sections: {
          vehicle: {
            title: "Vehicle Information",
            truck: "Truck Plate",
            trailer: "Trailer Plate",
          },
          dates: {
            title: "Date Information",
            loading: "Loading Date",
            unloading: "Unloading Date",
            estimatedUnloading: "Estimated Unloading Date",
          },
          address: {
            title: "Address Information",
            loading: "Loading Address",
            unloading: "Unloading Address",
          },
          contact: {
            title: "Contact Information",
            customer: {
              title: "Customer Representative",
              phone: "Phone",
              email: "Email",
            },
            sales: {
              title: "Sales Representative",
              phone: "Phone",
              email: "Email",
            },
          },
          location: {
            title: "Last Location Information",
            address: "Address",
            lastUpdate: "Last Update",
          },
        },
      },
      list: {
        empty: {
          title: "No loads found",
          search: "No loads match your search criteria",
          filter: "No loads match your filter criteria",
          noData: "No loads available yet",
        },
        loadMore: "All loads loaded",
        error: {
          title: "Loads loading error",
          description: "Please try again or refresh the page",
          fetch: "Error loading loads. Please try again.",
          list: "Error loading loads",
          list_title: "Loads loading error",
          forbidden: "You do not have permission to view this content",
          forbidden_title: "Access Denied",
          count: {
            fetch: "Error loading load count",
          },
          statuses: {
            fetch: "Error loading statuses",
            forbidden: "You do not have permission to view this content",
          },
          detail: {
            fetch: "Error loading load {{loadNo}}",
          },
        },
      },
      filter: {
        title: "Filter Options",
        description: "Use the options below to filter loads",
        ordering: {
          label: "Ordering",
          placeholder: "Default ordering",
          options: {
            createdAtDesc: "Creation Date (New-Old)",
            createdAtAsc: "Creation Date (Old-New)",
            loadingDateDesc: "Loading Date (New-Old)",
            loadingDateAsc: "Loading Date (Old-New)",
          },
        },
        status: {
          label: "Load Status",
          placeholder: "All statuses",
          all: "All",
          loading: "Loading statuses...",
          error: "Error loading statuses",
        },
        completion: {
          label: "Completion Status",
          placeholder: "Completion Status",
          all: "All",
          completed: "Completed",
          incomplete: "Incomplete",
        },
        reset: "Reset Filters",
        search: {
          label: "Search",
          placeholder: "Search loads...",
        },
      },
      badges: {
        search: "Search: {{term}}",
        status: "Status: {{status}}",
        completed: "Completed",
        incomplete: "Incomplete",
      },
      status: {
        selectLoad: "Please select a load",
        completed: "This load is completed",
        noLocation: "This load has no location information",
      },
      map: {
        overlay: {
          loading: "Loading...",
          noLoad: "No load selected",
          completed: "Load completed",
          noLocation: "No location information",
        },
        popup: {
          loadNo: "Load No",
          plate: "Plate",
          location: "Location",
          lastUpdate: "Last Update",
          noDate: "No date information",
          noAddress: "No address information",
          noPhone: "No phone information",
          noEmail: "No email information",
          noName: "No name information",
          noPlate: "No plate information",
        },
      },
      count: "{{count}} loads",
    },
    error: {
      load: {
        fetch: "Error loading load {{loadNo}}",
        param: "Error selecting load {{loadNo}}",
      },
    },
  },
};

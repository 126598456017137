import { MonitorCog, Moon, Sun } from "lucide-react";
import { Button } from "../ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/DropDownMenu";

export type Theme = "light" | "dark" | "system";

interface ModeToggleProps {
  readonly className?: string;
  readonly translations?: {
    light: string;
    dark: string;
    system: string;
  };
  readonly theme: Theme;
  readonly onThemeChange: (theme: Theme) => void;
}

export function ModeToggle({
  className,
  translations = {
    light: "Light",
    dark: "Dark",
    system: "System",
  },
  theme,
  onThemeChange,
}: ModeToggleProps) {
  /**
   * System theme is the theme of the operating system.
   */
  const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";

  /**
   * Active theme is the theme that is currently active.
   */
  const activeTheme = theme === "system" ? systemTheme : theme;

  return (
    <div className={className}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="size-7 md:size-8" variant="outline" size="icon">
            {activeTheme === "light" ? (
              <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all" />
            ) : (
              <Moon className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all" />
            )}
            <span className="sr-only">Toggle theme</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => onThemeChange("light")}>
            {translations.light}
            <Sun className="h-[1.2rem] w-[1.2rem] ml-auto" />
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onThemeChange("dark")}>
            {translations.dark}
            <Moon className="h-[1.2rem] w-[1.2rem] ml-auto" />
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onThemeChange("system")}>
            {translations.system}
            <MonitorCog className="h-[1.2rem] w-[1.2rem] ml-auto" />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

import { X } from "lucide-react";
import { Badge } from "@repo/ui/components/ui/Badge";
import { LoadFilterOptions } from "@repo/service";
import { Button } from "@repo/ui/components/ui/Button";
import { useLoadStatuses } from "../hooks/useLoadStatuses";
import { useTranslation } from "@repo/config";
import { createFilterBadgeConfig } from "../utils/filterBadgeUtils";

interface LoadOtsFilterBadgeListProps {
  readonly filters: LoadFilterOptions;
  readonly onFiltersChange: (filters: LoadFilterOptions) => void;
}

/**
 * @function LoadOtsFilterBadgeList
 * @description Component for displaying filter badges for loads
 */
export default function LoadOtsFilterBadgeList({
  filters,
  onFiltersChange,
}: LoadOtsFilterBadgeListProps) {
  const { t } = useTranslation("loads");
  const { data: statusesData } = useLoadStatuses();
  const badgeConfigs = createFilterBadgeConfig(t);

  const removeFilter = (filterKey: keyof LoadFilterOptions) => {
    const newFilters = { ...filters };
    if (filterKey === "ordering") {
      newFilters.ordering = "-created_at";
    } else {
      delete newFilters[filterKey];
    }
    onFiltersChange(newFilters);
  };

  if (!Object.keys(filters).length) return null;

  return (
    <div className="flex flex-wrap gap-2">
      {badgeConfigs.map(
        (config) =>
          config.isVisible(filters) && (
            <Badge
              key={config.key as string}
              variant="secondary"
              className="h-7"
            >
              {config.label && `${config.label}: `}
              {config.getValue(filters[config.key], statusesData?.results, t)}
              <Button
                variant="ghost"
                size="icon"
                className="h-4 w-4 ml-1 hover:bg-transparent"
                onClick={() => removeFilter(config.key)}
              >
                <X className="h-3 w-3" />
              </Button>
            </Badge>
          ),
      )}
    </div>
  );
}

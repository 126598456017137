import { Input } from "@repo/ui/components/ui/Input";
import LoadOtsFilterSheet from "./LoadOtsFilterSheet";
import { LoadFilterOptions } from "@repo/service";
import { useEffect, useState } from "react";
import { useDebounce, useTranslation } from "@repo/config";
import { Separator } from "@repo/ui/components/ui/Separator";

/**
 * @module LoadOtsSearchInput
 * @description Search input component with debounced search functionality and filter sheet trigger.
 * Provides text search and access to advanced filtering options.
 *
 * @component
 * @param {Object} props
 * @param {LoadFilterOptions} props.filters - Current filter state
 * @param {Function} props.onFiltersChange - Callback for filter changes
 *
 * @example
 * return (
 *   <LoadOtsSearchInput
 *     filters={filters}
 *     onFiltersChange={handleFilterChange}
 *   />
 * )
 */

/**
 * @constant {number} DEBOUNCE_DELAY
 * @description Delay in milliseconds for debouncing search input
 */
const DEBOUNCE_DELAY = 500;

interface LoadOtsSearchInputProps {
  readonly onFiltersChange: (filters: LoadFilterOptions) => void;
  readonly filters: LoadFilterOptions;
}

export default function LoadOtsSearchInput({
  onFiltersChange,
  filters,
}: LoadOtsSearchInputProps) {
  const { t } = useTranslation("loads");
  const [searchTerm, setSearchTerm] = useState(filters.search ?? "");
  const debouncedSearch = useDebounce(searchTerm, DEBOUNCE_DELAY);

  useEffect(() => {
    onFiltersChange({ ...filters, search: debouncedSearch });
  }, [debouncedSearch]);

  useEffect(() => {
    if (!filters.search) {
      setSearchTerm("");
    }
  }, [filters.search]);

  const handleReset = () => {
    setSearchTerm("");
    onFiltersChange({});
  };

  return (
    <div className="w-full flex space-x-1 items-center">
      <Input
        type="search"
        placeholder={t("ots.filter.search.placeholder")}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Separator orientation="vertical" />
      <LoadOtsFilterSheet
        filters={filters}
        onFiltersChange={onFiltersChange}
        onReset={handleReset}
      />
    </div>
  );
}

import { Badge } from "@repo/ui/components/ui/Badge";
import { Card, CardContent, CardHeader } from "@repo/ui/components/ui/Card";
import { Ticket } from "@repo/service";
import { useTranslation, useCurrentLanguage } from "@repo/config";
import { format } from "date-fns";
import { tr, enUS } from "date-fns/locale";
import { AlertCircle, Clock, User } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@repo/ui/components/ui/Tooltip";
import { getStatusColor, getPriorityColor } from "../utils/ticketColors";
import { cn } from "@repo/ui/lib/utils";
interface TicketCardProps {
  readonly ticket: Ticket;
  readonly onClick: () => void;
}

export default function TicketCard({ ticket, onClick }: TicketCardProps) {
  const { t } = useTranslation("hesk");
  const language = useCurrentLanguage();
  const dateLocale = language === "tr" ? tr : enUS;

  return (
    <Card
      onClick={onClick}
      className={cn(
        "group w-full text-left transition-all duration-200",
        "hover:shadow-md cursor-pointer",
        "bg-card",
        "border-border",
      )}
    >
      <CardHeader className="p-4 pb-0">
        <div className="flex items-start justify-between">
          <div>
            <div className="flex items-center gap-2">
              <h3 className="font-medium text-foreground group-hover:text-info dark:group-hover:text-info transition-colors">
                {ticket.subject}
              </h3>
            </div>

            <div className="flex items-center gap-2 mt-1">
              <p className="text-sm text-muted-foreground font-mono">
                {ticket.ticket_no}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Badge
              variant="secondary"
              className={getStatusColor(ticket.status.id)}
            >
              {ticket.status.label}
            </Badge>
            <Badge
              variant="secondary"
              className={getPriorityColor(ticket.priority.id)}
            >
              {ticket.priority.label}
            </Badge>
          </div>
        </div>
      </CardHeader>
      <CardContent className="p-4 pt-4 flex justify-between">
        <div className="flex items-center gap-4 text-sm text-muted-foreground">
          <div className="flex items-center gap-1">
            <AlertCircle className="size-4" />
            <span>{ticket.category.name}</span>
          </div>
          <div className="flex items-center gap-1">
            <Clock className="size-4" />
            <span>
              {format(new Date(ticket.updated_at), "PPp", {
                locale: dateLocale,
              })}
            </span>
          </div>
        </div>

        {ticket.last_replier && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Badge
                  variant="outline"
                  className={cn("text-xs border-border")}
                >
                  <User className="size-3 mr-1 text-muted-foreground" />
                  {ticket.last_replier?.name}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>{t("tickets.last_replier")}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </CardContent>
    </Card>
  );
}

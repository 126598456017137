import { AppName } from "./roles";
import { Env } from "../env";

type Environment = "production" | "development";

interface DomainConfig {
  primary: string;
  aliases?: string[];
}

interface AppUrlConfig {
  domains: {
    [key in Environment]: DomainConfig;
  };
  path: string;
}

/**
 * Object containing URLs for different applications
 */
export const APP_URLS: Record<AppName, AppUrlConfig> = {
  [AppName.EMPLOYEE]: {
    domains: {
      production: {
        primary: "panel.abclojistik.com",
      },
      development: {
        primary: "localhost",
        aliases: ["127.0.0.1"],
      },
    },
    path: "panel",
  },
  [AppName.CUSTOMER]: {
    domains: {
      production: {
        primary: "portal.abclojistik.com",
      },
      development: {
        primary: "localhost",
        aliases: ["127.0.0.1"],
      },
    },
    path: "portal",
  },
  [AppName.AUTH]: {
    domains: {
      production: {
        primary: "authv2.abclojistik.com",
      },
      development: {
        primary: "localhost",
        aliases: ["127.0.0.1"],
      },
    },
    path: "auth",
  },
  [AppName.RMC]: {
    domains: {
      production: {
        primary: "rmc.abcws.prod",
        aliases: ["rmc.abclojistik.com"],
      },
      development: {
        primary: "localhost",
        aliases: ["127.0.0.1"],
      },
    },
    path: "rmc",
  },
} as const;

const PORT_MAP: Record<AppName, string> = {
  [AppName.EMPLOYEE]: "3001",
  [AppName.CUSTOMER]: "3002",
  [AppName.AUTH]: "3000",
  [AppName.RMC]: "3003",
} as const;

/**
 * Checks if a hostname matches any of the domain configurations
 */
const isDomainMatch = (
  hostname: string,
  domainConfig: DomainConfig,
): boolean => {
  return (
    hostname === domainConfig.primary ||
    domainConfig.aliases?.includes(hostname) ||
    false
  );
};

/**
 * Retrieves the application name from a given URL
 */
export const getAppNameFromUrl = (url: string): AppName | null => {
  try {
    const { hostname, port } = new URL(url);
    const isLocalEnvironment =
      hostname === "localhost" || hostname === "127.0.0.1";
    const environment: Environment = isLocalEnvironment
      ? "development"
      : "production";

    for (const [appName, config] of Object.entries(APP_URLS)) {
      // Development environment check with port
      if (isLocalEnvironment && port === PORT_MAP[appName as AppName]) {
        return appName as AppName;
      }

      // Production environment check with domain
      if (
        !isLocalEnvironment &&
        isDomainMatch(hostname, config.domains[environment])
      ) {
        return appName as AppName;
      }
    }

    return null;
  } catch {
    return null;
  }
};

/**
 * Retrieves the redirect URL for a given application
 */
export const getRedirectUrl = (appName: AppName, env: Env): string => {
  const environment: Environment =
    env.NODE_ENV === "production" ? "production" : "development";
  const { primary } = APP_URLS[appName].domains[environment];

  if (environment === "production") {
    return `https://${primary}`;
  }

  return `http://${primary}:${PORT_MAP[appName]}`;
};

export const translations = {
  tr: {
    header: {
      activeSession: "Aktif Görüşme",
      agents: "Temsilciler",
      typing: "Yazıyor",
      active: "Aktif",
      notifications: "Bildirimler",
      notificationsDescription: "Yeni mesajlar için bildirimleri etkinleştir",
      disconnect: "Bağlantıyı Kes",
      menu: {
        export: "Görüşmeyi Dışa Aktar",
        transfer: "Görüşmeyi Transfer Et",
        end: "Görüşmeyi Sonlandır",
        notes: "Notlar",
      },
    },
    chat: {
      input: {
        placeholder: "Mesajınızı yazın...",
        placeholderInactive: "Görüşme sonlandı",
        send: "Gönder",
      },
      messages: {
        loading: {
          description: "Eski mesajlar yükleniyor",
        },
        system: "Sistem",
        you: "Siz",
        empty: {
          description: "Henüz mesaj yok",
        },
      },
      sidebar: {
        connectionStatus: {
          connected: "Bağlı",
          disconnected: "Chat Bağlantısı Kesildi",
          activeChats: "Aktif Görüşme",
          clickToReconnect: "Tekrar Bağlan",
          expand: "Genişlet",
          collapse: "Daralt",
        },
        sessionStatus: {
          active: "Aktif",
          ended: "Sonlandırıldı",
        },
        noSessions: "Aktif görüşme bulunmuyor",
      },
    },
  },
  en: {
    header: {
      activeSession: "Active Session",
      agents: "Agents",
      typing: "Typing",
      active: "Active",
      notifications: "Notifications",
      notificationsDescription: "Enable notifications for new messages",
      disconnect: "Disconnect",
      menu: {
        export: "Export Chat",
        transfer: "Transfer Chat",
        end: "End Chat",
        notes: "Notes",
      },
    },
    chat: {
      input: {
        placeholder: "Type your message...",
        placeholderInactive: "Session ended",
        send: "Send",
      },
      messages: {
        system: "System",
        you: "You",
        empty: {
          description: "No messages yet",
        },
        loading: {
          description: "Loading old messages",
        },
      },
      sidebar: {
        connectionStatus: {
          connected: "Connected",
          disconnected: "Chat Disconnected",
          activeChats: "Active Chats",
          clickToReconnect: "Click to reconnect",
          expand: "Expand",
          collapse: "Collapse",
        },
        sessionStatus: {
          active: "Active",
          ended: "Ended",
        },
        noSessions: "No active sessions",
      },
    },
  },
};

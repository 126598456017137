import { Button } from "@repo/ui/components/ui/Button";
import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { Badge } from "@repo/ui/components/ui/Badge";
import { useTranslation, useCurrentLanguage } from "@repo/config";
import { format } from "date-fns";
import { tr, enUS } from "date-fns/locale";
import {
  ArrowLeft,
  AlertCircle,
  Clock,
  User2,
  MessageSquare,
  CheckCircle2,
  XCircle,
  Paperclip,
  Download,
  ChevronDown,
  ChevronUp,
  Copy,
} from "lucide-react";
import {
  TicketDetail as TicketDetailType,
  HeskService,
  TicketReply,
} from "@repo/service";
import { getStatusColor, getPriorityColor } from "../utils/ticketColors";
import TicketReplyCard from "./TicketReplyCard";
import { cn } from "@repo/ui/lib/utils";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";
import TicketReplyForm from "./TicketReplyForm";
import { useState } from "react";
import { handleTicketNoCopy } from "../utils/ticketNoCopy";

/**
 * Props for the TicketDetail component
 * @property {TicketDetailType} ticket - The ticket data to display
 * @property {TicketReply[]} replies - The replies to the ticket
 * @property {boolean} isLoadingReplies - Whether the replies are loading
 * @property {Function} onBack - Callback function to handle back navigation
 * @property {HeskService} heskService - The HeskService instance
 * @property {Function} refetchReplies - Callback function to refetch replies
 */
interface TicketDetailProps {
  ticket: TicketDetailType;
  replies: TicketReply[];
  isLoadingReplies: boolean;
  onBack: () => void;
  heskService: HeskService;
  refetchReplies: (options?: { throwOnError?: boolean }) => Promise<unknown>;
}

/**
 * TicketDetail Component
 * Displays detailed information about a support ticket including:
 * - Ticket header with status and priority
 * - Main ticket content with subject, category, and message
 * - Replies section with staff and customer responses
 * - Resolution status if the ticket is closed
 */
export default function TicketDetail({
  ticket,
  replies,
  isLoadingReplies,
  onBack,
  heskService,
  refetchReplies,
}: Readonly<TicketDetailProps>) {
  const { t } = useTranslation("hesk");
  const language = useCurrentLanguage();
  const dateLocale = language === "tr" ? tr : enUS;
  const [isMessageExpanded, setIsMessageExpanded] = useState(false);

  // Check if ticket is resolved
  const isResolved = ticket.closed_at !== null;
  const hasReplies = replies.length > 0;

  const renderRepliesContent = () => {
    if (isLoadingReplies) {
      return (
        <Card className="bg-muted">
          <CardContent className="p-4 text-center text-muted-foreground">
            {t("tickets.replies.loading")}
          </CardContent>
        </Card>
      );
    }

    if (!hasReplies) {
      return (
        <Card className="bg-muted">
          <CardContent className="p-4 text-center text-muted-foreground">
            {t("tickets.replies.no_replies")}
          </CardContent>
        </Card>
      );
    }

    return (
      <div className="space-y-4 p-2">
        {replies.map((reply) => (
          <TicketReplyCard key={reply.id} reply={reply} />
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col max-h-[90vh]">
      {/* Main Header - Fixed */}
      <div
        className={cn(
          "flex-none p-3 rounded-t-lg",
          "bg-card",
          "border border-border",
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="icon"
              onClick={onBack}
              title={t("tickets.actions.back")}
              className="size-8"
            >
              <ArrowLeft className="size-4" />
            </Button>
            <div>
              <h2 className="font-medium text-foreground">
                {t("tickets.ticket_detail")}
              </h2>
              <div className="flex items-center gap-2">
                <p className="text-xs text-muted-foreground font-mono">
                  {ticket.ticket_no}
                </p>
                <Button
                  onClick={() => handleTicketNoCopy(ticket.ticket_no)}
                  variant="ghost"
                  size="icon"
                  className="size-4"
                >
                  <Copy className="size-4" />
                </Button>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Badge
              variant="secondary"
              className={getStatusColor(ticket.status.id)}
            >
              {ticket.status.label}
            </Badge>
            <Badge
              variant="secondary"
              className={getPriorityColor(ticket.priority.id)}
            >
              {ticket.priority.label}
            </Badge>
          </div>
        </div>
      </div>

      {/* Ticket Content - Fixed */}
      <div className="flex-none p-3 bg-card border-x border-border">
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-foreground">
              {ticket.subject}
            </h3>
            <div className="flex flex-wrap items-center gap-3 text-sm text-muted-foreground">
              <div
                className="flex items-center gap-1"
                title={t("tickets.meta.category")}
              >
                <AlertCircle className="size-3" />
                <span>{ticket.category.name}</span>
              </div>
              <div
                className="flex items-center gap-1"
                title={t("tickets.meta.updated_at")}
              >
                <Clock className="size-3" />
                <span>
                  {format(new Date(ticket.updated_at), "PPp", {
                    locale: dateLocale,
                  })}
                </span>
              </div>
              {ticket.last_replier && (
                <div
                  className="flex items-center gap-1"
                  title={t("tickets.meta.last_reply")}
                >
                  <User2 className="size-3" />
                  <span>{ticket.last_replier.name}</span>
                </div>
              )}
            </div>
          </div>

          {ticket.message ? (
            <div className="relative">
              <div
                className={cn(
                  "prose prose-sm max-w-none dark:prose-invert transition-all duration-200 ease-in-out overflow-hidden",
                  !isMessageExpanded && "max-h-12",
                  isMessageExpanded && "max-h-[500px]",
                )}
              >
                <div dangerouslySetInnerHTML={{ __html: ticket.message }} />
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsMessageExpanded(!isMessageExpanded)}
                className="w-full mt-2 hover:bg-muted/50"
              >
                {isMessageExpanded ? (
                  <ChevronUp className="size-4 mr-2" />
                ) : (
                  <ChevronDown className="size-4 mr-2" />
                )}
                {isMessageExpanded
                  ? t("tickets.messages.show_less")
                  : t("tickets.messages.show_more")}
              </Button>
            </div>
          ) : (
            <p className="text-muted-foreground text-center py-2">
              {t("tickets.messages.no_message")}
            </p>
          )}

          {/* Ticket Attachments */}
          {ticket.attachments && (
            <div className="flex items-center gap-2 text-sm border rounded-lg p-2 bg-muted/50">
              <div className="flex items-center gap-2 flex-1 min-w-0">
                <Paperclip className="size-3 text-muted-foreground shrink-0" />
                <span className="text-muted-foreground truncate max-w-48">
                  {ticket.attachments.file_name}
                </span>
              </div>
              <Button
                variant="ghost"
                size="sm"
                className="h-8 shrink-0 hover:bg-background/80"
                asChild
              >
                <a
                  href={ticket.attachments.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <Download className="size-3 mr-2" />
                </a>
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Replies Header - Fixed */}
      <div
        className={cn("flex-none p-3 bg-card border-x border-t border-border")}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <MessageSquare className="size-4 text-info" />
            <h4 className="font-medium text-foreground">
              {t("tickets.replies.title")}
            </h4>
            {hasReplies && (
              <Badge
                variant="secondary"
                className="text-xs bg-info-background text-info"
              >
                {t("tickets.replies.reply_count", { count: ticket.replies })}
              </Badge>
            )}
          </div>
          {isResolved && ticket.closed_at && (
            <div className="flex items-center gap-2 text-xs text-muted-foreground">
              {ticket.closed_by ? (
                <>
                  <CheckCircle2 className="size-3 text-success" />
                  <span>
                    {t("tickets.closed_by", { name: ticket.closed_by.name })}
                  </span>
                  <span>•</span>
                  <span title={t("tickets.closed_at")}>
                    {format(new Date(ticket.closed_at), "PPp", {
                      locale: dateLocale,
                    })}
                  </span>
                </>
              ) : (
                <XCircle className="size-3 text-destructive" />
              )}
            </div>
          )}
        </div>
      </div>

      {/* Replies Content - Scrollable */}
      <div className="flex-1 border-x border-border">
        <ScrollArea className="pr-2 h-[240px]">
          {renderRepliesContent()}
        </ScrollArea>
      </div>

      {/* Reply Form */}
      <TicketReplyForm
        ticketNo={ticket.ticket_no}
        heskService={heskService}
        onSuccess={() => void refetchReplies()}
      />
    </div>
  );
}

import { useState } from "react";
import { useCurrentLanguage } from "@repo/config";
import { HeskService, SubmitTicketReplyResponse } from "@repo/service";

interface UseSubmitTicketReplyProps {
  ticketNo: string;
  heskService: HeskService;
  onSuccess?: (response: SubmitTicketReplyResponse) => void;
  onError?: (error: Error) => void;
}

export function useSubmitTicketReply({
  ticketNo,
  heskService,
  onSuccess,
  onError,
}: UseSubmitTicketReplyProps) {
  const [isLoading, setIsLoading] = useState(false);
  const language = useCurrentLanguage();

  const submitReply = async (message: string, attachments?: File) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("message", message);

      if (attachments) {
        formData.append("attachments", attachments);
      }

      const response = await heskService.submitTicketReply(
        language,
        ticketNo,
        formData,
      );

      onSuccess?.(response);
      return response;
    } catch (error) {
      onError?.(error as Error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    submitReply,
    isLoading,
  };
}

import "@repo/ui/globals.css";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import { initI18n, createEnv } from "@repo/config";
import {
  createApiClient,
  createAuthService,
  createLoadService,
  createProfileService,
  createReportService,
  createOrderService,
  createHeskService,
} from "@repo/service";
import { createRouteConfig } from "./config/routeConfig.ts";

// i18n resources from packages
import { i18n as otsI18n } from "@repo/ots";
import { i18n as settingsI18n } from "@repo/user-settings";
import { i18n as heskI18n } from "@repo/hesk";
import { i18n as chatI18n } from "@repo/chat-panel";

// Create env instance
export const env = createEnv(import.meta.env);

// Initialize services
export const authService = createAuthService(env);
export const apiClient = createApiClient(env, authService);
export const loadService = createLoadService(apiClient);
export const reportService = createReportService(apiClient);
export const orderService = createOrderService(apiClient);
export const profileService = createProfileService(apiClient);
export const heskService = createHeskService(env, authService);

// Create route config
export const { routes } = createRouteConfig({
  authService,
  loadService,
});

// Initialize app with i18n
(async () => {
  try {
    await initI18n(env, ["common", "library", "orders", "reports"], {}, [
      otsI18n,
      settingsI18n,
      heskI18n,
      chatI18n,
    ]);

    createRoot(document.getElementById("root")!).render(<App />);
  } catch (error) {
    console.error("Failed to initialize i18n:", error);
  }
})();

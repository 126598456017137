import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { useLoads } from "../hooks/useLoads";
import { useInView } from "react-intersection-observer";
import LoadOtsSearchInput from "./LoadOtsSearchInput";
import { useEffect, useCallback } from "react";
import { useLoadContext } from "../contexts/LoadContext";
import LoadOtsListCard from "./LoadOtsListCard";
import { LoadFilterOptions, ApiError } from "@repo/service";
import LoadOtsFilterBadgeList from "./LoadOtsFilterBadgeList";
import LoadOtsCountBadge from "./LoadOtsCountBadge";
import {
  parseAsString,
  parseAsInteger,
  parseAsBoolean,
  useQueryState,
} from "nuqs";
import { useTranslation } from "@repo/config";
import { AlertCircle, FileX2, Loader } from "lucide-react";
import { Button } from "@repo/ui/components/ui/Button";

/**
 * @module LoadOtsList
 * @description Main component for displaying loads in a list view.
 * Includes search, filtering, and infinite scroll functionality.
 *
 * @component
 * @example
 * return (
 *   <LoadOtsList />
 * )
 *
 * @typedef {Object} LoadFilterOptions
 * @property {string} [search] - Search text
 * @property {number} [status_number] - Load status
 * @property {boolean} [is_load_completed] - Completion status
 * @property {string} [ordering] - Sorting criteria
 */
const QUERY_PARAMS = {
  SEARCH: "loadSearch",
  STATUS_NUMBER: "loadStatusNumber",
  IS_LOAD_COMPLETED: "loadIsCompleted",
  ORDERING: "loadOrdering",
} as const;

export default function LoadOtsList() {
  const [loadSearch, setLoadSearch] = useQueryState(
    QUERY_PARAMS.SEARCH,
    parseAsString.withDefault(""),
  );
  const [loadStatusNumber, setLoadStatusNumber] = useQueryState(
    QUERY_PARAMS.STATUS_NUMBER,
    parseAsInteger,
  );
  const [loadIsCompleted, setLoadIsCompleted] = useQueryState(
    QUERY_PARAMS.IS_LOAD_COMPLETED,
    parseAsBoolean,
  );
  const [loadOrdering, setLoadOrdering] = useQueryState(
    QUERY_PARAMS.ORDERING,
    parseAsString.withDefault("-created_at"),
  );
  const { t } = useTranslation("loads");
  const { setSelectedLoadNo, selectedLoad } = useLoadContext();
  const { ref: loadMoreRef, inView } = useInView();

  const {
    data: loadsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
    isError,
    error,
  } = useLoads({
    search: loadSearch,
    status_number: loadStatusNumber ?? undefined,
    is_load_completed: loadIsCompleted ?? undefined,
    ordering: loadOrdering,
  });

  const handleFiltersChange = useCallback(
    (newFilters: LoadFilterOptions) => {
      setLoadSearch(newFilters.search ?? "");
      setLoadStatusNumber(newFilters.status_number ?? null);
      setLoadIsCompleted(newFilters.is_load_completed ?? null);
      setLoadOrdering(newFilters.ordering ?? "-created_at");
      refetch();
    },
    [
      loadSearch,
      loadStatusNumber,
      loadIsCompleted,
      loadOrdering,
      setLoadSearch,
      setLoadStatusNumber,
      setLoadIsCompleted,
      setLoadOrdering,
      refetch,
    ],
  );

  useEffect(() => {
    if (isError || !inView || !hasNextPage || isFetchingNextPage) return;
    fetchNextPage();
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage, isError]);

  if (isError) {
    return (
      <div className="flex flex-col items-center justify-center py-8 text-center">
        <AlertCircle className="h-12 w-12 text-destructive mb-3" />
        <p className="text-lg font-medium text-destructive">
          {(error as ApiError)?.status === 403
            ? t("error.load.forbidden_title")
            : t("error.load.list_title")}
        </p>
        {(error as ApiError)?.status !== 403 && (
          <Button variant="outline" className="mt-4" onClick={() => refetch()}>
            {t("common.retry")}
          </Button>
        )}
      </div>
    );
  }

  const renderLoadsList = () => {
    if (!loadsData?.pages) return null;

    const allLoads = loadsData.pages.flatMap((page) => page.results);

    return allLoads.map((load) => (
      <LoadOtsListCard
        key={load.load_id}
        load={load}
        onClick={setSelectedLoadNo}
        isActive={selectedLoad?.load_no === load.load_no}
      />
    ));
  };

  const getEmptyStateMessage = () => {
    if (loadSearch) {
      return t("ots.list.empty.search");
    }

    if (loadStatusNumber ?? loadIsCompleted !== undefined) {
      return t("ots.list.empty.filter");
    }

    return t("ots.list.empty.default");
  };

  const renderEmptyState = () => (
    <div className="flex flex-col items-center justify-center py-8 text-center">
      <FileX2 className="h-12 w-12 text-muted-foreground mb-3" />
      <p className="text-lg font-medium text-muted-foreground">
        {t("ots.list.empty.title")}
      </p>
      <p className="text-sm text-muted-foreground mt-1">
        {getEmptyStateMessage()}
      </p>
    </div>
  );

  const hasResults = (loadsData?.pages?.[0]?.results?.length ?? 0) > 0;

  return (
    <div className="w-full">
      <Card className="rounded-lg py-2 backdrop-blur-md bg-opacity-50 bg-card/30 dark:bg-card/80">
        <CardContent className="px-2">
          <div className="flex flex-col gap-2">
            {/* Loads search input */}
            <LoadOtsSearchInput
              filters={{
                search: loadSearch,
                status_number: loadStatusNumber ?? undefined,
                is_load_completed: loadIsCompleted ?? undefined,
                ordering: loadOrdering,
              }}
              onFiltersChange={handleFiltersChange}
            />

            {/* Loads count badge */}
            <LoadOtsCountBadge
              filters={{
                search: loadSearch,
                status_number: loadStatusNumber ?? undefined,
                is_load_completed: loadIsCompleted ?? undefined,
                ordering: loadOrdering,
              }}
            />

            {/* Loads filter badge list */}
            <LoadOtsFilterBadgeList
              filters={{
                search: loadSearch,
                status_number: loadStatusNumber ?? undefined,
                is_load_completed: loadIsCompleted ?? undefined,
                ordering: loadOrdering,
              }}
              onFiltersChange={handleFiltersChange}
            />
          </div>

          {isLoading ? (
            <div className="flex justify-center py-4">
              <Loader className="size-4 text-primary animate-spin" />
            </div>
          ) : (
            // Loads list
            <div className="overflow-y-auto h-[calc(100svh-15rem)]">
              <div className="pr-2 pt-1 pl-1 space-y-2">
                {loadsData?.pages[0]?.results.length === 0
                  ? // Empty state
                    renderEmptyState()
                  : // Loads list
                    renderLoadsList()}
              </div>

              {isFetchingNextPage && (
                <div className="flex justify-center py-4">
                  <Loader className="size-4 text-primary animate-spin" />
                </div>
              )}

              {hasNextPage && <div ref={loadMoreRef} className="h-10" />}

              {!hasNextPage && hasResults && (
                <div className="text-center py-4 text-sm text-muted-foreground">
                  {t("ots.list.loadMore")}
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

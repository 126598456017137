import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/Form";
import { Input } from "@repo/ui/components/ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select";
import { Textarea } from "@repo/ui/components/ui/Textarea";
import { Button } from "@repo/ui/components/ui/Button";
import { Card, CardContent } from "@repo/ui/components/ui/Card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";
import { useTranslation } from "@repo/config";
import { useSubmitTicket } from "../hooks/useSubmitTicket";
import { useGetCategories } from "../hooks/useGetCategories";
import { toastManager } from "@repo/ui/utils/toastManager";
import { HeskFormValues, HeskService, Priority, Template } from "@repo/service";
import { DEPARTMENTS } from "../constants/department";
import { useHeskSchema } from "../schemas/heskSchema";
import { Separator } from "@repo/ui/components/ui/Separator";
import {
  X,
  Upload,
  FileText,
  Image as ImageIcon,
  File as FileIcon,
  FileArchive,
  FileSpreadsheet,
  CircleAlert,
  FileCode,
} from "lucide-react";
import { cn } from "@repo/ui/lib/utils";
import { useState, useCallback } from "react";
import { ALLOWED_FILE_TYPES } from "../constants/allowedFileTypes";
import { handleFileChange } from "../utils/attachmentCheck";
import { useGetPriorities } from "../hooks/useGetPriorities";
import { useGetTemplates } from "../hooks/useGetTemplates";

// Priority color mapping based on priority level
const PRIORITY_COLORS: Record<number, string> = {
  1: "text-destructive font-medium", // Low
  2: "text-warning font-medium", // Medium
  3: "text-info font-medium", // High
  4: "text-muted-foreground", // Critical
};

// Function to sort priorities by row_id in descending order
const sortPriorities = (a: Priority, b: Priority) => b.row_id - a.row_id;

// Props interface for the form component
interface HeskFormProps {
  readonly onSuccess: () => void;
  readonly heskService: HeskService;
}

// Props interface for file preview component
interface FilePreviewProps {
  file: File | undefined;
  onRemove: () => void;
}

// Component to preview uploaded files with icon and remove button
const FilePreview = ({ file, onRemove }: FilePreviewProps) => {
  const FileIconComponent = getFileIcon(file?.type ?? "");
  return (
    <>
      <div className="relative">
        <FileIconComponent className="w-10 h-10 text-primary" />
        <Button
          type="button"
          variant="ghost"
          size="icon"
          className="absolute -top-2 -right-2 h-5 w-5 rounded-full bg-destructive hover:bg-destructive/90"
          onClick={(e) => {
            e.preventDefault();
            onRemove();
          }}
        >
          <X className="h-3 w-3 text-white" />
        </Button>
      </div>
      {file && (
        <div className="flex flex-col items-center">
          <p className="text-sm font-medium truncate max-w-[200px]">
            {file?.name}
          </p>
          <p className="text-xs text-muted-foreground">
            {(file?.size / (1024 * 1024)).toFixed(2)} MB
          </p>
        </div>
      )}
    </>
  );
};

// Helper function to determine file icon based on file type
const getFileIcon = (fileType: string) => {
  if (fileType.includes("image")) return ImageIcon;
  if (fileType.includes("pdf")) return FileText;
  if (fileType.includes("spreadsheet") || fileType.includes("excel"))
    return FileSpreadsheet;
  if (fileType.includes("zip") || fileType.includes("rar")) return FileArchive;
  if (fileType.includes("text")) return FileText;
  return FileIcon;
};

// Main form component for creating support tickets
export default function HeskForm({ onSuccess, heskService }: HeskFormProps) {
  const { t } = useTranslation("hesk");

  const submitTicketMutation = useSubmitTicket(heskService);
  const { data: categories } = useGetCategories(heskService);
  const { data: priorities } = useGetPriorities(heskService);
  const { data: templates } = useGetTemplates(heskService);

  const heskSchema = useHeskSchema();

  const [isDragging, setIsDragging] = useState(false);

  const form = useForm<HeskFormValues>({
    resolver: zodResolver(heskSchema),
    defaultValues: {
      category: "",
      priority: "",
      computer_code: "",
      department: "",
      subject: "",
      message: "",
      attachments: undefined,
    },
  });

  const onSubmit = async (data: HeskFormValues) => {
    const response = await submitTicketMutation.mutateAsync(data);
    toastManager.success(response.title, response.description);
    form.reset();
    onSuccess?.();
  };

  const onFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = handleFileChange(e);
      if (file) {
        form.setValue("attachments", file);
      }
    },
    [form],
  );

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      setIsDragging(false);

      const file = e.dataTransfer.files?.[0];
      if (!file) return;

      // FILE TYPE CHECK
      const fileType = file.type;
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      const allowedExtensions = [
        "gif",
        "jpg",
        "jpeg",
        "png",
        "zip",
        "rar",
        "csv",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "txt",
        "pdf",
      ];

      if (
        ALLOWED_FILE_TYPES.includes(fileType) ||
        (fileExtension && allowedExtensions.includes(fileExtension))
      ) {
        form.setValue("attachments", file);
      } else {
        toastManager.error(
          t("form.attachments.error.title"),
          t("form.attachments.error.description"),
        );
      }
    },
    [form, t],
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(
    (e: React.DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      setIsDragging(false);
    },
    [],
  );

  const removeAttachment = () => {
    form.setValue("attachments", undefined);
    const fileInput =
      document.querySelector<HTMLInputElement>('input[type="file"]');
    if (fileInput) fileInput.value = "";
  };

  // Handle template selection and populate form fields
  const handleTemplateSelect = useCallback(
    (template: Template) => {
      form.setValue("subject", template.title);
      form.setValue("message", template.message);
    },
    [form],
  );

  return (
    <Card className="w-full mx-auto relative">
      <CardContent className="pt-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-end">
              {/* Department */}
              <FormField
                control={form.control}
                name="department"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("form.department.label")}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t("form.department.placeholder")}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {DEPARTMENTS.map((department) => (
                          <SelectItem
                            key={department.id}
                            value={department.id.toString()}
                          >
                            {t(department.name)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Computer Code */}
              <FormField
                control={form.control}
                name="computer_code"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex items-center gap-2">
                      <FormLabel>{t("form.computer_code.label")}</FormLabel>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <CircleAlert className="size-4" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{t("form.computer_code.help")}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                    <FormControl>
                      <Input
                        placeholder={t("form.computer_code.placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <Separator />

            {/* Category and Priority Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="category"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("form.category.label")}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t("form.category.placeholder")}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {categories?.results.map((category) => (
                          <SelectItem
                            key={category.id}
                            value={category.id.toString()}
                          >
                            {category.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Priority */}
              <FormField
                control={form.control}
                name="priority"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("form.priority.label")}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t("form.priority.placeholder")}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {priorities?.results
                          .sort(sortPriorities)
                          .map((priority) => (
                            <SelectItem
                              key={priority.id}
                              value={priority.id.toString()}
                              className={cn(
                                PRIORITY_COLORS[priority.row_id],
                                "flex items-center gap-2 relative",
                              )}
                            >
                              <div className="flex items-center gap-2 w-full">
                                <div
                                  className={cn(
                                    "size-2 shrink-0 rounded-full",
                                    priority.row_id === 1 && "bg-destructive",
                                    priority.row_id === 2 && "bg-warning",
                                    priority.row_id === 3 && "bg-info",
                                    priority.row_id === 4 && "bg-slate-500",
                                  )}
                                />
                                <span className="truncate">
                                  {priority.label}
                                </span>
                              </div>
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Subject and Message */}
            <div className="space-y-4">
              {/* Template Selector */}
              <div>
                <div className="mb-2 flex items-center gap-2">
                  <FileCode className="h-4 w-4" />
                  <span className="text-sm font-medium">
                    {t("form.templates.label")}
                  </span>
                </div>
                <div className="flex flex-wrap gap-2">
                  {templates?.results
                    .sort((a, b) => a.tpl_order - b.tpl_order)
                    .map((template) => (
                      <Button
                        key={template.id}
                        variant="outline"
                        size="sm"
                        type="button"
                        className="text-xs"
                        onClick={() => handleTemplateSelect(template)}
                      >
                        {template.title}
                      </Button>
                    ))}
                </div>
              </div>

              <FormField
                control={form.control}
                name="subject"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("form.subject.label")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("form.subject.placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="message"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("form.message.label")}</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder={t("form.message.placeholder")}
                        {...field}
                        className="min-h-[80px]"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Attachments */}
              <FormField
                control={form.control}
                name="attachments"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("form.attachments.label")}</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <div
                          className={cn(
                            "flex flex-col items-center justify-center w-full",
                            "border-2 border-dashed rounded-lg",
                            "transition-colors duration-150 ease-in-out",
                            isDragging
                              ? "border-primary bg-primary/5"
                              : "border-border hover:bg-accent/50",
                            field.value ? "py-4" : "py-6",
                          )}
                        >
                          <label
                            htmlFor="file-upload"
                            className="flex flex-col items-center justify-center w-full h-full cursor-pointer"
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                          >
                            {field.value ? (
                              <FilePreview
                                file={field.value}
                                onRemove={removeAttachment}
                              />
                            ) : (
                              <div className="flex flex-col items-center justify-center">
                                <Upload
                                  className={cn(
                                    "w-8 h-8 mb-2",
                                    isDragging
                                      ? "text-primary animate-bounce"
                                      : "text-muted-foreground",
                                  )}
                                />
                                <p className="mb-2 text-sm text-muted-foreground">
                                  <span className="font-semibold">
                                    {isDragging
                                      ? t("form.attachments.drop")
                                      : t("form.attachments.click_or_drop")}
                                  </span>
                                </p>
                                <p className="text-xs text-muted-foreground/70">
                                  {t("form.attachments.help")}
                                </p>
                              </div>
                            )}
                            <Input
                              id="file-upload"
                              type="file"
                              className="hidden"
                              onChange={onFileChange}
                              accept={ALLOWED_FILE_TYPES.join(",")}
                            />
                          </label>
                        </div>
                        <FormMessage className="absolute bottom-16" />
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>

            {/* Submit Button */}
            <Button
              type="submit"
              className="w-full"
              disabled={submitTicketMutation.isPending}
            >
              {submitTicketMutation.isPending
                ? t("form.submit.loading")
                : t("form.submit.label")}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}

import { useQuery } from "@tanstack/react-query";
import { orderService } from "@/main";
import { GetOrdersParams } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

const QUERY_KEY = "orders";

export const useOrders = (params: GetOrdersParams) => {
  const currentLanguage = useCurrentLanguage();

  const queryKey = [
    QUERY_KEY,
    currentLanguage,
    params.page,
    params.limit,
    params.filters?.search,
  ];

  const query = useQuery({
    queryKey,
    queryFn: () =>
      orderService.getOrders({
        ...params,
        limit: params.limit ?? 10,
        offset: ((params.page ?? 1) - 1) * (params.limit ?? 10),
        language: currentLanguage,
      }),
  });

  const isPending = query.isLoading || query.isFetching;

  return {
    ...query,
    isPending,
  };
};

import { LoadFilterOptions } from "@repo/service";

/**
 * @function getActiveFilterCount
 * @description Calculates the number of active filters in the given filter options.
 * @param {LoadFilterOptions} filters - The filter options to evaluate.
 * @returns {number} The number of active filters.
 */
export const getActiveFilterCount = (filters: LoadFilterOptions): number => {
  let count = 0;

  // Search filter
  if (filters.search && filters.search.trim() !== "") count++;

  // Status filter
  if (filters.status_number !== undefined) count++;

  // Load completion filter
  if (filters.is_load_completed !== undefined) count++;

  // Ordering filter
  if (filters.ordering && filters.ordering !== "-created_at") count++;

  return count;
};

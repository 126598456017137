import { Truck } from "lucide-react";
import { cn } from "@repo/ui/lib/utils";

interface LoadStatusIconProps {
  isCompleted: boolean;
}

export function LoadOtsListCardStatusIcon({
  isCompleted,
}: Readonly<LoadStatusIconProps>) {
  return (
    <div
      className={cn(
        "rounded-full p-1",
        isCompleted
          ? "bg-success-background text-success"
          : "bg-warning-background text-warning",
      )}
    >
      <Truck />
    </div>
  );
}

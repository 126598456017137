import { UserPen, LucideIcon, KeyRound } from "lucide-react";

interface SettingsRoute {
  path: string;
  translationKey: string;
  icon?: LucideIcon;
}

export const SETTINGS_ROUTES: Record<string, SettingsRoute> = {
  PROFILE: {
    path: "/settings",
    translationKey: "menu.profile_settings",
    icon: UserPen,
  },
  CHANGE_PASSWORD: {
    path: "/settings/change-password",
    translationKey: "menu.change_password",
    icon: KeyRound,
  },
  // ADDRESSES: {
  //     path: '/settings/addresses',
  //     translationKey: 'menu.my_addresses',
  //     icon: MapPinHouse
  // }
} as const;

export const getRouteConfig = (pathname: string) => {
  return (
    Object.values(SETTINGS_ROUTES).find((route) => route.path === pathname) ||
    SETTINGS_ROUTES.PROFILE
  );
};
